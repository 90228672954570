import React, { useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'
import FlashMessages from '../Pages/App/Shared/FlashMessages'
// import NavMenu from '@/Layout/Components/NavMenu'
import AdminNavMenu from '../Layout/Components/AdminNavMenu'
import MobileAdminNav from '../Layout/Components/MobileAdminNav'
import StatusBar from '../Layout/Components/StatusBar'

export default function AdminLayout({ title, children }) {
    useEffect(() => {
        document.title = title
    }, [title])

    const [showMobileNav, setShowMobileNav] = useState(false)
    const [showAdminNav, setShowAdminNav] = useState(true)

    const { user } = usePage().props
    var env = process.env.MIX_ENVIRONMENT
    return (
        <main className="flex flex-row flex-wrap">
            <StatusBar title={title}></StatusBar>
            <div className="flex flex-row w-full">
                <header
                    className={
                        showMobileNav
                            ? 'absolute w-full h-screen bg-gray-500 bg-opacity-50 col-span-2 bg-transparent z-30  md:max-w-sm md:static md:block'
                            : 'absolute bg-none col-span-2 bg-transparent z-30 md:max-w-sm md:static md:block'
                    }
                >
                    <AdminNavMenu />
                    <MobileAdminNav
                        showMobileNav={showMobileNav}
                        setShowMobileNav={setShowMobileNav}
                    />
                </header>
                <article className="flex-grow flex flex-col w-auto justify-start bg-gray-100 z-0 px-4 relative">
                    <FlashMessages />
                    {children}
                </article>
            </div>
        </main>
    )
}
