import React from 'react'
import AdminLayout from '../../../../Layout/AdminLayout'
import LocationRow from '../../../../Components/LocationRow'
import { useForm } from '@inertiajs/react'
import Select from 'react-select'

export default function LocationSettings({
    existingLocations,
    permanent,
    waivered,
    opst,
}) {
    const { data, setData, post, processing, errors } = useForm({
        org: '',
        name: '',
        address: '',
        crossStreet: '',
        type: '',
        zip: '',
    })
    let options = [
        { label: 'NYHRE', value: 'NYHRE' },
        { label: 'WHCP', value: 'WHCP' },
    ]
    let options2 = [
        { label: 'Waivered Mobile', value: 'waivered_mobile_site' },
        { label: 'OPST', value: 'opst_site' },
        { label: 'Permanent', value: 'permanent' },
    ]
    function handleSelect(opt) {
        setData({
            ...data,
            org: opt.value,
        })
    }
    function handleSelect2(opt) {
        setData({
            ...data,
            type: opt.value,
        })
    }
    function handleSubmit(e) {
        e.preventDefault()
        post('/settings/locations')
    }

    return (
        <>
            <AdminLayout title="Location Settings">
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md mt-4 mb-8">
                    <div className="p-8 md:p-12">
                        <div className="flex-row flex-wrap hidden md:flex items-center mb-8">
                            <p className="text-md pr-2">Jump to:</p>
                            <a
                                href="#mobile_locations"
                                className="bg-gray-100 hover:bg-gray-200 px-4 py-2 mx-2"
                            >
                                Waivered Mobile Locations
                            </a>
                            <a
                                href="#roving_locations"
                                className="bg-gray-100 hover:bg-gray-200 px-4 py-2 mx-2"
                            >
                                OPST Sites
                            </a>
                        </div>
                        <h2 className="text-xl font-bold mb-4">
                            Permanent Locations
                        </h2>
                        <div className="w-full grid grid-cols-12 whitespace-no-wrap mb-8">
                            <p className="hidden lg:block col-span-4 font-semibold border-b pb-1">
                                Name
                            </p>
                            <p className="hidden lg:block col-span-4 font-semibold border-b pb-1">
                                Address
                            </p>
                            <p className="hidden lg:block col-span-2 font-semibold border-b pb-1">
                                Org
                            </p>
                            <p className="hidden lg:block col-span-2 font-semibold border-b pb-1">
                                Actions
                            </p>
                            <div className="col-span-12">
                                {permanent.length ? (
                                    permanent.map(
                                        ({ name, address, id, org }) => (
                                            <LocationRow
                                                key={id}
                                                id={id}
                                                name={name}
                                                address={address}
                                                org={org}
                                            />
                                        )
                                    )
                                ) : (
                                    <p>No Permanent Locations</p>
                                )}
                            </div>
                        </div>
                        <h2
                            id="mobile_locations"
                            className="text-xl font-bold mb-4"
                        >
                            Waivered Mobile Locations
                        </h2>
                        <div className="w-full grid grid-cols-12 whitespace-no-wrap mb-8">
                            <p className="hidden lg:block col-span-4 font-semibold border-b pb-1">
                                Name
                            </p>
                            <p className="hidden lg:block col-span-4 font-semibold border-b pb-1">
                                Address
                            </p>
                            <p className="hidden lg:block col-span-2 font-semibold border-b pb-1">
                                Org
                            </p>
                            <p className="hidden lg:block col-span-2 font-semibold border-b pb-1">
                                Actions
                            </p>
                            <div className="col-span-12">
                                {waivered.length ? (
                                    waivered.map(
                                        ({ name, address, id, org }) => (
                                            <LocationRow
                                                key={id}
                                                id={id}
                                                name={name}
                                                address={address}
                                                org={org}
                                            />
                                        )
                                    )
                                ) : (
                                    <p>No Waivered Mobile Locations</p>
                                )}
                            </div>
                        </div>
                        <h2 id="opst_sites" className="text-xl font-bold mb-4">
                            OPST Sites
                        </h2>
                        <div className="w-full grid grid-cols-12 whitespace-no-wrap mb-8">
                            <p className="hidden lg:block col-span-4 font-semibold border-b pb-1">
                                Name
                            </p>
                            <p className="hidden lg:block col-span-4 font-semibold border-b pb-1">
                                Address
                            </p>
                            <p className="hidden lg:block col-span-2 font-semibold border-b pb-1">
                                Org
                            </p>
                            <p className="hidden lg:block col-span-2 font-semibold border-b pb-1">
                                Actions
                            </p>
                            <div className="col-span-12">
                                {waivered.length ? (
                                    opst.map(({ name, address, id, org }) => (
                                        <LocationRow
                                            key={id}
                                            id={id}
                                            name={name}
                                            address={address}
                                            org={org}
                                        />
                                    ))
                                ) : (
                                    <p>No OPST Sitess</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <form
                        className="bg-customGreen w-full p-8 md:p-12"
                        onSubmit={handleSubmit}
                    >
                        <h2
                            id="roving_locations"
                            className="text-3xl font-semibold text-white mb-4"
                        >
                            Add a new location
                        </h2>
                        <div className="flex flex-row flex-wrap items-end mb-4 gap-2">
                            <div className="w-full lg:w-4/12 flex flex-col">
                                {errors.org && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.org}
                                    </div>
                                )}
                                <label className="block mb-2 text-white">
                                    Organization
                                </label>
                                <Select
                                    options={options}
                                    onChange={(opt) => handleSelect(opt)}
                                />
                            </div>
                            <div className="w-full lg:w-4/12 flex flex-col">
                                {errors.type && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.type}
                                    </div>
                                )}
                                <label className="block mb-2 text-white">
                                    Location Type
                                </label>
                                <Select
                                    options={options2}
                                    onChange={(opt) => handleSelect2(opt)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-end mb-4 gap-2">
                            <div className="text-gray-700 w-full lg:w-4/12">
                                {errors.name && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.name}
                                    </div>
                                )}
                                <label className="block mb-2 text-white">
                                    Location Name
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('name', e.target.value)
                                    }
                                    value={data.name}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400 bg-white"
                                    type="text"
                                    placeholder=""
                                    id="name"
                                />
                            </div>
                            <div className="text-gray-700 w-full lg:w-4/12 mt-2">
                                {errors.address && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.address}
                                    </div>
                                )}
                                <label className="block mb-2 text-white">
                                    Location Address
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('address', e.target.value)
                                    }
                                    value={data.address}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400 bg-white"
                                    type="text"
                                    placeholder=""
                                    id="address"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-end mb-4 gap-2">
                            <div className="text-gray-700 w-full lg:w-4/12 mt-2">
                                {errors.crossStreet && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.crossStreet}
                                    </div>
                                )}
                                <label className="block mb-2 text-white">
                                    Location Cross Streets / Description
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('crossStreet', e.target.value)
                                    }
                                    value={data.crossStreet}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400 bg-white"
                                    type="text"
                                    placeholder=""
                                    id="crossStreet"
                                />
                            </div>
                            <div className="text-gray-700 w-full lg:w-4/12 mt-2">
                                {errors.crossStreet && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.crossStreet}
                                    </div>
                                )}
                                <label className="block mb-2 text-white">
                                    Location Zip Code
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('zip', e.target.value)
                                    }
                                    value={data.zip}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400 bg-white"
                                    type="text"
                                    placeholder=""
                                    id="zip"
                                />
                            </div>
                        </div>
                        <div className="w-full lg:w-2/12 flex flex-row items-center mt-4">
                            <button
                                type="submit"
                                className="bg-gray-100 hover:bg-gray-200 px-8 py-2"
                                disabled={processing}
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </AdminLayout>
        </>
    )
}
