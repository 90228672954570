import React from 'react'

export default function Expired({ }) {
    return (
        <div>
            <section className="h-auto flex flex-wrap justify-center mt-2 md:mt-20">
                <div className="h-auto max-w-lg w-full mx-4 grid shadow-md bg-white rounded">
                    <div className="flex items-center justify-center h-24 w-full bg-gray-200 rounded rounded-b-none">
                        <h1 className="text-2xl">Welcome to OnPoint NYC!</h1>
                    </div>
                    <div className="block w-full my-8">
                        <div className="flex flex-col md:flex-row flex-wrap px-4">
                            <p className="text-gray-500 w-full text-md text-lg font-medium">
                                The link to create your account has expired.
                                Please contact your administrator to have one
                                re-sent.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
