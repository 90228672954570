import React, { useState } from 'react'
import AdminLayout from '../../../../Layout/AdminLayout'

export default function ExportDownload({ link }) {
    return (
        <>
            <AdminLayout title="Export Download">
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 mt-4">
                    <h2 className="text-2xl font-semibold mb-4">
                        Your export is ready
                    </h2>
                    <form method="POST">
                        <a
                            className="bg-gray-100 px-4 py-2 w-48 flex justify-center items-center"
                            href={'/settings/exports/' + link}
                        >
                            Download export
                        </a>
                    </form>
                </div>
            </AdminLayout>
        </>
    )
}
