import React from 'react'
import Layout from '../../../Layout/Layout'
import ParticipantRow from '../../../Components/ParticipantRow'
import Pagination from '../../../Components/Pagination'

export default function Index({ participants }) {
    const {
        data: { links },
    } = participants
    return (
        <Layout>
            <>
                <h1>Participants</h1>
                <Pagination links={links} />
                <table className="w-full whitespace-nowrap">
                    <thead>
                        <tr className="text-left font-bold">
                            <th className="pr-6 pt-5 pb-4">Name</th>
                            <th className="px-6 pt-5 pb-4">ID</th>
                            <th className="px-6 pt-5 pb-4">Primary Phone</th>
                        </tr>
                    </thead>
                    {participants.map(
                        ({
                            id,
                            first_name,
                            last_name,
                            ccn,
                            primary_phone,
                            created_by,
                        }) => (
                            <ParticipantRow
                                key={id}
                                id={id}
                                first_name={first_name}
                                last_name={last_name}
                                ccn={ccn}
                                primary_phone={primary_phone}
                                created_by={created_by}
                                showSelect={true}
                            />
                        )
                    )}
                </table>
            </>
        </Layout>
    )
}
