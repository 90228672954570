import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout/Layout'
import { usePage, Link } from '@inertiajs/react'
import FlashMessages from '../Shared/FlashMessages'
import ShowAlerts from '../../../Components/Alerts/ShowAlerts'

export default function Alert({
    participants,
    participant,
    alert_user_id,
    alert_message,
    alert,
    alerts,
}) {
    const { user } = usePage().props
    const { flash, errors } = usePage().props
    const [alertText, setAlertText] = useState('')

    useEffect(() => {
        if (alert == 1)
            setAlertText(
                'This participant should follow up with Supportive Services'
            )
        if (alert == 2)
            setAlertText('This participant should follow up with Medical')

        if (alert == 3)
            setAlertText(
                'This participant should follow up with Drop-In Center Manager'
            )
        if (alert == 4)
            setAlertText(
                'This participant is on Temporary Ban (Contact Manager)'
            )
    })

    return (
        <>
            <div className="bg-gray-100 h-screen w-full flex items-center justify-center">
                <FlashMessages />
                <div className="flex flex-col w-full bg-white shadow-md p-8 md:p-16 container mx-auto max-w-xs md:max-w-lg">
                    {alerts?.length > 0 ? (
                        <ShowAlerts alerts={alerts} showClear={false} />
                    ) : null}
                    {alert != null && (
                        <div className="flex flex-row gap-4 w-full mb-4">
                            <span className="w-12 md:flex-1 w-auto max-w-xs bg-customRed p-2 rounded-md items-center justify-center relative text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </span>
                            <div className="w-10/12 flex flex-col gap-0">
                                <h2 className="font-semibold text-sm">
                                    {alertText}
                                </h2>
                                <h2 className="text-sm">{alert_message}</h2>
                                <h3 className="text-gray-600 text-xs">
                                    Alert added by {alert_user_id}
                                </h3>
                            </div>
                        </div>
                    )}
                    {participant.vaccine_alert_status == 0 && (
                        <div className="flex flex-row gap-4 w-full mb-4">
                            <span className="w-12 md:flex-1 w-auto max-w-xs bg-customRed p-2 rounded-md items-center justify-center relative text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </span>
                            <div className="w-10/12 flex flex-col gap-0 my-4">
                                <h2 className="font-semibold text-sm">
                                    This participant is not vaccinated
                                </h2>
                            </div>
                        </div>
                    )}
                    {participant.vaccine_alert_status == 1 && (
                        <div className="flex flex-row gap-4 w-full mb-4">
                            <span className="w-12 md:flex-1 w-auto max-w-xs bg-customYellow p-2 rounded-md items-center justify-center relative text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </span>
                            <div className="w-10/12 flex flex-col gap-0 my-4">
                                <h2 className="font-semibold text-sm">
                                    This participant is partially vaccinated
                                </h2>
                            </div>
                        </div>
                    )}
                    {participant.vaccine_alert_status == 2 && (
                        <div className="flex flex-row gap-4 w-full mb-4">
                            <span className="w-12 md:flex-1 w-auto max-w-xs bg-customGreen p-2 rounded-md items-center justify-center relative text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </span>
                            <div className="w-10/12 flex flex-col gap-0 my-4">
                                <h2 className="font-semibold text-sm">
                                    This participant is fully vaccinated
                                </h2>
                            </div>
                        </div>
                    )}
                    {participant.vaccine_alert_status == 3 && (
                        <div className="flex flex-row gap-4 w-full mb-4">
                            <span className="w-12 md:flex-1 w-auto max-w-xs bg-customGreen p-2 rounded-md items-center justify-center relative text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </span>
                            <div className="w-10/12 flex flex-col gap-0 my-4">
                                <h2 className="font-semibold text-sm">
                                    This participant is fully vaccinated plus
                                    booster
                                </h2>
                            </div>
                        </div>
                    )}
                    <h5 className="mb-4 text-2xl text-gray-700 font-semibold text-center">
                        Finish with {user.is.currently_seeing}
                    </h5>
                    {participant.vaccine_alert_status != 3 && (
                        <div className="flex flex-row flex-wrap justify-center mb-2">
                            <Link
                                href="/vaccineservice/create"
                                className="p-4 bg-gray-100 flex-grow w-full hover:bg-gray-200 text-center"
                            >
                                Start a new Vaccine Service Form
                            </Link>
                        </div>
                    )}
                    <div className="flex flex-row flex-wrap justify-center mb-2">
                        <Link
                            method="GET"
                            href="/continue"
                            className="p-4 bg-gray-100 flex-grow w-full hover:bg-gray-200 text-center"
                        >
                            Continue With Participant
                        </Link>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center mb-2">
                        <Link
                            href={route('participants.edit', {
                                id: participant.id,
                            })}
                            className="p-4 bg-gray-100 flex-grow w-full hover:bg-gray-200 text-center"
                        >
                            Edit Participant
                        </Link>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center">
                        <Link
                            method="POST"
                            href="/participants/drop"
                            className="p-4 bg-gray-100 flex-grow w-full hover:bg-gray-200 text-center"
                        >
                            Drop Participant
                        </Link>
                    </div>
                    {/* <div className="flex flex-row flex-wrap justify-center">
                    <Link
                        method="POST"
                        href={``}
                        className="p-4 bg-gray-100 flex-grow w-full hover:bg-gray-200 text-center"
                    >
                        Edit Participant
                    </Link>
                </div> */}
                </div>
            </div>
        </>
    )
}
