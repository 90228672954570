import React, { useEffect, useState } from 'react'
import { usePage, router } from '@inertiajs/react'
import FlashMessages from '../Pages/App/Shared/FlashMessages'
// import NavMenu from '@/Layout/Components/NavMenu'
import NavMenu from '../Layout/Components/NavMenu'
import MobileNav from '../Layout/Components/MobileNav'
import StatusBar from '../Layout/Components/StatusBar'
import Axios from 'axios'

export default function Layout({ title, children }) {
    useEffect(() => {
        document.title = title
    }, [title])
    var checker = setInterval(function () {
        axios.get(`/check`).then((response) => {
            if (response.data === 'old') {
                router.get('/logout', 'poo', {
                    onBefore: clearInterval(checker),
                })
                // clearInterval(checker)
            }
        })
    }, 60 * 1000)

    const [showMobileNav, setShowMobileNav] = useState(false)

    const { user } = usePage().props
    var env = process.env.MIX_ENVIRONMENT
    return (
        <main className="flex flex-row flex-wrap">
            <StatusBar title={title}></StatusBar>
            <div className="flex flex-row w-full">
                <header
                    className={
                        showMobileNav
                            ? 'absolute w-full z-30 md:max-w-sm md:static md:block'
                            : 'absolute w-auto bg-none bg-transparent z-30 md:static md:block'
                    }
                >
                    <NavMenu />
                    <MobileNav
                        showMobileNav={showMobileNav}
                        setShowMobileNav={setShowMobileNav}
                    />
                </header>
                <article className="relative z-0 flex flex-col justify-start flex-grow flex-shrink w-auto px-4 bg-gray-100">
                    <FlashMessages />
                    {children}
                </article>
            </div>
        </main>
    )
}
