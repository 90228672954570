import React from 'react'
import { Link, router } from '@inertiajs/react'
import moment from 'moment'

export default function Index({
    id,
    dob,
    first_name,
    last_name,
    participant_uid,
    qr,
    showSelect,
}) {
    function handleSelect(e, id) {
        e.preventDefault()
        router.visit(route('participants.edit', id), {
            method: 'get',
        })
    }
    function selectParticipant(e, id) {
        e.preventDefault()
        router.visit(route('participants.choose', id), {
            method: 'put',
        })
    }
    return (
        <>
            <div
                key={id}
                className="lg:grid lg:grid-cols-12 flex flex-col focus-within:bg-gray-100 border-t-2 lg:border-t border-gray-300 lg:border-gray-200 relative text-left items-left lg:items-center pt-4 lg:pt-0"
            >
                <div className="flex flex-row gap-2 lg:col-span-2 text-left">
                    <p className="lg:hidden font-bold">Date of Birth:</p>
                    {moment(dob, 'YYYY/MM/DD').format('MM/DD/YYYY')}
                </div>
                <div className="flex flex-row gap-2 lg:col-span-2 align-center text-left">
                    <p className="lg:hidden font-bold">Participant ID:</p>
                    {participant_uid}
                </div>
                <div className="flex flex-row gap-2 lg:col-span-3 align-center">
                    <p className="lg:hidden font-bold">Name:</p>
                    {first_name + ' ' + last_name}
                </div>
                <div className="flex flex-row gap-2 lg:col-span-2 align-center">
                    <p className="lg:hidden font-bold">QR Code #:</p>
                    {qr}
                </div>
                {showSelect && (
                    <div className="col-span-1 text-right flex flex-row gap-2 items-center py-4">
                        <Link
                            onClick={(e) => handleSelect(e, id)}
                            className="bg-gray-100 text-gray-500 font-bold px-4 py-2 rounded whitespace-nowrap text-sm hover:underline"
                        >
                            Edit
                        </Link>
                        <Link
                            className="bg-customOrange hover:bg-white hover:text-customOrange border-2 border-customOrange text-white font-bold px-4 py-2 rounded whitespace-nowrap text-sm"
                            onClick={(e) => {
                                selectParticipant(e, id)
                            }}
                        >
                            Make active participant
                        </Link>
                    </div>
                )}
            </div>
        </>
    )
}
