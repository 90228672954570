import React, { useState, useEffect, useRef } from 'react'
import { usePrevious } from 'react-use'
import { router } from '@inertiajs/react'
import pickBy from 'lodash/pickBy'
import { QrcodeIcon } from '@heroicons/react/solid'
import InputMask from 'react-input-mask'
import { debounce } from 'lodash'
import { SearchIcon } from '@heroicons/react/outline'

export default function SearchBar({
    label,
    scan,
    poo,
    isDobInput,
    showQr,
    setShowQr,
    qrValue,
    style,
}) {
    const [values, setValues] = useState({
        search: '',
        trashed: '',
        QR: '',
    })

    const inputRef = useRef(null)

    function handleChange(e) {
        const key = e.target.name
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
    }

    const prevValues = usePrevious(values)
    function reset() {
        setValues({
            search: '',
            trashed: '',
            QR: '',
        })
    }

    useEffect(() => {
        const renderQr = qrValue
        setValues({
            QR: renderQr,
        })
    }, [qrValue])

    // useEffect(() => {
    //     // https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
    //     if (prevValues) {
    //         const query = Object.keys(pickBy(values)).length
    //             ? pickBy(values)
    //             : { remember: 'forget' }
    //         router.get(route(route().current()), query, {
    //             replace: true,
    //             preserveState: true,
    //         })
    //     }
    // }, [values])
    useEffect(() => {
        // if (isDobInput) {
        //     const post = debounce(
        //         () => router.post(route('search.post'), values),
        //         3000
        //     )
        //     post()
        // } else {
        if (label != 'DOB') {
            router.post(route('search.post'), values)
        }
        // }
    }, [values])
    function searchDate() {
        router.post(route('search.post'), values)
    }
    return (
        <>
            <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row gap-2 items-center relative">
                    <h2 className="font-semibold text-2xl">{label}</h2>
                    {scan == 'true' && (
                        <>
                            <label className="hidden lg:block" htmlFor="QR">
                                <div className="rounded-md p-2 bg-gray-100 hover:bg-gray-200 flex flex-row gap-2 items-center absolute bottom-0 right-0">
                                    <p>Scan</p>
                                    <QrcodeIcon className="h-4 w-4" />
                                </div>
                            </label>
                            <div
                                className="block lg:hidden rounded-md p-2 bg-gray-100 hover:bg-gray-200 flex flex-row gap-2 items-center"
                                onClick={() => setShowQr(true)}
                            >
                                <p>Scan</p>
                                <QrcodeIcon className="h-4 w-4" />
                            </div>
                        </>
                    )}
                </div>
                <div className="w-full relative">
                    <input
                        className="relative bg-gray-100 border-b-2 border-customOrange w-full px-6 py-3 rounded-r focus:outline-none focus:ring-2 focus:ring-indigo-400"
                        autoComplete="off"
                        type="text"
                        name={label}
                        id={label}
                        value={qrValue !== '' ? qrValue : values.label}
                        onChange={handleChange}
                        placeholder="Search…"
                    />

                    <div className="flex flex-row absolute right-2 top-1/2 transform -translate-y-1/2 ">
                        {label == 'DOB' && (
                            <button
                                onClick=""
                                className="p-2 hover:bg-gray-200 text-sm text-gray-600 focus:text-indigo-700 focus:outline-none"
                                type="button"
                                onClick={searchDate}
                            >
                                <SearchIcon className="h-6 w-6" />
                            </button>
                        )}
                        <button
                            onClick={reset}
                            className="p-2 hover:bg-gray-200 text-sm text-gray-600 focus:text-indigo-700 focus:outline-none"
                            type="button"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
