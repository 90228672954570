import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { router, usePage } from '@inertiajs/react'
import FlashMessages from '../App/Shared/FlashMessages'

export default function SelectLocation({
    // nyhreLocations,
    locations,
    // selectedOrg,
}) {
    const [values, setValues] = useState({
        locationType: '',
        selectedLocation: null,
        // selectedOrg: selectedOrg.selectedOrg,
        // nyhrePermanent: nyhreLocations.nyhrePermanent,
        // nyhreWaivered: nyhreLocations.nyhreWaivered,
        // nyhreOPST: nyhreLocations.nyhreOPST,
        // whcpPermanent: whcpLocations.whcpPermanent,
        // whcpWaivered: whcpLocations.whcpWaivered,
        // whcpOPST: whcpLocations.whcpOPST,
        locations: locations,
        OPST: locations.opst,
        waivered: locations.waivered,
        permanent: locations.permanent,
        other_location_name: '',
        other_location_zip: '',
        other_location_cross_streets: '',
    })

    const { errors } = usePage().props
    const [activeLocation, setActiveLocation] = useState()
    const [options, setOptions] = useState()

    const locationTypeOptions = [
        { value: 'drop_in_center', label: 'Drop In Center' },
        { value: 'waivered_mobile_site', label: 'Waivered Mobile Site' },
        { value: 'opst_site', label: 'OPST Site' },
        { value: 'temp', label: 'Temporary Site' },
    ]

    const otherLocationTypeOptions = [
        { value: 'waivered_mobile_site', label: 'Waivered Mobile Site' },
        { value: 'opst_site', label: 'OPST Site' },
    ]

    //onchange handler for dropdowns
    function setLocationType(opt, id) {
        setValues((values) => ({
            ...values,
            locationType: opt.value,
        }))
    }

    function setLocation(opt) {
        setValues((values) => ({
            ...values,
            selectedLocation: opt.value,
        }))
    }

    // set dropdown options
    function setLocationList() {
        if (values.locationType == '') {
            return
        } else if (values.locationType == 'drop_in_center') {
            setActiveLocation(values.permanent)
        } else if (values.locationType == 'waivered_mobile_site') {
            setActiveLocation(values.waivered)
        } else if (values.locationType == 'opst_site') {
            setActiveLocation(values.OPST)
        }
    }

    useEffect(() => {
        setLocationList()
    }, [values.locationType])

    useEffect(() => {
        if (activeLocation) {
            setOptions(
                activeLocation.map(({ name, id }) => ({
                    label: name,
                    value: id,
                }))
            )
        }
    }, [activeLocation])

    function handleSubmit(e) {
        e.preventDefault()
        router.post('/select-location', values)
    }

    function handleOther(e) {
        const key = e.target.name
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
    }

    return (
        <>
            <FlashMessages />
            <div>
                <section className="h-auto flex flex-wrap justify-center mt-2 md:mt-20">
                    <div className="h-auto max-w-lg w-full mx-4 grid shadow-md bg-white rounded">
                        <div className="flex flex-col items-center justify-center h-24 w-full bg-gray-200 rounded rounded-b-none">
                            <h1 className="text-2xl mt-2">
                                Please select a location to continue
                            </h1>
                        </div>
                        <div className="block w-full my-8">
                            <div className="flex flex-col md:flex-row flex-wrap px-4">
                                <>
                                    <p className="text-gray-500 w-full text-md mb-2">
                                        Please select location type
                                    </p>
                                    <Select
                                        label="Single select"
                                        options={locationTypeOptions}
                                        className="w-full mb-4"
                                        classNamePrefix="select"
                                        onChange={(opt) => setLocationType(opt)}
                                        // defaultValue={options[0]}
                                        // isDisabled={isDisabled}
                                        // isLoading={isLoading}
                                        // isClearable={isClearable}
                                        // isRtl={isRtl}
                                        isSearchable={true}
                                        name="locationTypeOptions"
                                    />
                                    {values.locationType &&
                                        values.locationType != 'temp' && (
                                            <>
                                                <p className="text-gray-500 w-full text-md mb-2">
                                                    Please select location
                                                </p>
                                                <Select
                                                    label="Single select"
                                                    options={options}
                                                    // selectOption={options}
                                                    className="w-full mb-8"
                                                    classNamePrefix="select"
                                                    onChange={(opt) =>
                                                        setLocation(opt)
                                                    }
                                                    isSearchable={true}
                                                    name="locations"
                                                />
                                            </>
                                        )}
                                </>
                                {values.locationType == 'temp' && (
                                    <div className="flex flex-col flex-wrap md:flex-nowrap w-full">
                                        <p className="text-gray-500 w-full text-md mb-2">
                                            Enter in Temporary Location Details
                                        </p>
                                        <div className="flex flex-col w-full mb-4">
                                            {errors.other_location_name && (
                                                <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                                    {errors.other_location_name}
                                                </div>
                                            )}
                                            <label
                                                className="block text-gray-700 text-sm mb-2 ml-1"
                                                htmlFor="other_location_name"
                                            >
                                                Location Name
                                            </label>
                                            <input
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                onChange={handleOther}
                                                value={
                                                    values.other_location_name
                                                }
                                                id="other_location_name"
                                                name="other_location_name"
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="flex flex-col w-full mb-4">
                                            {errors.other_location_zip && (
                                                <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                                    {errors.other_location_zip}
                                                </div>
                                            )}
                                            <label
                                                className="block text-gray-700 text-sm mb-2 ml-1"
                                                htmlFor="other_location_zip"
                                            >
                                                Location Zip Code
                                            </label>
                                            <input
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                onChange={handleOther}
                                                value={
                                                    values.other_location_zip
                                                }
                                                id="other_location_zip"
                                                name="other_location_zip"
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="flex flex-col w-full mb-4">
                                            <label
                                                className="block text-gray-700 text-sm mb-2 ml-1"
                                                htmlFor="other_location_cross_streets"
                                            >
                                                Location Cross Streets(optional)
                                            </label>
                                            <input
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                onChange={handleOther}
                                                value={
                                                    values.other_location_cross_streets
                                                }
                                                id="other_location_cross_streets"
                                                name="other_location_cross_streets"
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="flex mb-6 w-full">
                                    <button
                                        type="submit"
                                        className="bg-gray-200 shadow-md w-full rounded p-2"
                                        onClick={handleSubmit}
                                    >
                                        Set location and sign in
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
