import React from 'react'
import { usePage } from '@inertiajs/react'
import NavPopOutItem from './NavPopOutItem'

export default function NavPopOut({ }) {
    const { user } = usePage().props

    return (
        <>
            {user.is.currently_seeing == null ? (
                <div className="flex flex-col static md:absolute z-50 md:top-0 md:w-56 md:h-auto md:right-0 md:transform md:translate-x-full ml-8 md:ml-16 md:justify-start bg-white rounded-sm shadow-none md:shadow-lg">
                    <NavPopOutItem
                        name="Search for participant"
                        link="/participants/search"
                    />
                </div>
            ) : (
                <div className="flex flex-col relative md:absolute z-50 md:top-0 md:w-56 md:h-auto md:right-0 md:transform md:translate-x-full ml-8 md:ml-16 md:justify-start bg-white rounded-sm shadow-none md:shadow-lg">
                    <NavPopOutItem
                        name="Syringe / Harm Reduction Services"
                        link="/exchange/show1"
                    />
                    <NavPopOutItem
                        name="Drop In / Outreach"
                        link="/dropin/create"
                    />
                    <NavPopOutItem
                        name="Narcan / Naloxone Recipient"
                        link={route('narcan.show')}
                    />
                    <NavPopOutItem
                        name="Holistic Services"
                        link="/holistic/create"
                    />
                    <NavPopOutItem
                        name="Smoking Kit"
                        link="/smokingkit/create"
                    />
                    <NavPopOutItem
                        name="Vaccine Service"
                        link="/vaccineservice/create"
                    />
                </div>
            )}
        </>
    )
}
