import React, { useState, useEffect } from 'react'
import Layout from '../../../../Layout/Layout'
import { usePage, useForm } from '@inertiajs/react'
import Select from 'react-select'

export default function ExchangePageOne({ referral_options }) {
    const { user } = usePage().props
    const { data, setData, post, processing, errors } = useForm({
        works_in: '',
        works_out: '',
        referral_code: [],
        referral_code_other: '',
        referred_to: '',
        safer_sex_supplies: false,
        sniffing_kits: false,
        hygiene_supplies: false,
        wound_care_supplies: false,
        all_weather_supplies: false,
        haz_waste_supplies: false,
        socks_clothes: false,
        taper_withdrawal_kits: false,
        hormone_steroid_kits: false,
        drug_testing_strips: false,
        supply_other: false,
        supply_other_details: '',
        fentanyl_test_strips_number: 0,
        xylazine_test_strips_number: 0,
        benzodiazepine_test_strips_number: 0,
        cocaine_test_strips_number: 0,
        ketamine_test_strips_number: 0,
        methamphetamine_test_strips_number: 0,
        morphine_test_strips_number: 0,
        other_test_strips_type: '',
        other_test_strips_number: 0,
        bupe_edu_received: false,
        safer_injection_counseling_received: false,
        safer_disposal_counseling_received: false,
        sexual_rr_condom_use_received: false,
        od_prevention_edu_received: false,
        info_on_prep_received: false,
        tx_options_counseling_received: false,
        covid_19_edu_received: false,
        covid_19_counseling_received: false,
        wound_care_received: false,
        used_moum_materials: false,
        services_other: false,
        services_other_details: '',
        services_description: '',
    })
    const [options, setOptions] = useState([])

    useEffect(() => {
        setOptions(
            referral_options.reduce(({ label, value }) => [
                {
                    label: label,
                    value: value,
                },
            ])
        )
    }, [options])

    function setSelectedReferrals(opt) {
        setData({
            ...data,
            referral_code: opt,
        })
    }

    const [showDropdown, setShowDropdown] = useState(false)
    const onClick = () => setShowDropdown(!showDropdown)
    function handleButtons(e) {
        let buttonValues = data.services_received
        if (buttonValues.includes(e.target.id)) {
            for (var i = 0; i < buttonValues.length; i++) {
                if (buttonValues[i] === e.target.id) {
                    buttonValues.splice(i, 1)
                }
            }
        } else {
            buttonValues.push(e.target.id)
        }
        setData({
            ...data,
            services_received: buttonValues,
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        post('/exchange/create1')
    }

    return (
        <>
            <Layout title="Syringe/Harm Reduction Service Form">
                <form onSubmit={handleSubmit}>
                    <div className="mt-2 flex w-full flex-col flex-wrap bg-white p-8 shadow-md md:mb-0 md:p-16">
                        <div className="mb-4 flex flex-row flex-wrap items-center">
                            <h5 className="mr-2 text-2xl text-gray-700">
                                Processing Syringe/Harm Reduction Service for:
                            </h5>
                            <p className="text-2xl font-semibold">{user.is.currently_seeing}</p>
                        </div>
                        <div className="mb-4 flex flex-row flex-wrap gap-4 md:flex-nowrap md:gap-2">
                            <div className="w-full text-gray-700 md:w-6/12">
                                {errors.works_in && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                        {errors.works_in}
                                    </div>
                                )}
                                <label className="mb-2 block">Works In</label>
                                <input
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('works_in', e.target.value)}
                                    value={data.works_in}
                                    id="works_in"
                                />
                            </div>
                            <div className="w-full text-gray-700 md:w-6/12">
                                {errors.works_out && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                        {errors.works_out}
                                    </div>
                                )}
                                <label className="mb-2 block">Works Out</label>
                                <input
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('works_out', e.target.value)}
                                    value={data.works_out}
                                    id="works_out"
                                />
                            </div>
                        </div>
                        <div className="mb-4 flex flex-row flex-wrap gap-4 md:flex-nowrap md:gap-2">
                            <div className="w-full text-gray-700">
                                {errors.referral_code && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                        {errors.referral_code}
                                    </div>
                                )}
                                <label className="mb-2 block">Referral Codes</label>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: 0,
                                            height: '2.5rem',
                                            borderColor: '#a2a2a2',
                                        }),
                                    }}
                                    isMulti
                                    options={options}
                                    className="h-10 w-full text-gray-700 md:w-full"
                                    placeholder="type or select"
                                    onChange={
                                        (opt) => setSelectedReferrals(opt)
                                    }
                                />
                            </div>
                            {/* <SearchableDropdown
                                showDropdown={showDropdown}
                                setShowDropdown={setShowDropdown}
                                id="referral code"
                                placeholder="Click or Type to see options"
                                label="Referral Code"
                            ></SearchableDropdown> */}
                        </div>
                        <div className="mb-4 flex flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                            <div className="w-full text-gray-700">
                                {errors.referral_code_other && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                        {errors.referral_code_other}
                                    </div>
                                )}
                                <label className="mb-2 block">Referral Codes (Other)</label>
                                <input
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    onChange={(e) => setData('referral_code_other', e.target.value)}
                                    value={data.referral_code_other}
                                    id="referral_code_other"
                                />
                            </div>
                        </div>
                        <div className="mb-4 flex flex-row flex-wrap gap-4 md:gap-0">
                            <div className="w-full text-gray-700">
                                {errors.referred_to && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                        {errors.referred_to}
                                    </div>
                                )}
                                <label className="mb-2 block">Referred To</label>
                                <input
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('referred_to', e.target.value)}
                                    value={data.referred_to}
                                    id="referred_to"
                                />
                            </div>
                        </div>
                        <h5 className="mb-4 mt-4 font-semibold text-gray-700">Supply Distribution</h5>
                        <div className="mb-4 flex w-full flex-wrap">
                            <button
                                className={
                                    data.safer_sex_supplies
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="safer_sex_supplies"
                                value={data.safer_sex_supplies}
                                onClick={(e) => setData('safer_sex_supplies', !data.safer_sex_supplies)}
                            >
                                Safer Sex Supplies
                            </button>
                            <button
                                className={
                                    data.sniffing_kits
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="sniffing_kits"
                                value={data.sniffing_kits}
                                onClick={(e) => setData('sniffing_kits', !data.sniffing_kits)}
                            >
                                Sniffing Kits
                            </button>
                            <button
                                className={
                                    data.hygiene_supplies
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="hygiene_supplies"
                                value={data.hygiene_supplies}
                                onClick={(e) => setData('hygiene_supplies', !data.hygiene_supplies)}
                            >
                                Hygiene Supplies
                            </button>
                            <button
                                className={
                                    data.wound_care_supplies
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="wound_care_supplies"
                                value={data.wound_care_supplies}
                                onClick={(e) => setData('wound_care_supplies', !data.wound_care_supplies)}
                            >
                                Wound Care Supplies
                            </button>
                            <button
                                className={
                                    data.all_weather_supplies
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="all_weather_supplies"
                                value={data.all_weather_supplies}
                                onClick={(e) => setData('all_weather_supplies', !data.all_weather_supplies)}
                            >
                                All-Weather Supplies
                            </button>
                            <button
                                className={
                                    data.haz_waste_supplies
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="haz_waste_supplies"
                                value={data.haz_waste_supplies}
                                onClick={(e) => setData('haz_waste_supplies', !data.haz_waste_supplies)}
                            >
                                Haz Waste Supplies
                            </button>
                            <button
                                className={
                                    data.socks_clothes
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="socks_clothes"
                                value={data.socks_clothes}
                                onClick={(e) => setData('socks_clothes', !data.socks_clothes)}
                            >
                                Socks/Clothes
                            </button>
                            <button
                                className={
                                    data.taper_withdrawal_kits
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="taper_withdrawal_kits"
                                value={data.taper_withdrawal_kits}
                                onClick={(e) => setData('taper_withdrawal_kits', !data.taper_withdrawal_kits)}
                            >
                                Taper/Withdrawal Kits
                            </button>
                            <button
                                className={
                                    data.hormone_steroid_kits
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="hormone_steroid_kits"
                                value={data.hormone_steroid_kits}
                                onClick={(e) => setData('hormone_steroid_kits', !data.hormone_steroid_kits)}
                            >
                                Hormone/Steroid Kits
                            </button>
                            <button
                                className={
                                    data.drug_testing_strips
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="drug_testing_strips"
                                value={data.drug_testing_strips}
                                onClick={(e) => setData('drug_testing_strips', !data.drug_testing_strips)}
                            >
                                Drug Testing Strips
                            </button>
                            <button
                                className={
                                    data.supply_other
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="supply_other"
                                value={data.supply_other}
                                onClick={(e) => setData('supply_other', !data.supply_other)}
                            >
                                Other
                            </button>
                            {data.supply_other && (
                                <div className=" mb-4  mt-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.supply_other_details && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.supply_other_details}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Other Supplies Details:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="text"
                                            onChange={(e) => setData('supply_other_details', e.target.value)}
                                            value={data.supply_other_details}
                                            id="supply_other_details"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* Drug Testing Kit Numbers */}
                        {data.drug_testing_strips && (
                            <div>
                                <h2 className="mb-4 mt-8 text-lg font-bold">Drug Testing Strip Details</h2>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4  md:gap-0">
                                    <div className="w-full md:flex md:flex-col text-gray-700">
                                        <label className="mb-2 block md:flex-1 md:whitespace-nowrap">
                                            Number of Fentanyl Test Strips:
                                        </label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('fentanyl_test_strips_number', e.target.value)}
                                            value={data.fentanyl_test_strips_number}
                                            id="fentanyl_test_strips_number"
                                        />
                                    </div>
                                    {errors.fentanyl_test_strips_number && (
                                        <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                            {errors.fentanyl_test_strips_number}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.xylazine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.xylazine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Xylazine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('xylazine_test_strips_number', e.target.value)}
                                            value={data.xylazine_test_strips_number}
                                            id="xylazine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.benzodiazepine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.benzodiazepine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Benzodiazepine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) =>
                                                setData('benzodiazepine_test_strips_number', e.target.value)
                                            }
                                            value={data.benzodiazepine_test_strips_number}
                                            id="benzodiazepine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.cocaine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.cocaine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Cocaine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('cocaine_test_strips_number', e.target.value)}
                                            value={data.cocaine_test_strips_number}
                                            id="cocaine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.ketamine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.ketamine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Ketamine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('ketamine_test_strips_number', e.target.value)}
                                            value={data.ketamine_test_strips_number}
                                            id="ketamine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.methamphetamine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.methamphetamine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Methamphetamine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) =>
                                                setData('methamphetamine_test_strips_number', e.target.value)
                                            }
                                            value={data.methamphetamine_test_strips_number}
                                            id="methamphetamine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.morphine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.morphine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Morphine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('morphine_test_strips_number', e.target.value)}
                                            value={data.morphine_test_strips_number}
                                            id="morphine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <h2 className="mb-4 mt-8 text-md font-semibold">Other Testing Strip Details</h2>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.other_test_strips_type && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.other_test_strips_type}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Type of Other Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="test"
                                            onChange={(e) => setData('other_test_strips_type', e.target.value)}
                                            value={data.other_test_strips_type}
                                            id="other_test_strips_type"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.other_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.other_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Other Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('other_test_strips_number', e.target.value)}
                                            value={data.other_test_strips_number}
                                            id="other_test_strips_number"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <h5 className="mb-4 mt-4 font-semibold text-gray-700">Services Received</h5>
                        {errors.services_received && (
                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                {errors.services_received}
                            </div>
                        )}
                        <div className="mb-4 flex w-full flex-wrap">
                            <button
                                className={
                                    data.bupe_edu_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="bupe_edu_received"
                                value={data.bupe_edu_received}
                                onClick={(e) => setData('bupe_edu_received', !data.bupe_edu_received)}
                            >
                                Bupe Edu
                            </button>
                            <button
                                className={
                                    data.safer_injection_counseling_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="safer_injection_counseling_received"
                                value={data.safer_injection_counseling_received}
                                onClick={(e) =>
                                    setData(
                                        'safer_injection_counseling_received',
                                        !data.safer_injection_counseling_received
                                    )
                                }
                            >
                                Safer Injection Counseling
                            </button>
                            <button
                                className={
                                    data.safer_disposal_counseling_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="safer_disposal_counseling_received"
                                value={data.safer_disposal_counseling_received}
                                onClick={(e) =>
                                    setData(
                                        'safer_disposal_counseling_received',
                                        !data.safer_disposal_counseling_received
                                    )
                                }
                            >
                                Safer Disposal Counseling
                            </button>
                            <button
                                className={
                                    data.sexual_rr_condom_use_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="sexual_rr_condom_use_received"
                                value={data.sexual_rr_condom_use_received}
                                onClick={(e) =>
                                    setData('sexual_rr_condom_use_received', !data.sexual_rr_condom_use_received)
                                }
                            >
                                Sexual RR / Condom Use
                            </button>
                            <button
                                className={
                                    data.od_prevention_edu_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="od_prevention_edu_received"
                                value={data.od_prevention_edu_received}
                                onClick={(e) => setData('od_prevention_edu_received', !data.od_prevention_edu_received)}
                            >
                                OD Prevention Edu
                            </button>
                            <button
                                className={
                                    data.info_on_prep_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="info_on_prep_received"
                                value={data.info_on_prep_received}
                                onClick={(e) => setData('info_on_prep_received', !data.info_on_prep_received)}
                            >
                                Info on PrEP
                            </button>
                            <button
                                className={
                                    data.tx_options_counseling_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="tx_options_counseling_received"
                                value={data.tx_options_counseling_received}
                                onClick={(e) =>
                                    setData('tx_options_counseling_received', !data.tx_options_counseling_received)
                                }
                            >
                                Tx Options Counseling
                            </button>
                            <button
                                className={
                                    data.covid_19_edu_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="covid_19_edu_received"
                                value={data.covid_19_edu_received}
                                onClick={(e) => setData('covid_19_edu_received', !data.covid_19_edu_received)}
                            >
                                COVID-19 Edu
                            </button>
                            <button
                                className={
                                    data.covid_19_counseling_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="covid_19_counseling_received"
                                value={data.covid_19_counseling_received}
                                onClick={(e) =>
                                    setData('covid_19_counseling_received', !data.covid_19_counseling_received)
                                }
                            >
                                COVID-19 Counseling
                            </button>
                            <button
                                className={
                                    data.wound_care_received
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="wound_care_received"
                                value={data.wound_care_received}
                                onClick={(e) => setData('wound_care_received', !data.wound_care_received)}
                            >
                                Wound Care Received
                            </button>
                            <button
                                className={
                                    data.used_moum_materials
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="used_moum_materials"
                                value={data.used_moum_materials}
                                onClick={(e) => setData('used_moum_materials', !data.used_moum_materials)}
                            >
                                Used MOUM Materials
                            </button>
                            <button
                                className={
                                    data.services_other
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="services_other"
                                value={data.services_other}
                                onClick={(e) => setData('services_other', !data.services_other)}
                            >
                                Other
                            </button>
                            {data.services_other && (
                                <div className="mb-4 mt-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.services_other_details && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.services_other_details}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Other Services Details:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="text"
                                            onChange={(e) => setData('services_other_details', e.target.value)}
                                            value={data.services_other_details}
                                            id="services_other_details"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="mb-4 flex flex-row flex-wrap">
                            <div className="w-full text-gray-700">
                                {errors.services_description && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                        {errors.services_description}
                                    </div>
                                )}
                                <label className="mb-2 block">Describe Edu / Counseling</label>
                                <input
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('services_description', e.target.value)}
                                    value={data.services_description}
                                    id="services_description"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-customBlue mb-8 flex w-full flex-row flex-wrap items-center space-y-4 px-8 py-7 md:space-x-4 md:space-y-0 md:px-16">
                        <button
                            type="submit"
                            className="text-customBlue flex h-12 w-full flex-row items-center justify-center bg-white px-8 py-2 font-bold hover:underline md:w-auto"
                            disabled={processing}
                        >
                            {processing && (
                                <div>
                                    <svg className="mr-2 animate-spin" width="18px" height="18px" viewBox="0 0 120 120">
                                        <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                                            <g
                                                id="Group"
                                                transform="translate(9.000000, 9.000000)"
                                                stroke="#314EB9"
                                                strokeWidth="9"
                                            >
                                                <path
                                                    d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                    id="Path"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            )}
                            Submit
                        </button>
                        <div className="w-auto flex-col items-center rounded-full bg-white py-2 md:w-auto md:flex-row">
                            <div className="flex flex-row items-center px-4">
                                <small className="pr-2">Step 1 of 1</small>
                                <div className="bg-customBlue h-4 w-4 rounded-full"></div>
                            </div>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}
