import React from 'react'
import Layout from '../../../../Layout/Layout'
import { usePage, useForm } from '@inertiajs/react'

export default function SmokingKit() {
    const { data, setData, post, processing, errors } = useForm({
        stem: false,
        bowl: false,
    })

    const { user } = usePage().props

    function handleSubmit(e) {
        e.preventDefault()
        post('/smokingkit/create')
    }

    return (
        <Layout title="Smoking Kit">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 md:mb-0 mt-2">
                    <div className="flex flex-row flex-wrap items-center mb-4">
                        <h5 className="text-2xl text-gray-700 mr-2">
                            Processing Smoking Kit for:
                        </h5>
                        <p className="text-2xl font-semibold">
                            {user.is.currently_seeing}
                        </p>
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-4">
                        <h5 className="text-2xl text-gray-700 mr-2">
                            Kit Distributed Type:
                        </h5>
                        <div className="w-full flex flex-wrap my-4">
                            <button
                                className={
                                    data.stem
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="stem"
                                value={data.stem}
                                onClick={(e) =>
                                    setData({ stem: !data.stem, bowl: false })
                                }
                            >
                                Stem
                            </button>
                            <button
                                className={
                                    data.bowl
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="bowl"
                                value={data.bowl}
                                onClick={(e) =>
                                    setData({ bowl: !data.bowl, stem: false })
                                }
                            >
                                Bowl
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row flex-wrap bg-customBlue md:space-x-4 space-y-4 md:space-y-0 items-center py-7 px-8 md:px-16 mb-8">
                    <button
                        type="submit"
                        className="bg-white text-customBlue h-12 py-2 px-8 w-full justify-center md:w-auto font-bold flex flex-row items-center"
                        disabled={processing}
                    >
                        {processing && (
                            <div>
                                <svg
                                    className="animate-spin mr-2"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 120 120"
                                >
                                    <g
                                        id="Artboard"
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                    >
                                        <g
                                            id="Group"
                                            transform="translate(9.000000, 9.000000)"
                                            stroke="#314EB9"
                                            strokeWidth="9"
                                        >
                                            <path
                                                d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                id="Path"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        )}
                        Done
                    </button>
                    <div className="flex-col md:flex-row items-center w-auto md:w-auto bg-white rounded-full py-2">
                        <div className="flex flex-row items-center px-4">
                            <small className="pr-2">Step 1 of 1</small>
                            <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    )
}
