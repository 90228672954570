import React, { useState, useEffect } from 'react'
import AdminLayout from '../../../../Layout/AdminLayout'
import { useForm } from '@inertiajs/react'
import moment from 'moment'

export default function AuditSettings({ audit }) {
    const [allAudits, setAllAudits] = useState(audit || [])
    const { data, setData, post, processing, errors } = useForm({
        participant_qr: '',
        participant_id: '',
        user_email: '',
        auditMode: 'users',
    })

    useEffect(() => {
        setAllAudits([])
    }, [data.auditMode])

    useEffect(() => {
        setAllAudits(audit)
    }, [audit])

    function handleExport(e) {
        e.preventDefault()
        if (data.auditMode === 'users') {
            post(route('audit.users'))
        } else if (data.auditMode === 'participants') {
            post(route('audit.participants'))
        } else if (data.auditMode === 'dropIn') {
            post(route('audit.dropin'))
        } else if (data.auditMode === 'holisticServices') {
            post(route('audit.holistic'))
        } else if (data.auditMode === 'narcanNaloxone') {
            post(route('audit.narcan'))
        } else if (data.auditMode === 'needleExchange') {
            post(route('audit.needle'))
        } else if (data.auditMode === 'smokingKit') {
            post(route('audit.smoking'))
        }
    }

    function handleGenerateSpreadsheet(e) {
        e.preventDefault()
        if (data.auditMode === 'users') {
            post(route('audit.export.users'), data)
        } else if (data.auditMode === 'participants') {
            post(route('audit.export.participants'), data)
        } else if (data.auditMode == 'dropIn') {
            post(route('audit.export.dropin'), data)
        } else if (data.auditMode == 'holisticServices') {
            post(route('audit.export.holistic'), data)
        } else if (data.auditMode == 'narcanNaloxone') {
            post(route('audit.export.narcan'), data)
        } else if (data.auditMode == 'needleExchange') {
            post(route('audit.export.needle'), data)
        } else if (data.auditMode == 'smokingKit') {
            post(route('audit.export.smoking'), data)
        }
    }

    return (
        <>
            <AdminLayout title="Location Settings">
                <div className="flex flex-col flex-wrap w-full p-8 mt-4 overflow-x-auto bg-white shadow-md md:p-16">
                    <h2 className="mt-4 mb-2 text-2xl font-semibold">Audit</h2>
                    <p className="mb-4 max-w-3xl">
                        To run an audit, first select the type. Leaving the
                        input field blank will return the last 500 results of
                        that type.
                    </p>
                    <form
                        className="flex flex-col gap-16"
                        onSubmit={handleExport}
                    >
                        <div className="flex flex-row items-center flex-wrap gap-2 mt-12">
                            <div className="flex gap-4">
                                <button
                                    type="button"
                                    className={
                                        data.auditMode === 'users'
                                            ? 'bg-customPurple text-white px-4 py-2'
                                            : 'bg-gray-200 hover:bg-gray-100 px-4 py-2'
                                    }
                                    onClick={() => {
                                        setData('auditMode', 'users')
                                    }}
                                >
                                    Users
                                </button>
                                <button
                                    type="button"
                                    className={
                                        data.auditMode === 'participants'
                                            ? 'bg-customPurple text-white px-4 py-2'
                                            : 'bg-gray-200 hover:bg-gray-100 px-4 py-2'
                                    }
                                    onClick={() => {
                                        setData('auditMode', 'participants')
                                    }}
                                >
                                    Participants
                                </button>
                                <button
                                    type="button"
                                    className={
                                        data.auditMode === 'dropIn'
                                            ? 'bg-customPurple text-white px-4 py-2'
                                            : 'bg-gray-200 hover:bg-gray-100 px-4 py-2'
                                    }
                                    onClick={() =>
                                        setData('auditMode', 'dropIn')
                                    }
                                >
                                    Drop-Ins
                                </button>
                                <button
                                    type="button"
                                    className={
                                        data.auditMode === 'holisticServices'
                                            ? 'bg-customPurple text-white px-4 py-2'
                                            : 'bg-gray-200 hover:bg-gray-100 px-4 py-2'
                                    }
                                    onClick={() =>
                                        setData('auditMode', 'holisticServices')
                                    }
                                >
                                    Holistic Services
                                </button>
                                <button
                                    type="button"
                                    className={
                                        data.auditMode === 'narcanNaloxone'
                                            ? 'bg-customPurple text-white px-4 py-2'
                                            : 'bg-gray-200 hover:bg-gray-100 px-4 py-2'
                                    }
                                    onClick={() =>
                                        setData('auditMode', 'narcanNaloxone')
                                    }
                                >
                                    Narcan/Naloxone
                                </button>
                                <button
                                    type="button"
                                    className={
                                        data.auditMode === 'needleExchange'
                                            ? 'bg-customPurple text-white px-4 py-2'
                                            : 'bg-gray-200 hover:bg-gray-100 px-4 py-2'
                                    }
                                    onClick={() =>
                                        setData('auditMode', 'needleExchange')
                                    }
                                >
                                    Needle Exchange
                                </button>
                                <button
                                    type="button"
                                    className={
                                        data.auditMode === 'smokingKit'
                                            ? 'bg-customPurple text-white px-4 py-2'
                                            : 'bg-gray-200 hover:bg-gray-100 px-4 py-2'
                                    }
                                    onClick={() =>
                                        setData('auditMode', 'smokingKit')
                                    }
                                >
                                    Smoking Kit
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center pb-8 border-b border-gray-300">
                            {data.auditMode != 'users' ? (
                                <>
                                    <div className="text-gray-700 relative">
                                        {/* {errors.crossStreet && (
                                <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                    {errors.crossStreet}
                                </div>
                            )} */}
                                        <label className="block mb-2 text-black md:absolute -top-8">
                                            Participant ID
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                setData(
                                                    'participant_id',
                                                    e.target.value
                                                )
                                            }
                                            value={data.participant_id}
                                            className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                            type="text"
                                            placeholder=""
                                            id="participant_id"
                                        />
                                    </div>
                                    {data.auditMode === 'participants' ? (
                                        <>
                                            <p className="px-4">or</p>
                                            <div className="text-gray-700 relative ">
                                                <label className="block mb-2 text-black md:absolute -top-8">
                                                    Participant QR
                                                </label>
                                                <input
                                                    onChange={(e) =>
                                                        setData(
                                                            'participant_qr',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={data.participant_qr}
                                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                                    type="text"
                                                    placeholder=""
                                                    id="participant_qr"
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                            {data.auditMode === 'users' ? (
                                <>
                                    <div className="text-gray-700 relative">
                                        {/* {errors.crossStreet && (
                                <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                    {errors.crossStreet}
                                </div>
                            )} */}
                                        <label className="block mb-2 text-black md:absolute -top-8">
                                            User Email
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                setData(
                                                    'user_email',
                                                    e.target.value
                                                )
                                            }
                                            value={data.user_email}
                                            className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                            type="text"
                                            placeholder=""
                                            id="user_email"
                                        />
                                    </div>
                                </>
                            ) : null}
                            <button
                                className="flex-grow-0 md:ml-8 px-4 py-2 bg-gray-100 hover:bg-gray-200"
                                type="submit"
                            >
                                Generate
                            </button>
                            <button
                                className="flex-grow-0 md:ml-2 px-4 py-2 bg-gray-100 hover:bg-gray-200"
                                onClick={(e) => handleGenerateSpreadsheet(e)}
                            >
                                Export Spreadsheet
                            </button>
                        </div>
                    </form>
                    <>
                        {allAudits && allAudits.length > 0 ? (
                            <>
                                <table className="divide-y divide-gray-300 table-auto w-full overflow-x-auto">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                            >
                                                Created At
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                User making the change
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Event
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-xs"
                                            >
                                                Old Values
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-xs"
                                            >
                                                New Values
                                            </th>
                                            {data.auditMode != 'users' ? (
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Participant ID
                                                </th>
                                            ) : null}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {allAudits.map((a) => (
                                            <tr key={a.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {moment(
                                                        a.created_at
                                                    ).format(
                                                        'MMMM Do, YYYY h:mm:ss A'
                                                    )}
                                                </td>
                                                {a.user ? (
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                    >
                                                        {a.user['email']}
                                                    </th>
                                                ) : (
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                    >
                                                        Email not found
                                                    </th>
                                                )}
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {a.event}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs truncate">
                                                    <DisplayObjectProperties
                                                        obj={a.old_values}
                                                    />
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs">
                                                    <DisplayObjectProperties
                                                        obj={a.new_values}
                                                    />
                                                </td>
                                                {data.auditMode != 'users' ? (
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {a.participant_id}
                                                    </td>
                                                ) : null}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : // <div className="mt-12 text-center text-gray-400 grid grid-cols-12">
                            //     <div className="md:col-span-3">Date:</div>
                            //     <div className="md:col-span-3">User:</div>
                            //     <div className="md:col-span-3">Old Values:</div>
                            //     <div className="md:col-span-3">New Values:</div>
                            //     {audit.map((a) => (
                            //         <>
                            //             <div className="col-span-12 md:col-span-3">{a.created_at}</div>
                            //             <div className="col-span-12 md:col-span-3">{a.user.name}</div>
                            //             <div className="col-span-12 md:col-span-3">{`${a.old_values}`}</div>
                            //             {/* <div className="col-span-12 md:col-span-3">
                            //                         {a.old_values.map((val) => val[0])}
                            //                     </div>
                            //                     <div className="col-span-12 md:col-span-3">
                            //                         {a.new_values.map((val) => val[-])}
                            //                     </div> */}
                            //         </>
                            //     ))}
                            // </div>
                            null}
                        {audit && audit.length == 0 ? (
                            <h1 className="text-xl font-medium mt-8">
                                {' '}
                                No Audits Found
                            </h1>
                        ) : null}
                    </>
                </div>
            </AdminLayout>
        </>
    )
}

// Functional component to display object properties
const DisplayObjectProperties = ({ obj }) => {
    return (
        <div>
            <ul>
                {Object.entries(obj).map(([key, value]) => {
                    if (value == true) {
                        value = 1
                    } else if (value == false) {
                        value = 0
                    }
                    return (
                        <li className="truncate" key={key}>
                            {key}: {value !== null ? value : 'null'}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
