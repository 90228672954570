import React, { useState } from 'react'
import { router, usePage } from '@inertiajs/react'

export default function Register() {
    const { errors } = usePage().props
    const [values, setValues] = useState({
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
    })

    function handleChange(e) {
        const key = e.target.id
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()
        router.post(route('register', route().params), values)
    }

    // submitAction = () => {
    //     //
    // }
    return (
        // <Layout title="Welcome">
        <form onSubmit={handleSubmit}>
            <section
                id="authentication"
                className="flex flex-wrap justify-center h-auto mt-2 md:mt-20"
            >
                <div className="grid w-full h-auto max-w-lg mx-4 bg-white rounded shadow-md">
                    <div className="flex items-center justify-center w-full h-24 bg-gray-200 rounded rounded-b-none">
                        <h1 className="text-2xl">Make An Account</h1>
                    </div>
                    <div id="sign_in_container" className="block w-full mt-4">
                        <div className="mx-4">
                            <label
                                className="block mb-2 ml-1 text-sm text-gray-700"
                                htmlFor="Name"
                            >
                                First Name
                            </label>
                            <input
                                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                                onChange={handleChange}
                                value={values.first_name}
                                id="first_name"
                                type="text"
                                placeholder=""
                            />
                            <label
                                className="block mb-2 ml-1 text-sm text-gray-700"
                                htmlFor="Name"
                            >
                                Last Name
                            </label>
                            <input
                                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                                onChange={handleChange}
                                value={values.last_name}
                                id="last_name"
                                type="text"
                                placeholder=""
                            />
                            <label
                                className="block mb-2 ml-1 text-sm text-gray-700"
                                htmlFor="email"
                            >
                                Email Address
                            </label>
                            <input
                                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                                onChange={handleChange}
                                value={values.email}
                                id="email"
                                type="text"
                                placeholder=""
                            />
                            <label
                                className="block mt-2 mb-2 ml-1 text-sm text-gray-700"
                                htmlFor="password"
                            >
                                Password
                            </label>
                            <input
                                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline "
                                onChange={handleChange}
                                value={values.password}
                                id="password"
                                type="password"
                                placeholder=""
                            />
                            <label
                                className="block mt-2 mb-2 ml-1 text-sm text-gray-700"
                                htmlFor="password_confirmation"
                            >
                                Confirm Password
                            </label>
                            <input
                                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline "
                                onChange={handleChange}
                                value={values.password_confirmation}
                                id="password_confirmation"
                                type="password"
                                placeholder=""
                            />
                            <div className="w-full h-px my-8 bg-gray-300"></div>
                            <div className="flex my-6">
                                <button
                                    className="w-full p-2 bg-gray-200 rounded shadow-md"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
        // </Layout>
    )
}
