import React, { useState } from 'react'
import { usePage, Link, router } from '@inertiajs/react'
import FlashMessages from '../App/Shared/FlashMessages'

export default function Login({ locationLists }) {
    const { errors } = usePage().props
    const [values, setValues] = useState({
        email: '',
        password: '',
        org: '',
        //nyhreLocations: locationLists.nyhreLocations,
        //whcpLocations: locationLists.whcpLocations,
    })
    const [activeLocations, setActiveLocations] = useState()
    const [options, setOptions] = useState()
    /*
    function handleLocation() {
        if (org === 'NYHRE') {
            setActiveLocations(values.nyhreLocations)
        }

        if (org === 'WHCP') {
            setActiveLocations(values.whcpLocations)
        }
        if (org) {
        }
    }
    useEffect(() => {
        if (activeLocations) {
            setOptions(
                activeLocations.map(({ name, id }) => ({
                    label: name,
                    value: id,
                }))
            )
        }
    }, [activeLocations])



    */

    /*
    useEffect(() => {
        handleLocation()
    }, [org])
    */

    function handleChange(e) {
        const key = e.target.id
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()
        router.post('/login', values)
    }
    return (
        <>
            <FlashMessages />
            <form onSubmit={handleSubmit}>
                <section
                    id="authentication"
                    className="flex flex-wrap justify-center h-auto mt-2 md:mt-20"
                >
                    <div className="grid w-full h-auto max-w-lg mx-4 bg-white rounded shadow-md">
                        <div className="flex items-center justify-center w-full h-24 bg-gray-200 rounded rounded-b-none">
                            <h1 className="text-2xl">
                                Welcome to OnPoint NYC!
                            </h1>
                        </div>
                        <div
                            id="sign_in_container"
                            className="block w-full mt-4"
                        >
                            <div className="flex flex-col flex-wrap px-4 mb-8 md:flex-row">
                                <p className="w-full text-gray-500 text-md">
                                    Please select your organization to continue
                                </p>
                                <div className="flex flex-col justify-center w-full mt-4 space-y-2 md:flex-row md:space-y-0">
                                    <button
                                        className={
                                            values.org === 'NYHRE'
                                                ? 'bg-customGreen shadow-md text-white px-4 py-2 w-full md:w-6/12 md:mr-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-6/12 md:mr-2'
                                        }
                                        type="button"
                                        value="NYHRE"
                                        id="org"
                                        onClick={handleChange}
                                    >
                                        NYHRE
                                    </button>
                                    <button
                                        className={
                                            values.org === 'WHCP'
                                                ? 'bg-customGreen shadow-md text-white px-4 py-2 w-full md:w-6/12 md:ml-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-6/12 md:ml-2'
                                        }
                                        type="button"
                                        value="WHCP"
                                        id="org"
                                        onClick={handleChange}
                                    >
                                        WHCP
                                    </button>
                                </div>
                            </div>
                            {values.org !== '' && (
                                <>
                                    <div className="mx-4 mt-6">
                                        <label
                                            className="block mb-2 ml-1 text-sm text-gray-700"
                                            htmlFor="email"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                                            onChange={handleChange}
                                            value={values.email}
                                            id="email"
                                            type="text"
                                            placeholder=""
                                        />
                                        {errors.email && (
                                            <div className="px-4 py-2 mt-2 font-bold text-white bg-red-500 rounded-full">
                                                {errors.email}
                                            </div>
                                        )}
                                        <label
                                            className="block mt-2 mb-2 ml-1 text-sm text-gray-700"
                                            htmlFor="password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline "
                                            onChange={handleChange}
                                            value={values.password}
                                            id="password"
                                            type="password"
                                            placeholder=""
                                        />
                                        {errors.password && (
                                            <div className="px-4 py-2 mt-2 font-bold text-white bg-red-500 rounded-full">
                                                {errors.password}
                                            </div>
                                        )}
                                        {/*
                            <div className="flex items-center justify-between">
                                <label className="inline mt-4 text-gray-600">
                                    <input
                                        className="mr-2 leading-tight"
                                        type="checkbox"
                                    />
                                    <span className="text-sm">Remember Me</span>
                                </label>
                                {/* <a
                                    className="inline mt-4 text-sm text-right text-gray-600"
                                    href="#forgot?"
                                >
                                    Forgot Password?
                                </a>
                                <a
                                    className="inline mt-4 text-sm text-right text-gray-600"
                                    href="/register"
                                >
                                    Register
                                </a>
                            </div>
                            //<div className="w-full h-px my-8 bg-gray-300"></div>
                           */}
                                        <div className="flex my-2">
                                            <Link href="/forgot-password">
                                                <small>Forgot Password?</small>
                                            </Link>
                                        </div>

                                        <div className="flex my-6">
                                            <button className="w-full p-2 bg-gray-200 rounded shadow-md">
                                                Sign In
                                            </button>
                                            {/* <button className="w-full p-2 bg-gray-200 rounded shadow-md">
                                    Register
                                </button> */}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </form>
        </>
    )
}
