import React, { useState } from 'react'
import { MenuIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/outline'

export default function MobileHamburger({ showMobileNav, setShowMobileNav }) {
    return (
        <button
            onClick={() => setShowMobileNav(!showMobileNav)}
            className={
                showMobileNav
                    ? `
                flex
                justify-center
                mx-3 my-2 p-4
                shadow-xl
                transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-102
                md:hidden
                h-16 w-16
                items-center
                rounded-full bg-white`
                    : `
                flex
                justify-center
                mx-3 my-2 p-4
                shadow-xl
                transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-102
                md:hidden
                h-16 w-16
                items-center
                rounded-full bg-gray-200`
            }
        >
            {showMobileNav ? (
                <XIcon className="h-8 w-8" />
            ) : (
                <MenuIcon className="w-8 h-8" />
            )}
        </button>
    )
}
