import React from 'react'
// import Layout from '@/Layout/Layout'
import Layout from '../../../Layout/Layout'
import { usePage } from '@inertiajs/react'

export default function Checkin({ user }) {
    // console.log({ user })
    return (
        <Layout>
            {user.can.create_location && (
                <h1 className="animate-pulse">Checkin</h1>
            )}
        </Layout>
    )
}
