import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout/Layout'
import { usePage, Link } from '@inertiajs/react'
import FlashMessages from '../Shared/FlashMessages'

export default function Continue({ participants }) {
    const { user } = usePage().props
    const { flash, errors } = usePage().props
    const [clearHistory, setClearHistory] = useState(false)

    useEffect(() => {
        if (!clearHistory) {
            history.pushState(null, null, window.location.href)
            setClearHistory(true)
        }

        return () => { }
    }, [])
    return (
        <div className="bg-gray-100 h-screen w-full flex items-center justify-center">
            <FlashMessages />
            <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 container mx-auto max-w-xs md:max-w-lg">
                <h5 className="mb-4 text-2xl text-gray-700 font-semibold ">
                    Continue with {user.is.currently_seeing}
                </h5>
                <div className="flex flex-row flex-wrap justify-center divide-y-8">
                    {flash.referredPage !== 'exchange' && (
                        <Link
                            href="/exchange/show1"
                            className="p-4 bg-customBlue text-white flex-grow w-full text-center"
                        >
                            Start a new Syringe/Harm Reduction Service
                        </Link>
                    )}
                    {flash.referredPage !== 'dropin' && (
                        <Link
                            href="/dropin/create"
                            className="p-4 bg-customBlue text-white flex-grow w-full text-center"
                        >
                            Start a new Drop In / Outreach
                        </Link>
                    )}
                    {flash.referredPage !== 'naloxone' && (
                        <Link
                            href="/narcan/create"
                            className="p-4 bg-customBlue text-white flex-grow w-full text-center"
                        >
                            Start a new Naloxone/Narcan Recipient Form
                        </Link>
                    )}
                    {flash.referredPage !== 'holistic' && (
                        <Link
                            href="/holistic/create"
                            className="p-4 bg-customBlue text-white flex-grow w-full text-center"
                        >
                            Start a new Holistic Services Form
                        </Link>
                    )}
                    {flash.referredPage !== 'smokingKit' && (
                        <Link
                            href="/smokingkit/create"
                            className="p-4 bg-customBlue text-white flex-grow w-full text-center"
                        >
                            Start a new Smoking Kit Form
                        </Link>
                    )}
                    {flash.referredPage !== 'vaccineService' && (
                        <Link
                            href="/vaccineservice/create"
                            className="p-4 bg-customBlue text-white flex-grow w-full text-center"
                        >
                            Start a new Vaccine Service Form
                        </Link>
                    )}
                </div>

                <h5 className="mb-4 text-2xl text-gray-700 font-semibold mt-8">
                    Finish with {user.is.currently_seeing}
                </h5>
                <div className="flex flex-row flex-wrap justify-center">
                    <Link
                        method="POST"
                        href="/participants/drop"
                        className="p-4 bg-gray-100 flex-grow w-full hover:bg-gray-200 text-center"
                    >
                        Finish and clear current participant
                    </Link>
                </div>
            </div>
        </div>
    )
}
