import React, { useState, useEffect } from 'react'
import Layout from '../../../../Layout/Layout'
import { useForm } from '@inertiajs/react'

export default function IntakePageThree() {
    const { data, setData, post, processing, errors } = useForm({
        home_address: '',
        home_address2: '',
        state: '',
        zip_code: '',
        phone_number: '',
        ethnicity: '',
        hispdetds: '',
        race_white: false,
        race_blafrican: false,
        race_asian: false,
        asian_ethnicity_country: '',
        race_hawaisland: false,
        race_indialaska: false,
        race_dk: false,
        race_declined: false,
        race_other: false,
        otherValue: '',
    })

    function handleSubmit(e) {
        e.preventDefault()
        post('create3', data)
    }
    return (
        <>
            <Layout title="New Participant Intake">
                <form
                    onSubmit={handleSubmit}
                    className="col-span-12 md:col-span-10 flex flex-col w-auto justify-start bg-gray-100 mb-4"
                >
                    <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 mt-4 md:md-0">
                        <div className="text-gray-700 w-full mt-4">
                            <label className="block mb-2" htmlFor="">
                                Address Line 1
                            </label>
                            {errors.home_address && (
                                <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                    {errors.home_address}
                                </div>
                            )}
                            <input
                                onChange={(e) =>
                                    setData('home_address', e.target.value)
                                }
                                value={data.home_address}
                                className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                type="text"
                                placeholder=""
                                id="home_address"
                            />
                        </div>
                        <div className="flex flex-row w-full space-x-2">
                            <div className="text-gray-700 w-full mt-8">
                                <label className="block mb-2" htmlFor="">
                                    Address Line 2
                                </label>
                                {errors.home_address2 && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.home_address2}
                                    </div>
                                )}
                                <input
                                    onChange={(e) =>
                                        setData('home_address2', e.target.value)
                                    }
                                    value={data.home_address2}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                    type="text"
                                    placeholder=""
                                    id="home_address2"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row space-x-2">
                            <div className="text-gray-700 w-12/12 md:w-1/3 mt-8">
                                <label className="block mb-2" htmlFor="city">
                                    City
                                </label>
                                {errors.city && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.city}
                                    </div>
                                )}
                                <input
                                    onChange={(e) =>
                                        setData('city', e.target.value)
                                    }
                                    value={data.city}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                    type="text"
                                    placeholder=""
                                    id="city"
                                />
                            </div>
                            <div className="text-gray-700 w-1/2 md:w-1/3 mt-8">
                                <label className="block mb-2" htmlFor="state">
                                    State
                                </label>
                                {errors.state && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.state}
                                    </div>
                                )}
                                <input
                                    onChange={(e) =>
                                        setData('state', e.target.value)
                                    }
                                    value={data.state}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                    type="text"
                                    placeholder=""
                                    id="state"
                                />
                            </div>
                            <div className="text-gray-700 w-1/2 md:w-1/3 mt-8">
                                <label
                                    className="block mb-2"
                                    htmlFor="zip_code"
                                >
                                    Zip Code
                                </label>
                                {errors.zip_code && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.zip_code}
                                    </div>
                                )}
                                <input
                                    onChange={(e) =>
                                        setData('zip_code', e.target.value)
                                    }
                                    value={data.zip_code}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                    type="text"
                                    placeholder=""
                                    id="zip_code"
                                />
                            </div>
                        </div>
                        <div className="text-gray-700 w-full mt-8">
                            <label
                                className="block mb-2"
                                htmlFor="phone_number"
                            >
                                Primary Phone Number
                            </label>
                            {errors.phone_number && (
                                <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                    {errors.phone_number}
                                </div>
                            )}
                            <input
                                onChange={(e) =>
                                    setData('phone_number', e.target.value)
                                }
                                value={data.phone_number}
                                className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                type="text"
                                placeholder=""
                                id="phone_number"
                            />
                        </div>
                        <h5 className="mt-8 text-gray-700 font-semibold ">
                            Ethnicity
                        </h5>
                        {errors.ethnicity && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2 self-start">
                                {errors.ethnicity}
                            </div>
                        )}
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.ethnicity === 'Hispanic'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Hispanic"
                                value={data.ethnicity}
                                onClick={(e) =>
                                    setData('ethnicity', e.target.id)
                                }
                            >
                                Hispanic
                            </button>
                            <button
                                className={
                                    data.ethnicity === 'Non-Hispanic'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Non-Hispanic"
                                value={data.ethnicity}
                                onClick={(e) =>
                                    setData('ethnicity', e.target.id)
                                }
                            >
                                Non-hispanic
                            </button>
                        </div>

                        {data.ethnicity === 'Hispanic' && (
                            <>
                                <h5 className="mt-8 text-gray-700 font-semibold ">
                                    Hispanic Ethnicity Details
                                </h5>
                                {errors.hispdetds && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 my-2 self-start">
                                        {errors.hispdetds}
                                    </div>
                                )}
                                <div className="w-full flex flex-wrap mt-2 gap-2">
                                    <button
                                        className={
                                            data.hispdetds === 'Puerto Rican'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Puerto Rican"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        Puerto Rican
                                    </button>
                                    <button
                                        className={
                                            data.hispdetds === 'Dominican'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Dominican"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        Dominican
                                    </button>
                                    <button
                                        className={
                                            data.hispdetds === 'Cuban'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Cuban"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        Cuban
                                    </button>
                                    <button
                                        className={
                                            data.hispdetds === 'South American'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="South American"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        South American
                                    </button>
                                    <button
                                        className={
                                            data.hispdetds ===
                                                'Central American'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Central American"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        Central American
                                    </button>
                                    <button
                                        className={
                                            data.hispdetds ===
                                                'Mexican/Mexican-American/Chicano(a)'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Mexican/Mexican-American/Chicano(a)"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        Mexican/Mexican-American/Chicano(a)
                                    </button>
                                    <button
                                        className={
                                            data.hispdetds === 'Spanish'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Spanish"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        Spanish
                                    </button>
                                    <button
                                        className={
                                            data.hispdetds ===
                                                'Other Hispanic, Latino/a or Spanish'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Other Hispanic, Latino/a or Spanish"
                                        value={data.hispdetds}
                                        onClick={(e) =>
                                            setData('hispdetds', e.target.id)
                                        }
                                    >
                                        Other Hispanic
                                    </button>
                                </div>
                            </>
                        )}

                        <h5 className="mt-8 text-gray-700 font-semibold ">
                            Race (check all that apply)
                        </h5>
                        {errors.race && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 my-2 self-start">
                                {errors.race}
                            </div>
                        )}
                        {errors.at_least_one_true && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 my-2 self-start">
                                {errors.at_least_one_true}
                            </div>
                        )}
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.race_blafrican
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="race_blafrican"
                                value={data.race_blafrican}
                                onClick={(e) =>
                                    setData(
                                        'race_blafrican',
                                        !data.race_blafrican
                                    )
                                }
                            >
                                Black
                            </button>
                            <button
                                className={
                                    data.race_white
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="race_white"
                                value={data.race_white}
                                onClick={(e) =>
                                    setData('race_white', !data.race_white)
                                }
                            >
                                White
                            </button>
                            <button
                                className={
                                    data.race_asian
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="race_asian"
                                value={data.race_asian}
                                onClick={(e) =>
                                    setData('race_asian', !data.race_asian)
                                }
                            >
                                Asian
                            </button>
                            <button
                                className={
                                    data.race_hawaisland
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="race_hawaisland"
                                value={data.race_hawaisland}
                                onClick={(e) =>
                                    setData(
                                        'race_hawaisland',
                                        !data.race_hawaisland
                                    )
                                }
                            >
                                Native Hawaiian / Pacific Islander
                            </button>
                            <button
                                className={
                                    data.race_indialaska
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="race_indialaska"
                                value={data.race_indialaska}
                                onClick={(e) =>
                                    setData(
                                        'race_indialaska',
                                        !data.race_indialaska
                                    )
                                }
                            >
                                American Indian / Alaskan Native
                            </button>
                            <button
                                className={
                                    data.race_dk === true
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="1"
                                value={data.race_dk}
                                onClick={(e) =>
                                    setData('race_dk', !data.race_dk)
                                }
                            >
                                Unknown
                            </button>
                            <button
                                className={
                                    data.race_declined === true
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="declined"
                                value={data.race_declined}
                                onClick={(e) =>
                                    setData(
                                        'race_declined',
                                        !data.race_declined
                                    )
                                }
                            >
                                Declined
                            </button>
                            <button
                                className={
                                    data.race_other
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="race_other"
                                value={data.race_other}
                                onClick={(e) =>
                                    setData('race_other', !data.race_other)
                                }
                            >
                                Other
                            </button>

                            {data.race_other && (
                                <div className="w-full mt-4">
                                    {errors.otherValue && (
                                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 my-2 self-start">
                                            {errors.otherValue}
                                        </div>
                                    )}
                                    <input
                                        onChange={(e) =>
                                            setData(
                                                'otherValue',
                                                e.target.value
                                            )
                                        }
                                        value={data.otherValue}
                                        className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg  "
                                        type="text"
                                        placeholder="Other Race Value"
                                        id="otherValue"
                                    />
                                </div>
                            )}
                            {data.race_asian && (
                                <div className="w-full mt-4">
                                    {errors.asian_ethnicity_country && (
                                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 my-2 self-start">
                                            {errors.asian_ethnicity_country}
                                        </div>
                                    )}
                                    <input
                                        onChange={(e) =>
                                            setData(
                                                'asian_ethnicity_country',
                                                e.target.value
                                            )
                                        }
                                        value={data.asian_ethnicity_country}
                                        className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg  "
                                        type="text"
                                        placeholder="Asian Ethnicity/Country"
                                        id="asian_ethnicity_country"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-full flex flex-row flex-wrap bg-customBlue md:space-x-4 space-y-4 md:space-y-0 items-center py-7 px-8 md:px-16">
                        <button
                            type="submit"
                            className="bg-white text-customBlue py-2 px-8 w-full justify-center md:w-auto font-bold flex flex-row items-center"
                            disabled={processing}
                        >
                            {processing && (
                                <div>
                                    <svg
                                        className="animate-spin mr-2"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 120 120"
                                    >
                                        <g
                                            id="Artboard"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                id="Group"
                                                transform="translate(9.000000, 9.000000)"
                                                stroke="#314EB9"
                                                strokeWidth="9"
                                            >
                                                <path
                                                    d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                    id="Path"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            )}
                            Next
                        </button>
                        <div className="flex-col md:flex-row items-center w-full md:w-auto bg-white rounded-full py-2">
                            <div className="flex flex-row items-center px-4">
                                <small className="pr-2">Step 3 of 4</small>
                                <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}
