import Select from 'react-select'
import { useForm } from '@inertiajs/react'
import { useState, useEffect } from 'react'

export default function AddAlert(id) {
    const [selectValue, setSelectValue] = useState('')
    const participantID = id.id
    function handleSubmit(e) {
        e.preventDefault()
        post(route('alerts.create'), {
            data,
            preserveScroll: true,
            onSuccess: () => {
                reset('type', 'type_custom', 'message', 'participant_id')
                setSelectValue('')
            },
        })
    }

    const { data, setData, post, errors, reset } = useForm({
        type: '',
        type_custom: '',
        message: '',
        participant_id: participantID,
    })


    const options = [
        { value: '24 hour suspension', label: '24 hour suspension' },
        { value: 'Suspended WH - DIC', label: 'Suspended WH - DIC' },
        {
            value: 'Suspended WH - whole building',
            label: 'Suspended WH - whole building',
        },
        { value: 'Suspended EH - DIC', label: 'Suspended EH - DIC' },
        { value: 'Suspended WH and EH', label: 'Suspended WH and EH' },
        { value: 'Talk to case management', label: 'Talk to case management' },
        { value: 'Talk to mental health', label: 'Talk to mental health' },
        { value: 'Talk to medical', label: 'Talk to medical' },
        { value: 'Youth', label: 'Youth' },
        { value: 'May be pregnant', label: 'May be pregnant' },
        { value: 'Other', label: 'Other' },
    ]

    return (
        <>
            <h3 className="w-full mt-12 mb-2 font-semibold text-lg text-gray-700">
                Add an Alert:
            </h3>
            <div className="flex flex-col">
                <p className="mb-1">Type</p>
                <Select
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: 0,
                            height: '2.5rem',
                            borderColor: '#a2a2a2',
                        }),
                    }}
                    value={selectValue}
                    isClearable
                    blurInputOnSelect
                    options={options}
                    className="h-10 w-full text-gray-700 md:w-full"
                    placeholder="type or select"
                    onChange={(newValue) => {
                        if (newValue) {
                            setSelectValue(newValue)
                            setData('type', newValue.value)
                        } else {
                            setData('type', '')
                            setSelectValue('')
                        }
                    }}
                />
                {errors.type && (
                    <div className="mt-6 flex flex-row gap-2 items-center mb-6 rounded-sm text-sm w-auto bg-customRed self-start px-4 py-2 font-bold text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                            />
                        </svg>

                        <p>{errors.type}</p>
                    </div>
                )}
                {data.type === 'Other' ? (
                    <div className="mt-6 mb-2">
                        <p className="mb-1">Other Alert Type</p>
                        <input
                            onChange={(e) =>
                                setData('type_custom', e.target.value)
                            }
                            value={data.type_custom}
                            className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                            type="text"
                            placeholder=""
                            id="type_custom"
                        />{' '}
                        {errors.type_custom && (
                            <div className="mt-6 flex flex-row gap-2 items-center mb-6 rounded-sm text-sm w-auto bg-customRed self-start px-4 py-2 font-bold text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                    />
                                </svg>

                                <p>{errors.type_custom}</p>
                            </div>
                        )}
                    </div>
                ) : null}
                <p className="mt-6">Message (optional)</p>
                <input
                    onChange={(e) => setData('message', e.target.value)}
                    value={data.message}
                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                    type="text"
                    placeholder=""
                    id="message"
                />
                <button
                    onClick={(e) => handleSubmit(e)}
                    className="w-auto self-start h-10 px-4 mt-6 text-white bg-customBlue"
                >
                    Submit
                </button>
            </div>
        </>
    )
}
