import React from 'react'
import Layout from '../../../../Layout/Layout'
import { usePage, useForm } from '@inertiajs/react'


export default function Edit({ dropins }) {
    const { data, setData, put, processing, errors } = useForm({
        coffee_received: dropins.coffee_received || 0,
        bathroom_received: dropins.bathroom_received || 0,
        shower_received: dropins.shower_received || 0,
        laundry_received: dropins.laundry_received || 0,
        respite_received: dropins.respite_received || 0,
        clothing_received: dropins.clothing_received || 0,
        mail_pick_up_received: dropins.mail_pick_up_received || 0,
        food_received: dropins.food_received || 0,
        od_response_received: dropins.od_response_received || 0,
        od_response_naloxone: dropins.od_response_naloxone || 0,
        od_response_oxygen: dropins.od_response_oxygen || 0,
        od_response_cpr: dropins.od_response_cpr || 0,
        od_response_aed: dropins.od_response_aed || 0,
        od_response_911: dropins.od_response_911 || 0,
        od_response_overamp: dropins.od_response_overamp || 0,
        od_response_rider_monitoring: dropins.od_response_rider_monitoring || 0,
        called_911_response_ems: dropins.called_911_response_ems || 0,
        called_911_response_rma: dropins.called_911_response_rma || 0,
        called_911_response_police_attended: dropins.called_911_response_police_attended || 0,
        services_other: dropins.services_other || 0,
        services_other_details: dropins.services_other_details || '',
    })

    const { user } = usePage().props

    function handleSubmit(e) {
        e.preventDefault()
        put(route('dropin.update', dropins.id), data)
    }

    return (
        <Layout title="Drop In / Outreach Services">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 md:mb-0 mt-2">
                    <div className="flex flex-row flex-wrap items-center mb-4">
                        <h5 className="text-2xl text-gray-700 mr-2">Editing: </h5>
                        <p className="text-2xl font-semibold">
                            {dropins.participant.first_name + ' ' + dropins.participant.last_name}
                        </p>
                        <p className="text-2xl font-semibold">{user.is.currently_seeing}</p>
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-4">
                        <h5 className="text-2xl text-gray-700 mr-2">Drop In / Outreach Services Administered:</h5>
                        <div className="w-full flex flex-wrap my-4">
                            <button
                                className={
                                    data.coffee_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="coffee_received"
                                value={data.coffee_received}
                                onClick={(e) => setData('coffee_received', !data.coffee_received)}
                            >
                                Coffee
                            </button>
                            <button
                                className={
                                    data.bathroom_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="bathroom"
                                value={data.bathroom_received}
                                onClick={(e) => setData('bathroom_received', !data.bathroom_received)}
                            >
                                Bathroom
                            </button>
                            <button
                                className={
                                    data.shower_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="shower_received"
                                value={data.shower_received}
                                onClick={(e) => setData('shower_received', !data.shower_received)}
                            >
                                Shower
                            </button>
                            <button
                                className={
                                    data.laundry_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="laundry_received"
                                value={data.laundry_received}
                                onClick={(e) => setData('laundry_received', !data.laundry_received)}
                            >
                                Laundry
                            </button>
                            <button
                                className={
                                    data.respite_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="respite_received"
                                value={data.respite_received}
                                onClick={(e) => setData('respite_received', !data.respite_received)}
                            >
                                Respite
                            </button>
                            <button
                                className={
                                    data.clothing_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="clothing_received"
                                value={data.clothing_received}
                                onClick={(e) => setData('clothing_received', !data.clothing_received)}
                            >
                                Clothing
                            </button>
                            <button
                                className={
                                    data.mail_pick_up_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="mail_pick_up_received"
                                value={data.mail_pick_up_received}
                                onClick={(e) => setData('mail_pick_up_received', !data.mail_pick_up_received)}
                            >
                                Mail Pick Up
                            </button>
                            <button
                                className={
                                    data.food_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="food_received"
                                value={data.food_received}
                                onClick={(e) => setData('food_received', !data.food_received)}
                            >
                                Food
                            </button>
                            <button
                                className={
                                    data.od_response_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="od_response_received"
                                value={data.od_response_received}
                                onClick={(e) => setData('od_response_received', !data.od_response_received)}
                            >
                                OD Response
                            </button>
                            <button
                                className={
                                    data.services_other
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="services_other"
                                value={data.services_other}
                                onClick={(e) => setData('services_other', !data.services_other)}
                            >
                                Other
                            </button>
                            {data.services_other ? (
                                <div className="w-full mt-4 flex flex-row flex-wrap md:flex-nowrap gap-4 md:gap-0 mb-4">
                                    <div className="text-gray-700 w-full">
                                        {errors.services_other_details && (
                                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                                {errors.services_other_details}
                                            </div>
                                        )}
                                        <label className="block mb-2">Other Services Details:</label>
                                        <input
                                            className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                            type="text"
                                            onChange={(e) => setData('services_other_details', e.target.value)}
                                            value={data.services_other_details}
                                            id="services_other_details"
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {data.od_response_received && (
                            <>
                                <h5 className="mt-4 text-2xl text-gray-700 mr-2">OD Response Details</h5>
                                <div className="w-full flex flex-wrap mt-2">
                                    <button
                                        className={
                                            data.od_response_naloxone
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="administered naloxone"
                                        value={data.od_response_naloxone}
                                        onClick={(e) => setData('od_response_naloxone', !data.od_response_naloxone)}
                                    >
                                        Administered Naloxone
                                    </button>
                                    <button
                                        className={
                                            data.od_response_oxygen
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="od_response_oxygen"
                                        value={data.od_response_oxygen}
                                        onClick={(e) => setData('od_response_oxygen', !data.od_response_oxygen)}
                                    >
                                        Administered Oxygen
                                    </button>
                                    <button
                                        className={
                                            data.od_response_cpr
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="od_response_cpr"
                                        value={data.od_response_cpr}
                                        onClick={(e) => setData('od_response_cpr', !data.od_response_cpr)}
                                    >
                                        Administered CPR
                                    </button>
                                    <button
                                        className={
                                            data.od_response_aed
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="od_response_aed"
                                        value={data.od_response_aed}
                                        onClick={(e) => setData('od_response_aed', !data.od_response_aed)}
                                    >
                                        Used AED
                                    </button>
                                    <button
                                        className={
                                            data.od_response_911
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="od_response_911"
                                        value={data.od_response_911}
                                        onClick={(e) => setData('od_response_911', !data.od_response_911)}
                                    >
                                        Called 911
                                    </button>
                                    <button
                                        className={
                                            data.od_response_overamp
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="od_response_overamp"
                                        value={data.od_response_overamp}
                                        onClick={(e) => setData('od_response_overamp', !data.od_response_overamp)}
                                    >
                                        OverAmp Response
                                    </button>
                                    <button
                                        className={
                                            data.od_response_rider_monitoring
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="od_response_overamp"
                                        value={data.od_response_rider_monitoring}
                                        onClick={(e) =>
                                            setData('od_response_rider_monitoring', !data.od_response_rider_monitoring)
                                        }
                                    >
                                        Rider Monitoring
                                    </button>
                                </div>
                            </>
                        )}
                        {data.od_response_911 && (
                            <>
                                <h5 className="mt-4 text-2xl text-gray-700 mr-2">911 Response Details</h5>
                                <div className="w-full flex flex-wrap mt-2">
                                    <button
                                        className={
                                            data.called_911_response_ems
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="called_911_response_ems"
                                        value={data.called_911_response_ems}
                                        onClick={(e) =>
                                            setData('called_911_response_ems', !data.called_911_response_ems)
                                        }
                                    >
                                        Participant went with EMS
                                    </button>
                                    <button
                                        className={
                                            data.called_911_response_rma
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="called_911_response_rma"
                                        value={data.called_911_response_rma}
                                        onClick={(e) =>
                                            setData('called_911_response_rma', !data.called_911_response_rma)
                                        }
                                    >
                                        Participant Refused Medical Attention (RMA)
                                    </button>
                                    <button
                                        className={
                                            data.called_911_response_police_attended
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                        }
                                        type="button"
                                        id="called_911_response_police_attended"
                                        value={data.called_911_response_police_attended}
                                        onClick={(e) =>
                                            setData(
                                                'called_911_response_police_attended',
                                                !data.called_911_response_police_attended
                                            )
                                        }
                                    >
                                        Police Attended
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="w-full flex flex-row flex-wrap bg-customBlue md:space-x-4 space-y-4 md:space-y-0 items-center py-7 px-8 md:px-16 mb-8">
                    <button
                        type="submit"
                        className="bg-white text-customBlue h-12 py-2 px-8 w-full justify-center md:w-auto font-bold flex flex-row items-center"
                        disabled={processing}
                    >
                        {processing && (
                            <div>
                                <svg className="animate-spin mr-2" width="18px" height="18px" viewBox="0 0 120 120">
                                    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                                        <g
                                            id="Group"
                                            transform="translate(9.000000, 9.000000)"
                                            stroke="#314EB9"
                                            strokeWidth="9"
                                        >
                                            <path
                                                d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                id="Path"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        )}
                        Done
                    </button>
                    <div className="flex-col md:flex-row items-center w-auto md:w-auto bg-white rounded-full py-2">
                        <div className="flex flex-row items-center px-4">
                            <small className="pr-2">Step 1 of 1</small>
                            <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    )
}
