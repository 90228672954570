import React from 'react'
import { Link } from '@inertiajs/react'
// import Icon from '@/Layout/Components/IconHandler'
import Icon from '../Components/IconHandler'

export default function NavItem({
    name,
    link,
    icon,
    iconColor,
    iconStroke,
    activeColor,
    isActive,
}) {
    return (
        <>
            <Link href={link}>
                <div
                    className={
                        isActive
                            ? `flex justify-start gap-2 mx-3 md:ml-3 my-4 w-auto md:w-28 lg:w-40 p-2 text-white rounded-sm transform transition-colors' + ${activeColor}`
                            : `flex justify-start gap-2 mx-3 md:ml-3 my-4 w-auto md:w-28 lg:w-40 p-2 bg-white md:bg-gray-100 text-gray-600 hover:bg-gray-100 md:hover:bg-white rounded-sm transform transition-colors`
                    }
                >
                    <div className="block md:hidden lg:block h-6 w-6">
                        <Icon
                            icon={icon}
                            fill={iconColor}
                            stroke={iconStroke}
                        />
                    </div>
                    {name}
                </div>
            </Link>
        </>
    )
}
