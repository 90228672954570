import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../../Layout/Layout'
import { usePage, useForm } from '@inertiajs/react'
import Select from 'react-select'

export default function ExchangePageOne({ referral_options, exchange }) {
    const dbValues = exchange.referral_code.reduce(({ label, value }) => [
        {
            label: label,
            value: value,
        },
    ])
    const { user } = usePage().props
    const { data, setData, put, processing, errors } = useForm({
        works_in: exchange.works_in,
        works_out: exchange.works_out,
        referral_code: dbValues || [],
        options1: dbValues || [],
        referral_code_other: exchange.referral_code_other || '',
        referred_to: exchange.referred_to || '',
        safer_sex_supplies: exchange.safer_sex_supplies || false,
        sniffing_kits: exchange.sniffing_kits || false,
        hygiene_supplies: exchange.hygiene_supplies || false,
        wound_care_supplies: exchange.wound_care_supplies || false,
        all_weather_supplies: exchange.all_weather_supplies || false,
        haz_waste_supplies: exchange.haz_waste_supplies || false,
        socks_clothes: exchange.socks_clothes || false,
        taper_withdrawal_kits: exchange.taper_withdrawal_kits || false,
        hormone_steroid_kits: exchange.hormone_steroid_kits || false,
        drug_testing_strips: exchange.drug_testing_strips || false,
        fentanyl_test_strips_number: exchange.fentanyl_test_strips_number,
        xylazine_test_strips_number: exchange.xylazine_test_strips_number,
        benzodiazepine_test_strips_number: exchange.benzodiazepine_test_strips_number,
        cocaine_test_strips_number: exchange.cocaine_test_strips_number,
        ketamine_test_strips_number: exchange.ketamine_test_strips_number,
        methamphetamine_test_strips_number: exchange.methamphetamine_test_strips_number,
        morphine_test_strips_number: exchange.morphine_test_strips_number,
        other_test_strips_type: exchange.other_test_strips_type || '',
        other_test_strips_number: exchange.other_test_strips_number,
        supply_other: exchange.supply_other || false,
        supply_other_details: exchange.supply_other_details || '',
        bupe_edu_received: exchange.bupe_edu_received || false,
        safer_injection_counseling_received: exchange.safer_injection_counseling_received || false,
        safer_disposal_counseling_received: exchange.safer_disposal_counseling_received || false,
        sexual_rr_condom_use_received: exchange.sexual_rr_condom_use_received || false,
        od_prevention_edu_received: exchange.od_prevention_edu_received || false,
        info_on_prep_received: exchange.info_on_prep_received || false,
        tx_options_counseling_received: exchange.tx_options_counseling_received || false,
        covid_19_edu_received: exchange.covid_19_edu_received || false,
        covid_19_counseling_received: exchange.covid_19_counseling_received || false,
        wound_care_received: exchange.wound_care_received || false,
        used_moum_materials: exchange.used_moum_materials || false,
        services_other: exchange.services_other || false,
        services_other_details: exchange.services_other_details || '',
        services_description: exchange.services_description || '',
    })
    const [defaultCodes, setDefaultCodes] = useState()
    const [options, setOptions] = useState()
    const ref = useRef(1)
    // useEffect(() => {
    //     setData({
    //         ...data,
    //         referral_code: exchange.referral_code.reduce(({ label, value }) => [
    //             {
    //                 label: label,
    //                 value: value,
    //             },
    //         ]),
    //     })
    // }, [ref])
    useEffect(() => {
        setOptions(
            referral_options.reduce(({ label, value }) => [
                {
                    label: label,
                    value: value,
                },
            ])
        )
    }, [ref])
    function setSelectedReferrals(opt) {
        setData({
            ...data,
            referral_code: opt,
        })
    }

    const [showDropdown, setShowDropdown] = useState(false)
    const onClick = () => setShowDropdown(!showDropdown)
    function handleButtons(e) {
        let buttonValues = data.services_received
        if (buttonValues.includes(e.target.id)) {
            for (var i = 0; i < buttonValues.length; i++) {
                if (buttonValues[i] === e.target.id) {
                    buttonValues.splice(i, 1)
                }
            }
        } else {
            buttonValues.push(e.target.id)
        }
        setData({
            ...data,
            services_received: buttonValues,
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        put(route('exchange.update', exchange.id), data)
    }

    return (
        <>
            <Layout title="Syringe/Harm Reduction Form">
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16  md:mb-0">
                        <div className="flex flex-row flex-wrap items-center mb-4">
                            <h5 className="text-2xl text-gray-700 mr-2">Editing Syringe/Harm Reduction Service for:</h5>
                            <p className="text-2xl font-semibold">
                                {exchange.participant.first_name + ' ' + exchange.participant.last_name}
                            </p>
                        </div>
                        <div className="flex flex-row flex-wrap mb-4">
                            <div className="text-gray-700 w-full md:w-6/12 md:pr-2">
                                {errors.works_in && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.works_in}
                                    </div>
                                )}
                                <label className="block mb-2">Works In</label>
                                <input
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('works_in', e.target.value)}
                                    value={data.works_in}
                                    id="works_in"
                                />
                            </div>
                            <div className="text-gray-700 w-full md:w-6/12">
                                {errors.works_out && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.works_out}
                                    </div>
                                )}
                                <label className="block mb-2">Works Out</label>
                                <input
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('works_out', e.target.value)}
                                    value={data.works_out}
                                    id="works_out"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap mb-4">
                            <div className="text-gray-700 w-full md:w-6/12 md:pr-2">
                                {errors.referral_code && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.referral_code}
                                    </div>
                                )}
                                <label className="block mb-2">Referral Codes</label>
                                <Select
                                    isMulti
                                    defaultValue={data.options1}
                                    options={options}
                                    className="text-gray-700 w-full md:w-6/12 md:pr-2"
                                    placeholder="type or select"
                                    onChange={
                                        (opt) => setSelectedReferrals(opt)
                                    }
                                />
                            </div>
                            <div className="flex flex-row flex-wrap md:flex-nowrap gap-4 md:gap-0 mt-4 w-full">
                                <div className="text-gray-700 w-full">
                                    {errors.referral_code_other && (
                                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                            {errors.referral_code_other}
                                        </div>
                                    )}
                                    <label className="block mb-2">Referral Codes (Other)</label>
                                    <input
                                        className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                        type="text"
                                        onChange={(e) => setData('referral_code_other', e.target.value)}
                                        value={data.referral_code_other}
                                        id="referral_code_other"
                                    />
                                </div>
                            </div>
                            {/* <SearchableDropdown
                                showDropdown={showDropdown}
                                setShowDropdown={setShowDropdown}
                                id="referral code"
                                placeholder="Click or Type to see options"
                                label="Referral Code"
                            ></SearchableDropdown> */}
                        </div>
                        <div className="flex flex-row flex-wrap mb-4">
                            <div className="text-gray-700 w-full">
                                {errors.referred_to && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.referred_to}
                                    </div>
                                )}
                                <label className="block mb-2">Referred To</label>
                                <input
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('referred_to', e.target.value)}
                                    value={data.referred_to}
                                    id="referred_to"
                                />
                            </div>
                        </div>
                        <h5 className="mt-4 text-gray-700 font-semibold mb-4">Supply Distribution</h5>
                        <div className="w-full flex flex-wrap mb-4">
                            <button
                                className={
                                    data.safer_sex_supplies
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="safer_sex_supplies"
                                value={data.safer_sex_supplies}
                                onClick={(e) => setData('safer_sex_supplies', !data.safer_sex_supplies)}
                            >
                                Safer Sex Supplies
                            </button>
                            <button
                                className={
                                    data.sniffing_kits
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="sniffing_kits"
                                value={data.sniffing_kits}
                                onClick={(e) => setData('sniffing_kits', !data.sniffing_kits)}
                            >
                                Sniffing Kits
                            </button>
                            <button
                                className={
                                    data.hygiene_supplies
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="hygiene_supplies"
                                value={data.hygiene_supplies}
                                onClick={(e) => setData('hygiene_supplies', !data.hygiene_supplies)}
                            >
                                Hygiene Supplies
                            </button>
                            <button
                                className={
                                    data.wound_care_supplies
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="wound_care_supplies"
                                value={data.wound_care_supplies}
                                onClick={(e) => setData('wound_care_supplies', !data.wound_care_supplies)}
                            >
                                Wound Care Supplies
                            </button>
                            <button
                                className={
                                    data.all_weather_supplies
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="all_weather_supplies"
                                value={data.all_weather_supplies}
                                onClick={(e) => setData('all_weather_supplies', !data.all_weather_supplies)}
                            >
                                All-Weather Supplies
                            </button>
                            <button
                                className={
                                    data.haz_waste_supplies
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="haz_waste_supplies"
                                value={data.haz_waste_supplies}
                                onClick={(e) => setData('haz_waste_supplies', !data.haz_waste_supplies)}
                            >
                                Haz Waste Supplies
                            </button>
                            <button
                                className={
                                    data.socks_clothes
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="socks_clothes"
                                value={data.socks_clothes}
                                onClick={(e) => setData('socks_clothes', !data.socks_clothes)}
                            >
                                Socks/Clothes
                            </button>
                            <button
                                className={
                                    data.taper_withdrawal_kits
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="taper_withdrawal_kits"
                                value={data.taper_withdrawal_kits}
                                onClick={(e) => setData('taper_withdrawal_kits', !data.taper_withdrawal_kits)}
                            >
                                Taper/Withdrawal Kits
                            </button>
                            <button
                                className={
                                    data.hormone_steroid_kits
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="hormone_steroid_kits"
                                value={data.hormone_steroid_kits}
                                onClick={(e) => setData('hormone_steroid_kits', !data.hormone_steroid_kits)}
                            >
                                Hormone/Steroid Kits
                            </button>
                            <button
                                className={
                                    data.drug_testing_strips
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="drug_testing_strips"
                                value={data.drug_testing_strips}
                                onClick={(e) => setData('drug_testing_strips', !data.drug_testing_strips)}
                            >
                                Drug Testing Strips
                            </button>
                            <button
                                className={
                                    data.supply_other
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="supply_other"
                                value={data.supply_other}
                                onClick={(e) => setData('supply_other', !data.supply_other)}
                            >
                                Other
                            </button>
                            {data.supply_other && (
                                <div className=" w-full  mt-4 flex flex-row flex-wrap md:flex-nowrap gap-4 md:gap-0 mb-4">
                                    <div className="text-gray-700 w-full">
                                        {errors.supply_other_details && (
                                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                                {errors.supply_other_details}
                                            </div>
                                        )}
                                        <label className="block mb-2">Other Supplies Details:</label>
                                        <input
                                            className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                            type="text"
                                            onChange={(e) => setData('supply_other_details', e.target.value)}
                                            value={data.supply_other_details}
                                            id="supply_other_details"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* Drug Testing Kit Numbers */}
                        {data.drug_testing_strips && (
                            <div>
                                <h2 className="mb-4 mt-8 text-lg font-bold">Drug Testing Strip Details</h2>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4  md:gap-0">
                                    <div className="w-full md:flex md:flex-col text-gray-700">
                                        <label className="mb-2 block md:flex-1 md:whitespace-nowrap">
                                            Number of Fentanyl Test Strips:
                                        </label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('fentanyl_test_strips_number', e.target.value)}
                                            value={data.fentanyl_test_strips_number}
                                            id="fentanyl_test_strips_number"
                                        />
                                    </div>
                                    {errors.fentanyl_test_strips_number && (
                                        <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                            {errors.fentanyl_test_strips_number}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.xylazine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.xylazine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Xylazine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('xylazine_test_strips_number', e.target.value)}
                                            value={data.xylazine_test_strips_number}
                                            id="xylazine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.benzodiazepine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.benzodiazepine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Benzodiazepine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) =>
                                                setData('benzodiazepine_test_strips_number', e.target.value)
                                            }
                                            value={data.benzodiazepine_test_strips_number}
                                            id="benzodiazepine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.cocaine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.cocaine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Cocaine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('cocaine_test_strips_number', e.target.value)}
                                            value={data.cocaine_test_strips_number}
                                            id="cocaine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.ketamine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.ketamine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Ketamine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('ketamine_test_strips_number', e.target.value)}
                                            value={data.ketamine_test_strips_number}
                                            id="ketamine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.methamphetamine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.methamphetamine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Methamphetamine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) =>
                                                setData('methamphetamine_test_strips_number', e.target.value)
                                            }
                                            value={data.methamphetamine_test_strips_number}
                                            id="methamphetamine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.morphine_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.morphine_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Morphine Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('morphine_test_strips_number', e.target.value)}
                                            value={data.morphine_test_strips_number}
                                            id="morphine_test_strips_number"
                                        />
                                    </div>
                                </div>
                                <h2 className="mb-4 mt-8 text-md font-semibold">Other Testing Strip Details</h2>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.other_test_strips_type && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.other_test_strips_type}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Type of Other Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="test"
                                            onChange={(e) => setData('other_test_strips_type', e.target.value)}
                                            value={data.other_test_strips_type}
                                            id="other_test_strips_type"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:flex-nowrap md:gap-0">
                                    <div className="w-full text-gray-700">
                                        {errors.other_test_strips_number && (
                                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                                {errors.other_test_strips_number}
                                            </div>
                                        )}
                                        <label className="mb-2 block">Number of Other Test Strips:</label>
                                        <input
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="number"
                                            inputmode="numeric"
                                            onChange={(e) => setData('other_test_strips_number', e.target.value)}
                                            value={data.other_test_strips_number}
                                            id="other_test_strips_number"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <h5 className="mt-4 text-gray-700 font-semibold ">Services Received</h5>
                        {errors.services_received && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                {errors.services_received}
                            </div>
                        )}
                        <div className="w-full flex flex-wrap mb-4">
                            <button
                                className={
                                    data.bupe_edu_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="bupe_edu_received"
                                value={data.bupe_edu_received}
                                onClick={(e) => setData('bupe_edu_received', !data.bupe_edu_received)}
                            >
                                Bupe Edu
                            </button>
                            <button
                                className={
                                    data.safer_injection_counseling_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="safer_injection_counseling_received"
                                value={data.safer_injection_counseling_received}
                                onClick={(e) =>
                                    setData(
                                        'safer_injection_counseling_received',
                                        !data.safer_injection_counseling_received
                                    )
                                }
                            >
                                Safer Injection Counseling
                            </button>
                            <button
                                className={
                                    data.safer_disposal_counseling_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="safer_disposal_counseling_received"
                                value={data.safer_disposal_counseling_received}
                                onClick={(e) =>
                                    setData(
                                        'safer_disposal_counseling_received',
                                        !data.safer_disposal_counseling_received
                                    )
                                }
                            >
                                Safer Disposal Counseling
                            </button>
                            <button
                                className={
                                    data.sexual_rr_condom_use_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="sexual_rr_condom_use_received"
                                value={data.sexual_rr_condom_use_received}
                                onClick={(e) =>
                                    setData('sexual_rr_condom_use_received', !data.sexual_rr_condom_use_received)
                                }
                            >
                                Sexual RR / Condom Use
                            </button>
                            <button
                                className={
                                    data.od_prevention_edu_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="od_prevention_edu_received"
                                value={data.od_prevention_edu_received}
                                onClick={(e) => setData('od_prevention_edu_received', !data.od_prevention_edu_received)}
                            >
                                OD Prevention Edu
                            </button>
                            <button
                                className={
                                    data.info_on_prep_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="info_on_prep_received"
                                value={data.info_on_prep_received}
                                onClick={(e) => setData('info_on_prep_received', !data.info_on_prep_received)}
                            >
                                Info on PrEP
                            </button>
                            <button
                                className={
                                    data.tx_options_counseling_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="tx_options_counseling_received"
                                value={data.tx_options_counseling_received}
                                onClick={(e) =>
                                    setData('tx_options_counseling_received', !data.tx_options_counseling_received)
                                }
                            >
                                Tx Options Counseling
                            </button>
                            <button
                                className={
                                    data.covid_19_edu_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="covid_19_edu_received"
                                value={data.covid_19_edu_received}
                                onClick={(e) => setData('covid_19_edu_received', !data.covid_19_edu_received)}
                            >
                                COVID-19 Edu
                            </button>
                            <button
                                className={
                                    data.covid_19_counseling_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="covid_19_counseling_received"
                                value={data.covid_19_counseling_received}
                                onClick={(e) =>
                                    setData('covid_19_counseling_received', !data.covid_19_counseling_received)
                                }
                            >
                                COVID-19 Counseling
                            </button>
                            <button
                                className={
                                    data.wound_care_received
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="wound_care_received"
                                value={data.wound_care_received}
                                onClick={(e) => setData('wound_care_received', !data.wound_care_received)}
                            >
                                Wound Care Received
                            </button>
                            <button
                                className={
                                    data.used_moum_materials
                                        ? 'bg-customBlue m-2 w-full px-4 py-2 text-white md:w-1/4'
                                        : 'm-2 w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="used_moum_materials"
                                value={data.used_moum_materials}
                                onClick={(e) => setData('used_moum_materials', !data.used_moum_materials)}
                            >
                                Used MOUM Materials
                            </button>
                            <button
                                className={
                                    data.services_other
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="services_other"
                                value={data.services_other}
                                onClick={(e) => setData('services_other', !data.services_other)}
                            >
                                Other
                            </button>
                            {data.services_other && (
                                <div className="w-full mt-4 flex flex-row flex-wrap md:flex-nowrap gap-4 md:gap-0 mb-4">
                                    <div className="text-gray-700 w-full">
                                        {errors.services_other_details && (
                                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                                {errors.services_other_details}
                                            </div>
                                        )}
                                        <label className="block mb-2">Other Services Details:</label>
                                        <input
                                            className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                            type="text"
                                            onChange={(e) => setData('services_other_details', e.target.value)}
                                            value={data.services_other_details}
                                            id="services_other_details"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-row flex-wrap mb-4">
                            <div className="text-gray-700 w-full">
                                {errors.services_description && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.services_description}
                                    </div>
                                )}
                                <label className="block mb-2">Describe Edu / Counseling</label>
                                <input
                                    className="w-full h-10 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    onChange={(e) => setData('services_description', e.target.value)}
                                    value={data.services_description}
                                    id="works_in"
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row flex-wrap justify-end items-center mt-0 md:mt-4 my-4 md:mb-16">
                            {/* <label className="mr-4" htmlFor="intakeProgress">
                                Step 1 of 4
                            </label> */}
                            {/* <progress
                                className="mr-16 bg-gray-200"
                                id="intakeProgress"
                                value="25"
                                max="100"
                            ></progress> */}
                            <button type="submit" className="bg-customGreen text-white py-2 px-4" disabled={processing}>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}
