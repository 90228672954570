import React, { useState } from 'react'
import { usePage } from '@inertiajs/react'
// import MinimizeButton from '@/Layout/Components/MinimizeButton'
import MinimizeButton from '../Components/MinimizeButton'
// import MobileHamburger from '@/Layout/Components/MobileHamburger'
import MobileHamburger from '../Components/MobileHamburger'
// import NavItem from '@/Layout/Components/NavItem'
import NavItem from '../Components/NavItem'
import NavDropdownItem from '../Components/NavDropdownItem'
// import NavPopOut from '@/Layout/Components/NavPopOut'
import NavPopOut from '../Components/NavPopOut'

export default function MobileAdminNav({ showMobileNav, setShowMobileNav }) {
    const { user } = usePage().props
    const [dropdownShow, setDropdownShow] = useState(false)
    const isActive = route().current()

    return (
        <div
            className={
                showMobileNav
                    ? 'absolute top-0 left-0 w-screen h-screen overflow-y-none'
                    : 'bg-none'
            }
        >
            <div className="fixed bottom-2 right-2">
                <div
                    className={
                        showMobileNav === true
                            ? 'w-64 flex flex-col justify-start bg-white shadow-lg rounded-md'
                            : 'hidden'
                    }
                >
                    <div className="z-50">
                        <NavItem
                            name="Users"
                            link="/settings/users"
                            icon="User"
                            iconColor={
                                isActive === 'users.index'
                                    ? '#FFFFFF'
                                    : 'rgba(251, 212, 24, 1)'
                            }
                            shadowColor="shadow-yellow"
                            activeColor="bg-customYellow"
                            isActive={isActive === 'users.index' ? true : false}
                        />

                        <NavItem
                            name="Locations"
                            link="/settings/locations"
                            icon="OfficeBuildingIcon"
                            iconColor={
                                isActive === 'locations.index'
                                    ? '#FFFFFF'
                                    : 'rgba(90, 164, 85, 1)'
                            }
                            shadowColor="shadow-green"
                            activeColor="bg-customGreen"
                            isActive={
                                isActive === 'locations.index' ? true : false
                            }
                        />
                        <NavItem
                            name="Import/Export"
                            link="/settings/import"
                            icon="SwitchVerticalIcon"
                            iconColor={
                                isActive === 'import'
                                    ? '#FFFFFF'
                                    : 'rgba(100, 116, 175, 1)'
                            }
                            shadowColor="shadow-green"
                            activeColor="bg-customBlue"
                            isActive={isActive === 'import' ? true : false}
                        />

                        <NavItem
                            name="Back"
                            link="/dashboard"
                            icon="ArrowLeftIcon"
                            iconColor="rgba(128, 128, 128, 1)"
                            shadowColor="shadow-gray"
                        />
                        {/*
                    <MinimizeButton
                        name="Minimize"
                        icon="ArrowLeft"
                        iconColor="gray"
                        shadowColor="gray"
                    />
                    */}
                    </div>
                </div>
                <div className="flex justify-end">
                    <MobileHamburger
                        showMobileNav={showMobileNav}
                        setShowMobileNav={setShowMobileNav}
                        icon="Menu"
                        iconColor="gray"
                    />
                </div>
            </div>
        </div>
    )
}
