import moment from 'moment'

export default function AlertHistoryRow({ alerts }) {
    return (
        <div className="flex flex-col gap-4 mt-2 mb-8">
            {alerts.map((alert) => (
                <div
                    className="border border-gray-100 flex flex-row rounded-md w-full"
                    key={alert.id}
                >
                    {alert.is_active ? (
                        <div className="flex-0 bg-customRed text-white flex items-center px-2 rounded-tl-md rounded-bl-md">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                                />
                            </svg>
                        </div>
                    ) : (
                        <div className="flex-0 bg-gray-100 text-gray-700 flex items-center px-2 rounded-tl-md rounded-bl-md">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                            </svg>
                        </div>
                    )}
                    <div className="flex flex-row py-4 gap-4 justify-between items-center w-full px-4">
                        <div className="flex flex-col">
                            {alert.type === 'Other' ? (
                                <p className="text-xl">{alert.type_custom}</p>
                            ) : (
                                <p className="text-xl">{alert.type}</p>
                            )}
                            <p>{alert.message}</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-sm text-gray-600">
                                Added:{' '}
                                {moment(alert.created_at).format(
                                    'MMMM Do YYYY, h:mm A'
                                )}{' '}
                                by {alert.user.name}
                            </p>
                            {alert.cleared_by ? (
                                <p className="text-sm text-gray-600">
                                    Cleared:{' '}
                                    {moment(alert.created_at).format(
                                        'MMMM Do YYYY, h:mm A'
                                    )}{' '}
                                    by {alert.cleared_by.name}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
