import React, { useState, useEffect } from 'react'
import Layout from '../../../../Layout/Layout'
import { useForm } from '@inertiajs/react'

export default function IntakePageTwo() {
    const { data, setData, post, processing, errors } = useForm({
        sexual_orientation: '',
        sexual_orientation_other: '',
        gender_identity: '',
        gender_identity_other: '',
        assigned_sex: '',
        pronouns: '',
        pronouns_other: '',
    })
    function handleSubmit(e) {
        e.preventDefault()
        post('create2', data)
    }

    useEffect(() => {
        if (data.pronouns !== 'Other') {
            setData('pronouns_other', '')
        }
    }, [data.pronouns])

    return (
        <>
            <Layout title="New Participant Intake Form">
                <form
                    className="col-span-12 mb-4 flex w-auto flex-col justify-start bg-gray-100 md:col-span-10"
                    onSubmit={handleSubmit}
                >
                    <div className="md:md-0 mt-4 flex w-full flex-col flex-wrap bg-white p-8 shadow-md md:p-16">
                        <h5 className="mt-4 font-semibold text-gray-700 ">
                            Sexual Orientation
                        </h5>
                        {errors.sexual_orientation && (
                            <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                {errors.sexual_orientation}
                            </div>
                        )}

                        <div className="mt-2 flex w-full flex-wrap gap-2">
                            <button
                                className={
                                    data.sexual_orientation ===
                                        'Straight or Heterosexual'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Straight or Heterosexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Straight / Heterosexual
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Gay or Lesbian'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Gay or Lesbian"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Gay / Lesbian
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Bisexual'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Bisexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Bisexual
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Queer'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Queer"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Queer
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Pansexual'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Pansexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Pansexual
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Asexual'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Asexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Asexual
                            </button>
                            <button
                                className={
                                    data.sexual_orientation ===
                                        'Not Sure/Questioning'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Not Sure/Questioning"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Questioning / Not Sure
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Other'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Other"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Other
                            </button>
                        </div>
                        {data.sexual_orientation === 'Other' && (
                            <div className="flex w-full flex-col md:w-4/12">
                                <label
                                    className="mb-2 mt-4 block"
                                    htmlFor="sexual_orientation_other"
                                >
                                    Other Sexual Orientation:
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData(
                                            'sexual_orientation_other',
                                            e.target.value
                                        )
                                    }
                                    value={data.sexual_orientation_other}
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    id="sexual_orientation_other"
                                />
                                {errors.sexual_orientation_other && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 text-sm font-bold text-white">
                                        {errors.sexual_orientation_other}
                                    </div>
                                )}
                            </div>
                        )}
                        <h5 className="mt-8 font-semibold text-gray-700 ">
                            Current Gender Identity
                        </h5>
                        {errors.gender_identity && (
                            <div className="rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                {errors.gender_identity}
                            </div>
                        )}

                        <div className="mt-2 flex w-full flex-wrap gap-2">
                            <button
                                className={
                                    data.gender_identity === 'Woman/Girl'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Woman/Girl"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Woman or Girl
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Transgender Woman/Girl'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Transgender Woman/Girl"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Transgender Woman or girl
                            </button>
                            <button
                                className={
                                    data.gender_identity === 'Man/Boy'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Man/Boy"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Man or Boy
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Transgender Man/Boy'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Transgender Man/Boy"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Transgender Man or Boy
                            </button>
                            <button
                                className={
                                    data.gender_identity === 'Non-Binary person'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Non-Binary person"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Non-Binary Person
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Gender Non-Conforming person'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Gender Non-Conforming person"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Gender nonconforming person
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Not Sure/Questioning'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Not Sure/Questioning"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Questioning / Not Sure
                            </button>
                            <button
                                className={
                                    data.gender_identity === 'Other'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Other"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Other
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Chose not to respond'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Chose not to respond"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Chose not to respond
                            </button>
                        </div>
                        {data.gender_identity === 'Other' && (
                            <div className="flex w-full flex-col md:w-4/12">
                                <label
                                    className="mb-2 mt-4 block"
                                    htmlFor="gender_identity_other"
                                >
                                    Other Gender Identity:
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData(
                                            'gender_identity_other',
                                            e.target.value
                                        )
                                    }
                                    value={data.gender_identity_other}
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    id="gender_identity_other"
                                />
                                {errors.gender_identity_other && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 text-sm font-bold text-white">
                                        {errors.gender_identity_other}
                                    </div>
                                )}
                            </div>
                        )}
                        <h5 className="mt-8 font-semibold text-gray-700">
                            Sex assigned at birth
                        </h5>
                        {errors.assigned_sex && (
                            <div className="rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                {errors.assigned_sex}
                            </div>
                        )}

                        <div className="mt-2 flex w-full flex-wrap gap-2">
                            <button
                                className={
                                    data.assigned_sex === 'Male'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Male"
                                value={data.assigned_sex}
                                onClick={(e) =>
                                    setData('assigned_sex', e.target.id)
                                }
                            >
                                Male
                            </button>
                            <button
                                className={
                                    data.assigned_sex === 'Female'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Female"
                                value={data.assigned_sex}
                                onClick={(e) =>
                                    setData('assigned_sex', e.target.id)
                                }
                            >
                                Female
                            </button>
                            <button
                                className={
                                    data.assigned_sex === 'Intersex'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Intersex"
                                value={data.assigned_sex}
                                onClick={(e) =>
                                    setData('assigned_sex', e.target.id)
                                }
                            >
                                Intersex
                            </button>
                        </div>
                        <h5 className="mt-8 font-semibold text-gray-700">
                            Pronouns
                        </h5>
                        <div className="flex w-full flex-wrap gap-2">
                            <div className="mt-2 text-gray-700 w-full">
                                <label
                                    className="mb-2 block hidden"
                                    htmlFor="pronouns"
                                >
                                    Pronouns
                                </label>
                                {errors.pronouns && (
                                    <div className="mt-2 rounded-full bg-red-500 px-4 py-2 font-bold text-white">
                                        {errors.pronouns}
                                    </div>
                                )}
                                <div className="mt-2 flex w-full flex-wrap gap-2">
                                    <button
                                        className={
                                            data.pronouns === 'She/Her'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                        }
                                        type="button"
                                        id="She/Her"
                                        value={data.pronouns}
                                        onClick={(e) =>
                                            setData('pronouns', e.target.id)
                                        }
                                    >
                                        She/Her
                                    </button>
                                    <button
                                        className={
                                            data.pronouns === 'He/Him'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                        }
                                        type="button"
                                        id="He/Him"
                                        value={data.pronouns}
                                        onClick={(e) =>
                                            setData('pronouns', e.target.id)
                                        }
                                    >
                                        He/Him
                                    </button>
                                    <button
                                        className={
                                            data.pronouns === 'They/Them'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                        }
                                        type="button"
                                        id="They/Them"
                                        value={data.pronouns}
                                        onClick={(e) =>
                                            setData('pronouns', e.target.id)
                                        }
                                    >
                                        They/Them
                                    </button>
                                    <button
                                        className={
                                            data.pronouns === 'Ze/Zim'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                        }
                                        type="button"
                                        id="Ze/Zim"
                                        value={data.pronouns}
                                        onClick={(e) =>
                                            setData('pronouns', e.target.id)
                                        }
                                    >
                                        Ze/Zim
                                    </button>
                                    <button
                                        className={
                                            data.pronouns ===
                                                'No Pronouns/Use my name'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                        }
                                        type="button"
                                        id="No Pronouns/Use my name"
                                        value={data.pronouns}
                                        onClick={(e) =>
                                            setData('pronouns', e.target.id)
                                        }
                                    >
                                        No Pronouns/Use my name
                                    </button>
                                    <button
                                        className={
                                            data.pronouns === 'Other'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                        }
                                        type="button"
                                        id="Other"
                                        value={data.pronouns}
                                        onClick={(e) =>
                                            setData('pronouns', e.target.id)
                                        }
                                    >
                                        Other
                                    </button>
                                </div>
                                {data.pronouns === 'Other' && (
                                    <div className="text-gray-700 w-full mt-4">
                                        <label
                                            className="block mb-2"
                                            htmlFor=""
                                        >
                                            Other Pronouns Details:
                                        </label>
                                        {errors.pronouns_other && (
                                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                                {errors.pronouns_other}
                                            </div>
                                        )}
                                        <input
                                            onChange={(e) =>
                                                setData(
                                                    'pronouns_other',
                                                    e.target.value
                                                )
                                            }
                                            value={data.pronouns_other}
                                            className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                            type="text"
                                            placeholder=""
                                            id="pronouns_other"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="bg-customBlue flex w-full flex-row flex-wrap items-center space-y-4 px-8 py-8 md:space-x-4 md:space-y-0 md:px-16">
                        <button
                            type="submit"
                            className="text-customBlue flex w-full flex-row items-center justify-center bg-white px-8 py-2 font-bold md:w-auto"
                            disabled={processing}
                        >
                            {processing && (
                                <div>
                                    <svg
                                        className="mr-2 animate-spin"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 120 120"
                                    >
                                        <g
                                            id="Artboard"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                id="Group"
                                                transform="translate(9.000000, 9.000000)"
                                                stroke="#314EB9"
                                                strokeWidth="9"
                                            >
                                                <path
                                                    d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                    id="Path"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            )}
                            Next
                        </button>
                        <div className="w-auto flex-col items-center rounded-full bg-white py-2 md:w-auto md:flex-row">
                            <div className="flex flex-row items-center px-4">
                                <small className="pr-2">Step 2 of 4</small>
                                <div className="bg-customBlue h-4 w-4 rounded-full"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="bg-customBlue h-4 w-4 rounded-full"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-gray-300"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}
