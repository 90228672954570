import React from 'react'
import { HomeIcon } from '@heroicons/react/solid'
import { CogIcon } from '@heroicons/react/solid'
import { MapIcon } from '@heroicons/react/solid'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { QrcodeIcon } from '@heroicons/react/solid'
import { CameraIcon } from '@heroicons/react/solid'
import { UserAddIcon } from '@heroicons/react/solid'
import { UserIcon } from '@heroicons/react/solid'
import { DocumentTextIcon } from '@heroicons/react/solid'
import { ClockIcon } from '@heroicons/react/solid'
import { LogoutIcon } from '@heroicons/react/solid'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { OfficeBuildingIcon } from '@heroicons/react/solid'
import { SwitchVerticalIcon } from '@heroicons/react/solid'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { SearchIcon } from '@heroicons/react/outline'
import { ChartBarIcon } from '@heroicons/react/outline'
import { ClipboardCheckIcon } from '@heroicons/react/solid'
import { NewspaperIcon } from '@heroicons/react/solid'
const iconTypes = {
    Home: HomeIcon,
    Cog: CogIcon,
    Map: MapIcon,
    Camera: CameraIcon,
    UserAdd: UserAddIcon,
    DocumentText: DocumentTextIcon,
    Clock: ClockIcon,
    Logout: LogoutIcon,
    ChevronDown: ChevronDownIcon,
    QrcodeIcon: QrcodeIcon,
    ArrowLeftIcon: ArrowLeftIcon,
    OfficeBuildingIcon: OfficeBuildingIcon,
    SwitchVerticalIcon: SwitchVerticalIcon,
    ChevronRightIcon: ChevronRightIcon,
    Search: SearchIcon,
    Chart: ChartBarIcon,
    User: UserIcon,
    ClipboardCheckIcon: ClipboardCheckIcon,
    NewspaperIcon: NewspaperIcon,
}

const IconHandler = ({ icon, ...props }) => {
    let Icon = iconTypes[icon]
    return <Icon {...props} />
}

export default IconHandler
