import React from 'react'
import Icon from '../Components/IconHandler'

export default function NavDropdownItem({
    name,
    icon,
    iconColor,
    shadowColor,
    activeColor,
    isActive,
    hasExtraIcon,
    extraIcon,
    dropdownShow,
}) {
    return (
        <div
            className={
                isActive
                    ? `flex justify-start gap-2 mx-3 md:ml-3 my-4 w-auto md:w-28 lg:w-40 p-2 text-white rounded-sm transform transition-colors' + ${activeColor}`
                    : `flex justify-start gap-2 mx-3 md:ml-3 my-4 w-auto md:w-28 lg:w-40 p-2 bg-white md:bg-gray-100 text-gray-600 hover:bg-gray-100 md:hover:bg-white rounded-sm transform transition-colors`
            }
        >
            <div className="flex md:hidden lg:flex flex-row">
                <div className="h-6 w-6">
                    <Icon icon={icon} fill={iconColor} />
                </div>
            </div>
            {name}
            {extraIcon && (
                <div className="block md:hidden lg:block h-6 w-6">
                    <Icon
                        className={
                            dropdownShow === true
                                ? 'transition transform rotate-[-90] md:rotate-180'
                                : 'transition transform rotate-90 md:rotate-0'
                        }
                        icon={extraIcon}
                        fill={iconColor}
                    />
                </div>
            )}
        </div>
    )
}
