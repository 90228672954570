import { useForm } from '@inertiajs/react'
import moment from 'moment'

export default function ShowAlerts({ alerts, showClear = true }) {
    const { post } = useForm()

    function setAlertInactive(e, id) {
        e.preventDefault()
        post(route('alerts.clear', id), {
            preserveScroll: true,
        })
    }


    return (
        <div className="flex flex-col gap-4 mt-2 mb-8">
            {alerts.map((alert) => (
                <div
                    className="shadow-md flex flex-row rounded-md w-full"
                    key={alert.id}
                >
                    <div className="flex-0 bg-customRed text-white flex items-center px-2 rounded-tl-md rounded-bl-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                            />
                        </svg>
                    </div>
                    <div className="py-4 px-6 flex flex-col">
                        {alert.type === 'Other' ? (
                            <p className="text-xl mb-1">{alert.type_custom}</p>
                        ) : (
                            <p className="text-xl mb-1">{alert.type}</p>
                        )}

                        <p className="mb-2">{alert.message}</p>
                        <p className="text-sm text-gray-600">
                            {moment(alert.created_at).format(
                                'MMMM Do YYYY, h:mm A'
                            )}{' '}
                            - {alert.user.name}{' '}
                        </p>
                        {showClear ? (
                            <button
                                className="w-auto self-start py-1 hover:bg-gray-200  transition-colors px-4 mt-4 text-sm text-customRed bg-gray-100"
                                onClick={(e) => setAlertInactive(e, alert.id)}
                            >
                                Clear Alert
                            </button>
                        ) : null}
                    </div>
                </div>
            ))}
        </div>
    )
}
