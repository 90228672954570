import React from 'react'
import Layout from '../../Layout/Layout'
import { usePage } from '@inertiajs/react'

export default function Reports({ user }) {
    return (
        <Layout>
            {user.can.create_location && (
                <h1 className="animate-pulse">Reports</h1>
            )}
        </Layout>
    )
}
