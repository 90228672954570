import React from 'react'
import { Link, router } from '@inertiajs/react'

export default function Index({ id, name, address, org }) {
    function handleSelect(e, id) {
        e.preventDefault()
        router.visit(route('locations.edit', id), {
            method: 'get',
        })
    }

    function handleDelete(e, id) {
        e.preventDefault()
        router.visit(route('locations.delete', id), {
            method: 'put',
        })
    }
    return (
        <>
            <div
                key={id}
                className="lg:grid lg:grid-cols-12 flex flex-col gap-4 lg:gap-0 focus-within:bg-gray-100 border-t-2 lg:border-t border-gray-300 lg:border-gray-200 relative text-left items-left lg:items-center pt-4 lg:pt-0"
            >
                <div className="w-full md:w-1/3 lg:col-span-4">
                    <p className="lg:hidden font-bold">Name:</p>
                    <div className="lg:py-4 flex items-center justify-start focus:text-indigo-700">
                        {name}
                    </div>
                </div>
                <div className="w-full md:w-1/3 lg:col-span-4">
                    <p className="lg:hidden font-bold">Address:</p>
                    <div className="lg:py-4 flex items-center focus:text-indigo">
                        {address}
                    </div>
                </div>
                <div className="w-full md:w-1/3 lg:col-span-2">
                    <p className="lg:hidden font-bold">Org:</p>
                    <div className=" py-0 lg:py-4">{org}</div>
                </div>
                <div className="flex flex-row col-span-2 gap-8 lg:gap-4">
                    <Link
                        onClick={(e) => handleSelect(e, id)}
                        className="flex flex-row hover:text-customGreen py-4 cursor-pointer group"
                    >
                        Edit
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-gray-500 group-hover:text-customGreen w-6 h-6 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                        </svg>
                    </Link>
                    <Link
                        onClick={(e) => handleDelete(e, id)}
                        className="flex flex-row hover:text-customRed py-4  cursor-pointer group"
                    >
                        Delete
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-gray-500 group-hover:text-customRed w-6 h-6 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
        </>
    )
}
