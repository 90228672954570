import React, { useEffect, useState, useRef } from 'react'
import { Link, usePage } from '@inertiajs/react'
// import MinimizeButton from '@/Layout/Components/MinimizeButton'
import MinimizeButton from '../Components/MinimizeButton'
// import MobileHamburger from '@/Layout/Components/MobileHamburger'
import MobileHamburger from '../Components/MobileHamburger'
// import NavItem from '@/Layout/Components/NavItem'
import NavItem from '../Components/NavItem'
import NavDropdownItem from '../Components/NavDropdownItem'
// import NavPopOut from '@/Layout/Components/NavPopOut'
import NavPopOut from '../Components/NavPopOut'

export default function NavMenu({ }) {
    const { user } = usePage().props
    const [dropdownShow, setDropdownShow] = useState(false)
    const popOutRef = useRef(null)
    const isActive = route().current()
    const dropdownRef = useRef(null)

    useEffect(() => {
        const pageClickEvent = (e) => {
            // Check if click is outside the dropdown menu
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setDropdownShow(false)
            }
        }

        if (dropdownShow) {
            window.addEventListener('click', pageClickEvent)
        }

        return () => {
            window.removeEventListener('click', pageClickEvent)
        }
    }, [dropdownShow])

    return (
        <>
            <div className="hidden py-8 md:py-0 md:flex md:flex-col justify-end sticky top-0 max-w-sm">
                <div className="z-50">
                    <NavItem
                        name="Dashboard"
                        link="/dashboard"
                        icon="Map"
                        iconColor={
                            isActive === 'dashboard'
                                ? '#FFFFFF'
                                : 'rgba(251, 212, 24, 1)'
                        }
                        activeColor="bg-customYellow"
                        hasExtraIcon="false"
                        dropdownShow={dropdownShow}
                        isActive={isActive === 'dashboard' ? true : false}
                    />
                    <div
                        className="relative z-50"
                        ref={dropdownRef}
                        onClick={() => setDropdownShow(!dropdownShow)}
                    >
                        <NavDropdownItem
                            name="Forms"
                            icon="DocumentText"
                            iconColor={
                                isActive === 'intake' ||
                                    isActive === 'create2' ||
                                    isActive === 'create3' ||
                                    isActive === 'create4' ||
                                    isActive === 'exchange.show' ||
                                    isActive === 'dropin.show' ||
                                    isActive === 'vaccineservice.show' ||
                                    isActive === 'naloxone'
                                    ? '#FFFFFF'
                                    : 'rgba(100, 116, 175, 1)'
                            }
                            activeColor="bg-customBlue"
                            hasExtraIcon="true"
                            extraIcon="ChevronRightIcon"
                            isActive={
                                isActive === 'intake' ||
                                    isActive === 'create2' ||
                                    isActive === 'create3' ||
                                    isActive === 'create4' ||
                                    isActive === 'exchange.show' ||
                                    isActive === 'dropin.show' ||
                                    isActive === 'vaccineservice.show' ||
                                    isActive === 'naloxone'
                                    ? true
                                    : false
                            }
                            dropdownShow={dropdownShow}
                        />
                        {dropdownShow && <NavPopOut />}
                    </div>
                    <NavItem
                        name="Participants"
                        link="/participants/search"
                        icon="User"
                        hasExtraIcon="false"
                        iconColor={
                            isActive === 'search'
                                ? '#FFFFFF'
                                : 'rgba(198, 100, 51, 1)'
                        }
                        iconStroke={
                            isActive === 'search'
                                ? '#FFFFFF'
                                : 'rgba(198, 100, 51, 1)'
                        }
                        activeColor="bg-customOrange"
                        isActive={isActive === 'search' ? true : false}
                        dropdownShow={dropdownShow}
                    />
                    {/*
                    <NavItem
                        name="Reports"
                        link="/reports"
                        icon="Chart"
                        hasExtraIcon="false"
                        iconColor={
                            isActive === 'reports' ? '#C84F5F' : '#F3F4F6'
                        }
                        iconStroke={
                            isActive === 'reports' ? '#FFFFFF' : '#C84F5F'
                        }
                        activeColor="bg-customRed"
                        isActive={isActive === 'reports' ? true : false}
                        dropdownShow={dropdownShow}
                    />
                    */}
                    {user.can.create_location && (
                        <NavItem
                            name="Admin"
                            link="/settings/locations"
                            icon="Cog"
                            hasExtraIcon="false"
                            iconColor="rgba(142, 63, 120, 1)"
                            activeColor="bg-customPurple"
                            dropdownShow={dropdownShow}
                        />
                    )}
                    <NavItem
                        name="Logout"
                        link="/logout"
                        icon="Logout"
                        hasExtraIcon="false"
                        iconColor="rgba(128, 128, 128, 1)"
                        activeColor="bg-gray-700"
                        dropdownShow={dropdownShow}
                    />
                    {/*
                    <MinimizeButton
                        name="Minimize"
                        icon="ArrowLeft"
                        iconColor="gray"
                        shadowColor="gray"
                    />
                    */}
                </div>
            </div>
        </>
    )
}
