import React, { useState, useEffect } from 'react'
import pickBy from 'lodash/pickBy'
import { has } from 'lodash'
import { Link, router } from '@inertiajs/react'
import Layout from '../../../../Layout/Layout'
import ParticipantRow from '../../../../Components/ParticipantRow'
import Pagination from '../../../../Components/Pagination'
import QRScanner from '../../QRScanner'
import InputMask from 'react-input-mask'
import { QrcodeIcon } from '@heroicons/react/solid'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

export default function Search({ matchingParticipants, newParticipant }) {
    const { links } = matchingParticipants

    const [participant, setParticipant] = useState({
        participant_uid: newParticipant.participant_uid,
        first_name: newParticipant.first_name,
        last_name: newParticipant.last_name,
        dob: newParticipant.dob,
        qr: newParticipant.qr,
        skipSearch: true,
        valid: true,
    })
    const [values, setValues] = useState({
        search: 'poo',
        trashed: '',
        QR: '',
        validDate: true,
        DOB: '',
    })

    const [showFields, setShowFields] = useState({
        QR: true,
        DOB: false,
        All: false,
    })

    const [showNewParticipantButton, setShowNewParticipantButton] =
        useState(false)

    function handleChange(e) {
        const key = e.target.name
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
        if (values['DOB'] === '') {
            setValues((values) => ({
                ...values,
                validDate: true,
            }))
        }
    }

    function validateDate(e) {
        const value = e.target.value
        const date = moment(value)
        const isValid = date.isValid()
        if (isValid) {
            setValues((values) => ({
                ...values,
                validDate: true,
                DOB: value,
            }))
        }
        if (!isValid) {
            setValues((values) => ({
                ...values,
                validDate: false,
                DOB: value,
            }))
        }
        if (values['DOB'] === '') {
            setValues((values) => ({
                ...values,
                validDate: true,
            }))
        }
    }

    // QR Stuff
    const [showQr, setShowQr] = useState(false)
    const [qrValue, setQrValue] = useState('')
    useEffect(() => {
        const renderQr = qrValue
        setValues({
            QR: renderQr,
            search: '',
            trashed: '',
            validDate: true,
        })
    }, [qrValue])

    // end QR Stuff
    function submitSearch(e) {
        // const query = values
        e.preventDefault()
        const query = Object.keys(pickBy(values)).length
            ? pickBy(values)
            : { remember: 'forget' }
        router.get(route(route().current()), query, {
            replace: true,
            preserveState: true,
        })
        setShowNewParticipantButton(true)
    }

    function handleContinue(e) {
        e.preventDefault()
        router.post('/participants/create', participant)
    }
    return (
        <>
            <Layout title="Possible Matches">
                <div className="bg-customOrange w-full p-4 py-4 md:p-16 md:py-8 mt-4 flex flex-row justify-between flex-wrap gap-4 lg:gap-0">
                    <h1 className="text-3xl font-semibold text-white">
                        Search
                    </h1>
                    <div className="flex flex-col md:flex-row gap-2">
                        <div>
                            {newParticipant.first_name && (
                                <Link
                                    as="button"
                                    className="border-2 rounded-md border-white bg-white hover:bg-customOrange hover:text-white text-customOrange  p-2 md:p-2 flex items-center justify-center font-bold text-sm md:text-md"
                                    onClick={handleContinue}
                                >
                                    Continue Creating{' '}
                                    {newParticipant.first_name +
                                        ' ' +
                                        newParticipant.last_name}
                                </Link>
                            )}
                        </div>
                        {showNewParticipantButton == true && (
                            <Link
                                className="border-2 rounded-md border-white hover:bg-white hover:text-customOrange text-white  p-2 md:p-2 flex items-center justify-center font-bold text-sm md:text-md"
                                href="/participants/create"
                            >
                                Create a new participant
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col flex-wrap flex-shrink w-full bg-white shadow-md p-8 md:p-16 md:pt-8 mb-8 overflow-hidden">
                    <form
                        onSubmit={submitSearch}
                        className="flex flex-row flex-wrap lg:gap-4 gap-8 justify-start pb-8 w-full justify-end"
                    >
                        {showFields.All && (
                            <>
                                <div className="flex flex-col gap-2 w-full lg:w-3/12">
                                    <h3 className="text-xl font-semibold">
                                        Participant ID:
                                    </h3>
                                    <input
                                        className="relative bg-gray-100 border-b-2 border-customOrange w-full px-6 py-3 rounded-r focus:outline-none focus:ring-2 focus:ring-indigo-400"
                                        autoComplete="off"
                                        type="text"
                                        name="Participant ID"
                                        id="Participant ID"
                                        value={values.label}
                                        onChange={handleChange}
                                        placeholder="Search…"
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full lg:w-4/12">
                                    <h3 className="text-xl font-semibold">
                                        First Name:
                                    </h3>
                                    <input
                                        className="relative bg-gray-100 border-b-2 border-customOrange w-full px-6 py-3 rounded-r focus:outline-none focus:ring-2 focus:ring-indigo-400"
                                        autoComplete="off"
                                        type="text"
                                        name="First Name"
                                        id="First Name"
                                        value={values.label}
                                        onChange={handleChange}
                                        placeholder="Search…"
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full lg:w-4/12 flex-grow">
                                    <h3 className="text-xl font-semibold">
                                        Last Name:
                                    </h3>
                                    <input
                                        className="relative bg-gray-100 border-b-2 border-customOrange w-full px-6 py-3 rounded-r focus:outline-none focus:ring-2 focus:ring-indigo-400"
                                        autoComplete="off"
                                        type="text"
                                        name="Last Name"
                                        id="Last Name"
                                        value={values.label}
                                        onChange={handleChange}
                                        placeholder="Search…"
                                    />
                                </div>
                            </>
                        )}
                        {showFields.DOB && (
                            <div className="flex flex-col gap-2 w-full lg:w-4/12 flex-grow mt-2 items-baseline justify-end">
                                <div className="relative w-full">
                                    <h3 className="text-xl font-semibold">
                                        DOB:
                                    </h3>
                                    {!values.validDate && (
                                        <div className="absolute right-0 top-0 w-auto inline bg-red-100 p-1 rounded-md">
                                            <p className="text-red-500 text-xs italic">
                                                Please enter a valid date
                                                (MM/DD/YYYY)
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <InputMask
                                    className="relative bg-gray-100 border-b-2 border-customOrange w-full px-6 py-3 rounded-r focus:outline-none focus:ring-2 focus:ring-indigo-400"
                                    mask="99/99/9999"
                                    name="DOB"
                                    autoComplete="off"
                                    value={values['DOB']}
                                    // onChange={handleChange}
                                    onChange={validateDate}
                                    placeholder="Search..."
                                    type="text"
                                ></InputMask>
                            </div>
                        )}
                        {showFields.QR && (
                            <div className="flex flex-col gap-2 w-full lg:w-4/12 flex-grow mt-2">
                                {/* desktop */}
                                {!isMobile && (
                                    <label
                                        className="flex text-xl font-semibold flex flex-row items-center gap-4"
                                        htmlFor="QR"
                                    >
                                        <p>QR:</p>
                                        <div className="rounded-md p-2 bg-gray-100 hover:bg-gray-200 flex flex-row gap-2 items-center">
                                            <p className="text-sm">Scan</p>
                                            <QrcodeIcon className="h-4 w-4" />
                                        </div>
                                    </label>
                                )}
                                {/* end desktop */}
                                {/* mobile */}
                                {isMobile && (
                                    <div className="block flex justify-start items-center gap-4">
                                        <p className="text-xl font-semibold">
                                            QR:
                                        </p>
                                        <div
                                            className="block rounded-md p-2 bg-gray-100 hover:bg-gray-200 flex flex-row gap-2 items-center"
                                            onClick={() => setShowQr(true)}
                                        >
                                            <p>Scan</p>
                                            <QrcodeIcon className="h-4 w-4" />
                                        </div>
                                    </div>
                                )}
                                {/* end mobile */}
                                <input
                                    className="relative bg-gray-100 border-b-2 border-customOrange w-full px-6 py-3 rounded-r focus:outline-none focus:ring-2 focus:ring-indigo-400"
                                    autoComplete="off"
                                    type="text"
                                    name="QR"
                                    id="QR"
                                    value={
                                        qrValue !== '' ? qrValue : values.label
                                    }
                                    onChange={handleChange}
                                    placeholder="Search…"
                                />
                            </div>
                        )}
                        <div className="flex flex-col justify-end w-full md:w-auto">
                            {values.validDate && (
                                <button
                                    type="submit"
                                    className="flex justify-center items-center font-semibold border shadow-md hover:shadow-none border-customOrange bg-gray-100 p-2 h-12 w-full md:w-32 rounded-md flex-grow-0"
                                // onClick={submitSearch}
                                >
                                    Search
                                </button>
                            )}
                            {showFields.QR && showFields.All != true && (
                                <div className="flex flex-col justify-end w-full md:w-auto mt-4">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setShowFields((showFields) => ({
                                                ...showFields,
                                                QR: false,
                                                DOB: true,
                                            }))
                                            setValues((values) => ({
                                                ...values,
                                                QR: '',
                                            }))
                                        }}
                                        className="flex justify-center items-center font-semibold p-2 h-12 w-full md:w-32 shadow-md hover:shadow-none bg-gray-100  w-full rounded-md flex-grow-0"
                                    >
                                        No QR
                                    </button>
                                </div>
                            )}
                            {showFields.DOB && showFields.All != true && (
                                <div className="flex flex-col justify-end w-full md:w-auto mt-4">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setShowFields((showFields) => ({
                                                ...showFields,
                                                QR: true,
                                                DOB: true,
                                                All: true,
                                            }))
                                            setValues((values) => ({
                                                ...values,
                                                DOB: '',
                                            }))
                                            router.get(
                                                '/participants/search',
                                                {
                                                    showFields: {
                                                        QR: false,
                                                        DOB: false,
                                                        All: true,
                                                    },
                                                },
                                                {
                                                    preserveState: true,
                                                }
                                            )
                                        }}
                                        className="flex justify-center items-center font-semibold p-2 h-12 w-full md:w-32 shadow-md hover:shadow-none bg-gray-100  w-full rounded-md flex-grow-0"
                                    >
                                        No DOB
                                    </button>
                                </div>
                            )}
                        </div>
                    </form>
                    <div className="grid-cols-12 font-semibold w-full hidden lg:grid lg:pb-2">
                        <div className="col-span-2">DOB</div>
                        <div className="col-span-2">Participant ID</div>
                        <div className="col-span-3">Name</div>
                        <div className="col-span-2">QR</div>
                        <div className="col-span-1">Actions</div>
                    </div>
                    {matchingParticipants.data.map(
                        ({
                            id,
                            dob,
                            first_name,
                            last_name,
                            participant_uid,
                            qr,
                        }) => (
                            <ParticipantRow
                                key={id}
                                id={id}
                                dob={dob}
                                first_name={first_name}
                                last_name={last_name}
                                participant_uid={participant_uid}
                                qr={qr}
                                showSelect="true"
                            />
                        )
                    )}
                    <Pagination links={links} />
                </div>
                {/* <div>
                <Link
                    as="button"
                    className="block md:hidden bg-customGreen hover:bg-customGreenLight text-white font-bold py-2 mt-4 px-4 mr-2"
                    onClick={handleContinue}
                >
                    Continue Creating{' '}
                    {newParticipant.first_name + ' ' + newParticipant.last_name}
                </Link>
            </div> */}
            </Layout>
            {showQr === true && (
                <QRScanner
                    qrValue={qrValue}
                    setQrValue={setQrValue}
                    showQr={showQr}
                    setShowQr={setShowQr}
                />
            )}
        </>
    )
}
