import React, { useState } from 'react'
import { router, usePage } from '@inertiajs/react'
import FlashMessages from '../App/Shared/FlashMessages'

export default function Login({ locationLists }) {
    const { errors } = usePage().props
    const [values, setValues] = useState({
        email: '',
    })

    function handleChange(e) {
        const key = e.target.id
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()
        router.post('/forgot-password', values)
    }
    return (
        <>
            <FlashMessages />
            <form onSubmit={handleSubmit}>
                <section
                    id="forgot_password"
                    className="h-auto flex flex-wrap justify-center mt-2 md:mt-20"
                >
                    <div className="h-auto max-w-lg w-full mx-4 grid shadow-md bg-white rounded">
                        <div className="flex items-center justify-center h-24 w-full bg-gray-200 rounded rounded-b-none">
                            <h1 className="text-2xl">
                                Welcome to OnPoint NYC!
                            </h1>
                        </div>
                        <div
                            id="sign_in_container"
                            className="block w-full mt-4"
                        >
                            <div className="flex flex-col md:flex-row flex-wrap px-4">
                                <div className="mx-4 mt-6 w-full">
                                    <label
                                        className="block text-gray-700 text-sm mb-2 ml-1"
                                        htmlFor="email"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={handleChange}
                                        value={values.email}
                                        id="email"
                                        type="text"
                                        placeholder=""
                                    />
                                    {errors.email && (
                                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                            {errors.email}
                                        </div>
                                    )}
                                    <div className="flex my-6">
                                        <button className="bg-gray-200 hover:underline shadow-md w-full rounded p-2">
                                            Request Password Reset
                                        </button>
                                        {/* <button className="bg-gray-200 shadow-md w-full rounded p-2">
                                   Register
                               </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </>
    )
}
