require('./bootstrap')
import { createInertiaApp } from '@inertiajs/react'
import { FlareErrorBoundary } from '@flareapp/flare-react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { flare } from '@flareapp/flare-client'
if (process.env.NODE_ENV === 'production') {
    flare.light('wGQgdJu2WyAnJDxMR0fZj7UmRxtGES07')
}
if (app) {
    createInertiaApp({
        resolve: name => require(`./Pages/${name}`),
        setup({ el, App, props }) {
            createRoot(el).render(
                <FlareErrorBoundary>
                    <App {...props} />
                </FlareErrorBoundary>
            )
        },
    })
}
