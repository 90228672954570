import { useForm, router } from '@inertiajs/react'
import React, { useState, useEffect } from 'react'
import AdminLayout from '../../../../Layout/AdminLayout'
import { ChevronDownIcon } from '@heroicons/react/solid'

export default function UserSettings({ users, invites }) {
    const { data, setData, post, processing, errors } = useForm({
        email: '',
    })

    const [showRoleDropdown, setShowRoleDropdown] = useState([])

    function handleSubmit(e) {
        e.preventDefault()
        post(route('users.invite'))
    }
    function handleDeleteInvite(e, token) {
        e.preventDefault()
        // const invite = token
        // post(route('users.delete'), id)
        router.post(route('users.delete.invite', token))
    }
    function handleDelete(e, id) {
        e.preventDefault()
        router.post(route('users.delete', id))
    }

    function handleRoleUpdate(role, id) {
        // e.preventDefault()
        // const role = e.target.value
        router.post(route('users.update.role', id), { role, id })
    }

    useEffect(
        (e) => {
            const pageClickEvent = (e) => {
                if (showRoleDropdown.length > 0) {
                    setShowRoleDropdown([])
                }
            }
            if (showRoleDropdown) {
                window.addEventListener('click', pageClickEvent)
            }
            return () => {
                window.removeEventListener('click', pageClickEvent)
            }
        },
        [showRoleDropdown]
    )

    function handleOpen(user) {
        var existingOpenRoles = showRoleDropdown
        if (!showRoleDropdown.includes(user.id)) {
            setShowRoleDropdown((showRoleDropdown) => [...showRoleDropdown, user.id])
        } else {
            existingOpenRoles = existingOpenRoles.filter(function (item) {
                return item !== user.id
            })
            setShowRoleDropdown(existingOpenRoles)
        }
    }

    return (
        <>
            <AdminLayout title="User Settings">
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md overflow-x-auto p-8 md:p-16 mt-4">
                    <h2 className="md:hidden text-xl mb-2">User Settings</h2>
                    <h2 className="text-2xl font-semibold mb-2">Existing Users</h2>
                    {users.map((user) => (
                        <div
                            key={`${user.id}-row`}
                            className="flex flex-row shadow-sm p-4 pl-0 justify-between space-y-2 items-center"
                        >
                            <div className="w-5/12 md:w-4/12 pl-2">
                                <p>{user.name}</p>
                            </div>
                            <div className="w-5/12 md:w-7/12">
                                <p>{user.email}</p>
                            </div>
                            <div className="flex gap-4 items-center">
                                <div className="relative">
                                    <div
                                        className={`${showRoleDropdown == true ? 'hover:bg-gray-100' : 'hover:bg-gray-300'
                                            }  flex flex-row items-center w-auto md:w-54 h-auto p-2 ml-1 bg-gray-100 rounded-md cursor-pointer`}
                                        onClick={() => handleOpen(user)}
                                    >
                                        <p className="text-gray-900 whitespace-nowrap">
                                            {user && user.role ? user.role : 'Assign Role'}
                                        </p>
                                        <ChevronDownIcon
                                            className={
                                                showRoleDropdown === true
                                                    ? 'h-5 w-5 text-gray-900 ml-1 transition transform rotate-270 md:rotate-180'
                                                    : 'h-5 w-5 text-gray-900 ml-1 transition transform rotate-90 md:rotate-0'
                                            }
                                        />
                                    </div>
                                    {showRoleDropdown.includes(user.id) && (
                                        <div className="text-gray-900 bg-white shadow-lg rounded-sm gap-2 h-auto w-56 absolute top-12 left-0 z-50">
                                            <p
                                                className="cursor-pointer hover:bg-gray-100 p-4"
                                                onClick={() => handleRoleUpdate('User', user.id)}
                                            >
                                                User
                                            </p>
                                            <p
                                                className="cursor-pointer hover:bg-gray-100 p-4"
                                                onClick={() => handleRoleUpdate('Admin', user.id)}
                                            >
                                                Admin
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="rounded-md py-2 px-6 bg-red-100 hover:bg-red-200 cursor-pointer"
                                    onClick={(e) => handleDelete(e, user.id)}
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                    ))}
                    <h2 className="text-2xl font-semibold mb-2 mt-4">Pending Invites</h2>
                    {invites.map(
                        ({ email, token }) => (
                            (
                                <div className="flex flex-row shadow-sm p-4 pl-0 justify-between space-y-2 items-center">
                                    {/* <div className="w-5/12 md:w-5/12 pl-2">
                                <p>{name}</p>
                            </div> */}
                                    <div className="w-5/12 md:w-7/12">
                                        <p>{email}</p>
                                    </div>
                                    {/* <div className="rounded-md w-auto bg-yellow-200 hover:bg-yellow-300 whitespace-nowrap py-2 px-6 mr-4">
                                        Resend invitation
                                    </div> */}
                                    <div
                                        onClick={(e) => handleDeleteInvite(e, token)}
                                        className="rounded-md py-2 px-6 bg-red-100 hover:bg-red-200"
                                    >
                                        Delete Invite
                                    </div>
                                </div>
                            )
                        )
                    )}
                </div>
                <form onSubmit={handleSubmit} className="bg-customYellow w-full p-8 md:p-16">
                    <h2 className="text-3xl font-semibold text-white mb-1">Invite a new user</h2>
                    {errors.email && (
                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2 max-w-xs">
                            {errors.email}
                        </div>
                    )}
                    <div className="flex flex-row flex-wrap items-end mb-8 mt-2">
                        <div className="w-full md:w-5/12 mt-2">
                            <label className="text-white block mb-2 text-">User Email</label>
                            <input
                                onChange={(e) => setData('email', e.target.value)}
                                className="bg-white w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                value={data.email}
                                id="email"
                                type="text"
                                placeholder=""
                            />
                        </div>
                        <div className="w-full lg:w-2/12 flex flex-row items-center mt-4 md:ml-4">
                            <button type="submit" className="bg-gray-100 hover:bg-gray-200 px-8 py-2">
                                Invite
                            </button>
                        </div>
                    </div>
                </form>
            </AdminLayout>
        </>
    )
}
