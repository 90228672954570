import React from 'react'
import Layout from '../../../../Layout/Layout'
import { usePage, useForm } from '@inertiajs/react'

export default function VaccineService() {
    const { data, setData, post, processing, errors } = useForm({
        vaccine_status: {
            no_covid_vaccine: false,
            johnson_johnson_1_dose: false,
            johnson_johnson_1_dose_plus_booster: false,
            pfizer_1_dose: false,
            pfizer_2_dose: false,
            pfizer_2_dose_plus_booster: false,
            moderna_1_dose: false,
            moderna_2_dose: false,
            moderna_2_dose_plus_booster: false,
        },
        vaccine_decision: {
            info_from_NYHRE_WHCP_staff: false,
            website_blog_web_based_tool: false,
            social_media: false,
            flyers_or_signs: false,
            outreach_from_other_agency: false,
            text_call_email_mail: false,
            fear_of_getting_sick_dying: false,
            required_for_work_housing: false,
            comm_center_library_worship: false,
            community_fair_event: false,
            decide_other: false,
            decide_other_details: '',
        },
        vaccine_reasons: {
            not_worried_vaccine_not_necessary: false,
            family_member: false,
            concerned_about_side_effects: false,
            heard_bad_things_news_social_media: false,
            dont_trust_government: false,
            dont_trust_doctors: false,
            dont_trust_any_vaccine: false,
            dont_trust_covid_vaccine: false,
            belief_vaccine_has_tracking_chip: false,
            religious_beliefs: false,
            reason_other: false,
            reason_other_details: '',
        },
        vaccine_services: {
            discussed_vaccine_concerns: false,
            provided_written_materials: false,
            referred_to_hotline: false,
            referred_to_onsite_vaccine: false,
            referred_to_or_helped_schedule_with_outside_agency: false,
        },
    })

    const { user } = usePage().props

    function handleSubmit(e) {
        e.preventDefault()
        post(route('vaccineservice.create'))
    }
    //multi-select
    function handleDecision(e) {
        const id = e.target.id
        const state = data.vaccine_decision[id]
        // let { id, ...x } = state
        setData(() => ({
            ...data,
            vaccine_decision: {
                ...data.vaccine_decision,
                [id]: !state,
            },
        }))
    }

    //multi-select
    function handleReasons(e) {
        const id = e.target.id
        const state = data.vaccine_reasons[id]
        // let { id, ...x } = state
        setData(() => ({
            ...data,
            vaccine_reasons: {
                ...data.vaccine_reasons,
                [id]: !state,
            },
        }))
    }

    function handleServices(e) {
        const id = e.target.id
        const state = data.vaccine_services[id]
        // let { id, ...x } = state
        setData(() => ({
            ...data,
            vaccine_services: {
                ...data.vaccine_services,
                [id]: !state,
            },
        }))
    }

    // single - select
    function handleStatus(e) {
        const id = e.target.id
        const state = data.vaccine_status[id]
        setData(() => ({
            ...data,
            vaccine_status: {
                [id]: !state,
            },
        }))
    }

    function handleOther(e) {
        const id = e.target.id
        if (e.target.id == 'decide_other_details') {
            setData(() => ({
                ...data,
                vaccine_decision: {
                    ...data.vaccine_decision,
                    decide_other_details: e.target.value,
                },
            }))
        }
        if (e.target.id == 'reason_other_details') {
            setData(() => ({
                ...data,
                vaccine_reasons: {
                    ...data.vaccine_reasons,
                    reason_other_details: e.target.value,
                },
            }))
        }
    }

    return (
        <Layout title="Vaccine Service Form">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col flex-wrap w-full p-8 mt-2 bg-white shadow-md md:p-16 md:mb-0">
                    <div className="flex flex-row flex-wrap items-center mb-4">
                        <h5 className="mr-2 text-2xl text-gray-700">
                            Processing Vaccine Service Form for:
                        </h5>
                        <p className="text-2xl font-semibold">
                            {user.is.currently_seeing}
                        </p>
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-4">
                        <h5 className="mr-2 text-2xl text-gray-700">
                            Have you had a COVID Vaccine?
                        </h5>
                        <div className="flex flex-wrap w-full my-4">
                            <button
                                className={
                                    data.vaccine_status.no_covid_vaccine
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="no_covid_vaccine"
                                onClick={(e) => handleStatus(e)}
                            >
                                No Vaccine
                            </button>
                            <button
                                className={
                                    data.vaccine_status.johnson_johnson_1_dose
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="johnson_johnson_1_dose"
                                onClick={(e) => handleStatus(e)}
                            >
                                Johnson & Johnson - 1 Dose
                            </button>
                            <button
                                className={
                                    data.vaccine_status
                                        .johnson_johnson_1_dose_plus_booster
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="johnson_johnson_1_dose_plus_booster"
                                onClick={(e) => handleStatus(e)}
                            >
                                Johnson & Johnson - 1 Plus Booster
                            </button>
                            <button
                                className={
                                    data.vaccine_status.pfizer_1_dose
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="pfizer_1_dose"
                                value={data.pfizer_1_dose}
                                onClick={(e) => handleStatus(e)}
                            >
                                Pfizer - 1 Dose
                            </button>
                            <button
                                className={
                                    data.vaccine_status.pfizer_2_dose
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="pfizer_2_dose"
                                value={data.pfizer_2_dose}
                                onClick={(e) => handleStatus(e)}
                            >
                                Pfizer - 2 Doses
                            </button>
                            <button
                                className={
                                    data.vaccine_status
                                        .pfizer_2_dose_plus_booster
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="pfizer_2_dose_plus_booster"
                                value={data.pfizer_2_dose_plus_booster}
                                onClick={(e) => handleStatus(e)}
                            >
                                Pfizer - 2 Plus Booster
                            </button>
                            <button
                                className={
                                    data.vaccine_status.moderna_1_dose
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="moderna_1_dose"
                                value={data.moderna_1_dose}
                                onClick={(e) => handleStatus(e)}
                            >
                                Moderna - 1 Dose
                            </button>
                            <button
                                className={
                                    data.vaccine_status.moderna_2_dose
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="moderna_2_dose"
                                value={data.vaccine_status.moderna_2_dose}
                                onClick={(e) => handleStatus(e)}
                            >
                                Moderna - 2 Doses
                            </button>
                            <button
                                className={
                                    data.vaccine_status
                                        .moderna_2_dose_plus_booster
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="moderna_2_dose_plus_booster"
                                value={data.moderna_2_dose_plus_booster}
                                onClick={(e) => handleStatus(e)}
                            >
                                Moderna - 2 Plus Booster
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-4">
                        <h5 className="mr-2 text-2xl text-gray-700">
                            If yes, what helped you decide to get vaccinated?
                        </h5>
                        <div className="flex flex-wrap w-full my-4">
                            <button
                                className={
                                    data.vaccine_decision
                                        .info_from_NYHRE_WHCP_staff
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="info_from_NYHRE_WHCP_staff"
                                value={data.info_from_NYHRE_WHCP_staff}
                                onClick={(e) => handleDecision(e)}
                            >
                                Info from NYHRE/WHCP staff
                            </button>
                            <button
                                className={
                                    data.vaccine_decision
                                        .website_blog_web_based_tool
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="website_blog_web_based_tool"
                                value={data.website_blog_web_based_tool}
                                onClick={(e) => handleDecision(e)}
                            >
                                Website, Blog, Web-based tool
                            </button>
                            <button
                                className={
                                    data.vaccine_decision.social_media
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="social_media"
                                value={data.social_media}
                                onClick={(e) => handleDecision(e)}
                            >
                                Social Media (Facebook, etc)
                            </button>
                            <button
                                className={
                                    data.vaccine_decision.flyers_or_signs
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="flyers_or_signs"
                                value={data.flyers_or_signs}
                                onClick={(e) => handleDecision(e)}
                            >
                                Flyers or Signs posted around
                            </button>
                            <button
                                className={
                                    data.vaccine_decision
                                        .outreach_from_other_agency
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="outreach_from_other_agency"
                                value={data.outreach_from_other_agency}
                                onClick={(e) => handleDecision(e)}
                            >
                                Outreach from other agency
                            </button>
                            <button
                                className={
                                    data.vaccine_decision.text_call_email_mail
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="text_call_email_mail"
                                value={data.text_call_email_mail}
                                onClick={(e) => handleDecision(e)}
                            >
                                Text/Call/E-mail/Mail
                            </button>
                            <button
                                className={
                                    data.vaccine_decision
                                        .fear_of_getting_sick_dying
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="fear_of_getting_sick_dying"
                                value={data.fear_of_getting_sick_dying}
                                onClick={(e) => handleDecision(e)}
                            >
                                Fear of getting sick/dying
                            </button>
                            <button
                                className={
                                    data.vaccine_decision
                                        .required_for_work_housing
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="required_for_work_housing"
                                value={data.required_for_work_housing}
                                onClick={(e) => handleDecision(e)}
                            >
                                Required for work/housing
                            </button>
                            <button
                                className={
                                    data.vaccine_decision
                                        .comm_center_library_worship
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="comm_center_library_worship"
                                value={data.comm_center_library_worship}
                                onClick={(e) => handleDecision(e)}
                            >
                                Comm. Center, Library, Worship
                            </button>
                            <button
                                className={
                                    data.vaccine_decision.community_fair_event
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="community_fair_event"
                                value={data.community_fair_event}
                                onClick={(e) => handleDecision(e)}
                            >
                                Community Fair/Event
                            </button>
                            <button
                                className={
                                    data.vaccine_decision.decide_other
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="decide_other"
                                value={data.decide_other}
                                onClick={(e) => handleDecision(e)}
                            >
                                Other
                            </button>
                        </div>
                        {data.vaccine_decision.decide_other && (
                            <div className="flex flex-col w-full pl-2 md:w-6/12">
                                <label
                                    className="block mt-4 mb-2"
                                    htmlFor="decide_other_details"
                                >
                                    Other Details:
                                </label>
                                <input
                                    onChange={(e) => handleOther(e)}
                                    value={data.decide_other_details}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="decide_other_details"
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-4">
                        <h5 className="mr-2 text-2xl text-gray-700">
                            If no, are there any specifics reasons why you
                            haven't gotten vaccinated for COVID?
                        </h5>
                        <div className="flex flex-wrap w-full my-4">
                            <button
                                className={
                                    data.vaccine_reasons
                                        .not_worried_vaccine_not_necessary
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="not_worried_vaccine_not_necessary"
                                value={data.not_worried_vaccine_not_necessary}
                                onClick={(e) => handleReasons(e)}
                            >
                                Not worried about COVID/ Vaccine not necessary
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons.family_member
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="family_member"
                                value={data.family_member}
                                onClick={(e) => handleReasons(e)}
                            >
                                Family member does not want me to get it
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons
                                        .concerned_about_side_effects
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="concerned_about_side_effects"
                                value={
                                    data.vaccine_reasons
                                        .concerned_about_side_effects
                                }
                                onClick={(e) => handleReasons(e)}
                            >
                                Concerned about side effects (short-term or
                                long-term)
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons
                                        .heard_bad_things_news_social_media
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="heard_bad_things_news_social_media"
                                value={data.heard_bad_things_news_social_media}
                                onClick={(e) => handleReasons(e)}
                            >
                                Heard Bad Things on News or Social Media
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons.dont_trust_government
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="dont_trust_government"
                                value={data.dont_trust_government}
                                onClick={(e) => handleReasons(e)}
                            >
                                Don't Trust Government
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons.dont_trust_doctors
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="dont_trust_doctors"
                                value={data.dont_trust_doctors}
                                onClick={(e) => handleReasons(e)}
                            >
                                Don't Trust Doctors
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons.dont_trust_any_vaccine
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="dont_trust_any_vaccine"
                                value={data.dont_trust_any_vaccine}
                                onClick={(e) => handleReasons(e)}
                            >
                                Don't trust any vaccine
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons
                                        .dont_trust_covid_vaccine
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="dont_trust_covid_vaccine"
                                value={data.dont_trust_covid_vaccine}
                                onClick={(e) => handleReasons(e)}
                            >
                                Don't trust COVID vaccine
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons
                                        .belief_vaccine_has_tracking_chip
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="belief_vaccine_has_tracking_chip"
                                value={data.belief_vaccine_has_tracking_chip}
                                onClick={(e) => handleReasons(e)}
                            >
                                Belief Vaccine has Tracking Chip
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons.religious_beliefs
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="religious_beliefs"
                                value={data.religious_beliefs}
                                onClick={(e) => handleReasons(e)}
                            >
                                Religious Beliefs
                            </button>
                            <button
                                className={
                                    data.vaccine_reasons.reason_other
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="reason_other"
                                value={data.reason_other}
                                onClick={(e) => handleReasons(e)}
                            >
                                Other
                            </button>
                        </div>
                        {data.vaccine_reasons.reason_other && (
                            <div className="flex flex-col w-full pl-2 md:w-6/12">
                                <label
                                    className="block mt-4 mb-2"
                                    htmlFor="reason_other_details"
                                >
                                    Other Details:
                                </label>
                                <input
                                    onChange={(e) => handleOther(e)}
                                    value={
                                        data.vaccine_reasons
                                            .reason_other_details
                                    }
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="reason_other_details"
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-4">
                        <h5 className="mr-2 text-2xl text-gray-700">
                            Vaccine-Related Services
                        </h5>
                        <div className="flex flex-wrap w-full my-4">
                            <button
                                className={
                                    data.vaccine_services
                                        .discussed_vaccine_concerns
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="discussed_vaccine_concerns"
                                value={data.discussed_vaccine_concerns}
                                onClick={(e) => handleServices(e)}
                            >
                                Discussed vaccine concerns with participant
                            </button>
                            <button
                                className={
                                    data.vaccine_services
                                        .provided_written_materials
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="provided_written_materials"
                                value={data.provided_written_materials}
                                onClick={(e) => handleServices(e)}
                            >
                                Provided written materials/vax facts
                            </button>
                            <button
                                className={
                                    data.vaccine_services.referred_to_hotline
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="referred_to_hotline"
                                value={data.referred_to_hotline}
                                onClick={(e) => handleServices(e)}
                            >
                                Referred to our hotline
                            </button>
                            <button
                                className={
                                    data.vaccine_services
                                        .referred_to_onsite_vaccine
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="referred_to_onsite_vaccine"
                                value={data.referred_to_onsite_vaccine}
                                onClick={(e) => handleServices(e)}
                            >
                                Referred to on-site vaccine
                            </button>
                            <button
                                className={
                                    data.vaccine_services
                                        .referred_to_or_helped_schedule_with_outside_agency
                                        ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4 m-2'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4 m-2'
                                }
                                type="button"
                                id="referred_to_or_helped_schedule_with_outside_agency"
                                value={
                                    data.referred_to_or_helped_schedule_with_outside_agency
                                }
                                onClick={(e) => handleServices(e)}
                            >
                                Referred to or helped schedule appt. with
                                outside agency
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap items-center w-full px-8 mb-8 space-y-4 bg-customBlue md:space-x-4 md:space-y-0 py-7 md:px-16">
                    <button
                        type="submit"
                        className="flex flex-row items-center justify-center w-full h-12 px-8 py-2 font-bold bg-white text-customBlue md:w-auto"
                        disabled={processing}
                    >
                        {processing && (
                            <div>
                                <svg
                                    className="mr-2 animate-spin"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 120 120"
                                >
                                    <g
                                        id="Artboard"
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                    >
                                        <g
                                            id="Group"
                                            transform="translate(9.000000, 9.000000)"
                                            stroke="#314EB9"
                                            strokeWidth="9"
                                        >
                                            <path
                                                d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                id="Path"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        )}
                        Done
                    </button>
                    <div className="flex-col items-center w-auto py-2 bg-white rounded-full md:flex-row md:w-auto">
                        <div className="flex flex-row items-center px-4">
                            <small className="pr-2">Step 1 of 1</small>
                            <div className="w-4 h-4 rounded-full bg-customBlue"></div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    )
}
