import React, { useEffect, useState } from 'react'
import { router, usePage } from '@inertiajs/react'
import FlashMessages from '../App/Shared/FlashMessages'
import Select from 'react-select'

export default function Login({ token }) {
    const { errors } = usePage().props
    const [values, setValues] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: token,
    })

    function handleChange(e) {
        const key = e.target.id
        const value = e.target.value
        setValues((values) => ({
            ...values,
            [key]: value,
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()
        router.post('/reset-password', values)
    }
    return (
        <>
            <FlashMessages />
            <form onSubmit={handleSubmit}>
                <section
                    id="authentication"
                    className="h-auto flex flex-wrap justify-center mt-2 md:mt-20"
                >
                    <div className="h-auto max-w-lg w-full mx-4 grid shadow-md bg-white rounded">
                        <div className="flex items-center justify-center h-24 w-full bg-gray-200 rounded rounded-b-none">
                            <h1 className="text-2xl">
                                Welcome to OnPoint NYC!
                            </h1>
                        </div>
                        <div
                            id="sign_in_container"
                            className="block w-full mt-4"
                        >
                            <div className="flex flex-col md:flex-row flex-wrap px-4 w-full">
                                <p className="text-sm text-gray-500 mx-4">
                                    You have requested a password reset. Please
                                    enter your email address, and then choose a
                                    new password by entering it into both
                                    password boxes below.
                                </p>
                                <div className="mx-4 mt-6 w-full">
                                    <label
                                        className="block text-gray-700 text-sm mb-2 ml-1"
                                        htmlFor="email"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={handleChange}
                                        value={values.email}
                                        id="email"
                                        type="text"
                                        placeholder=""
                                    />
                                    {errors.email && (
                                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                            {errors.email}
                                        </div>
                                    )}
                                    <label
                                        className="block text-gray-700 text-sm mt-2 mb-2 ml-1"
                                        htmlFor="password"
                                    >
                                        Password
                                    </label>
                                    <input
                                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                                        onChange={handleChange}
                                        value={values.password}
                                        id="password"
                                        type="password"
                                        placeholder=""
                                    />
                                    {errors.password && (
                                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                            {errors.password}
                                        </div>
                                    )}
                                    <label
                                        className="block text-gray-700 text-sm mt-2 mb-2 ml-1"
                                        htmlFor="password_confirmation"
                                    >
                                        Confirm Password
                                    </label>
                                    <input
                                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                                        onChange={handleChange}
                                        value={values.password_confirmation}
                                        id="password_confirmation"
                                        type="password"
                                        placeholder=""
                                    />
                                    {errors.password_confirmation && (
                                        <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                            {errors.password_confirmation}
                                        </div>
                                    )}
                                    {/*
                            <div className="flex justify-between items-center">
                                <label className="inline text-gray-600 mt-4">
                                    <input
                                        className="mr-2 leading-tight"
                                        type="checkbox"
                                    />
                                    <span className="text-sm">Remember Me</span>
                                </label>
                                {/* <a
                                    className="text-sm inline text-right text-gray-600 mt-4"
                                    href="#forgot?"
                                >
                                    Forgot Password?
                                </a>
                                <a
                                    className="text-sm inline text-right text-gray-600 mt-4"
                                    href="/register"
                                >
                                    Register
                                </a>
                            </div>
                            //<div className="h-px w-full bg-gray-300 my-8"></div>
                           */}

                                    <div className="flex my-6">
                                        <button className="bg-gray-200 hover:underline shadow-md w-full rounded p-2">
                                            Sign In
                                        </button>
                                        {/* <button className="bg-gray-200 shadow-md w-full rounded p-2">
                                    Register
                                </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </>
    )
}
