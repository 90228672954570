import React, { useState } from 'react'
import AdminLayout from '../../../../Layout/AdminLayout'

export default function GeneralSettings({}) {
    return (
        <>
            <AdminLayout title="General Settings">
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 mt-4">
                    <h2 className="md:hidden text-xl mb-2">General Settings</h2>
                </div>
            </AdminLayout>
        </>
    )
}
