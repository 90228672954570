import { React } from 'react'

export default function FormButton({ id, isActive, value, text, ...props }) {
    return (
        <button
            className={
                isActive
                    ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4'
                    : 'bg-gray-200 hover:bg-gray-100 px-4 py-2 w-full md:w-1/4'
            }
            type="button"
            id={id}
            value={value}
            {...props}
        >
            {text}
        </button>
    )
}
