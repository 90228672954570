import React from 'react'
import { Link } from '@inertiajs/react'

export default function NavPopOutItem({ name, link }) {
    var navClass =
        `flex justify-between p-2 md:p-4 hover:bg-gray-100 text-sm md:text-base`

    return (
        <Link href={link}>
            <div className={navClass}>
                {name}
            </div>
        </Link>
    )
}
