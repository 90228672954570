import React, { useState, useEffect } from 'react'
import Layout from '../../../../Layout/Layout'
import { useForm } from '@inertiajs/react'
import InputMask from 'react-input-mask'
import QRScanner from '../../QRScanner'
import { QrcodeIcon } from '@heroicons/react/solid'
import moment from 'moment'

const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream

export default function IntakePageOne() {
    const { data, setData, post, processing, errors } = useForm({
        participant_uid: '',
        first_name: '',
        last_name: '',
        dob: '',
        qr: null,
        validDate: true,
    })
    function validateDate(e) {
        const key = e.target.name
        const value = e.target.value
        const date = moment(value)
        const isValid = date.isValid()
        if (isValid) {
            setData((data) => ({
                ...data,
                validDate: true,
                dob: value,
            }))
        }
        if (!isValid) {
            setData((data) => ({
                ...data,
                dob: value,
                validDate: false,
            }))
        }
    }

    const [showQr, setShowQr] = useState(false)
    const [qrValue, setQrValue] = useState('')

    useEffect(() => {
        const scanQr = qrValue
        setData((data) => ({
            ...data,
            qr: scanQr,
        }))
    }, [qrValue])

    function handleSubmit(e) {
        e.preventDefault()
        post('/participants/create')
    }

    return (
        <>
            <Layout title="New Participant Intake Form">
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col flex-wrap w-full p-8 mt-4 bg-white shadow-md md:p-16">
                        <h5 className="mb-4 text-2xl font-semibold text-gray-700 ">
                            Basic Information
                        </h5>
                        <div className="flex flex-row flex-wrap w-full">
                            <div className="w-full mt-4 text-gray-700 md:w-1/2 md:pr-2 md:mt-8">
                                {errors.dob && (
                                    <div className="px-4 py-2 mt-2 font-bold text-white bg-red-500 rounded-full">
                                        {errors.dob}
                                    </div>
                                )}
                                <label className="block mb-2" htmlFor="dob">
                                    Participant Date of Birth
                                </label>
                                <InputMask
                                    className="w-full h-10 pl-2 text-gray-700 border border-gray-400"
                                    mask="99/99/9999"
                                    value={data.dob}
                                    name="dob"
                                    onChange={validateDate}
                                    placeholder="MM/DD/YYYY"
                                ></InputMask>
                                {!data.validDate && (
                                    <p className="text-red-500 text-xs italic">
                                        Please enter a valid date (MM/DD/YYYY)
                                    </p>
                                )}
                            </div>
                            <div className="w-full mt-4 text-gray-700 md:w-1/2 md:pr-2 md:mt-8">
                                {errors.first_name && (
                                    <div className="px-4 py-2 mt-2 font-bold text-white bg-red-500 rounded-full">
                                        {errors.first_name}
                                    </div>
                                )}
                                <label
                                    className="block mb-2"
                                    htmlFor="first_name"
                                >
                                    Participant First Name
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('first_name', e.target.value)
                                    }
                                    value={data.first_name}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="first_name"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap w-full">
                            <div className="w-full mt-4 text-gray-700 md:w-1/2 md:mt-8">
                                {errors.last_name && (
                                    <div className="px-4 py-2 mt-2 font-bold text-white bg-red-500 rounded-full">
                                        {errors.last_name}
                                    </div>
                                )}
                                <label
                                    className="block mb-2"
                                    htmlFor="lastName"
                                >
                                    Participant Last Name
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('last_name', e.target.value)
                                    }
                                    value={data.last_name}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="last_name"
                                />
                            </div>
                            <div className="w-full mt-4 text-gray-700 md:w-1/2 md:pl-2 md:mt-8">
                                <div className="w-full text-gray-700 md:w-6/12">
                                    {errors.participant_uid && (
                                        <div className="px-4 py-2 mt-2 font-bold text-white bg-red-500 rounded-full">
                                            {errors.participant_uid}
                                        </div>
                                    )}
                                    <label
                                        className="block mb-2"
                                        htmlFor="chartNumber"
                                    >
                                        Participant ID
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setData(
                                                'participant_uid',
                                                e.target.value
                                            )
                                        }
                                        value={data.participant_uid}
                                        className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                        type="text"
                                        placeholder=""
                                        id="participant_uid"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap w-full">
                            <div className="w-full mt-4 text-gray-700 md:w-1/2 md:mt-8">
                                {errors.qr && (
                                    <div className="px-4 py-2 mt-2 font-bold text-white bg-red-500 rounded-full">
                                        {errors.qr}
                                    </div>
                                )}
                                <p>QR</p>
                                <div className="relative">
                                    <input
                                        className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                        type="text"
                                        placeholder=""
                                        id="QR"
                                        onChange={(e) =>
                                            setData('qr', e.target.value)
                                        }
                                        value={data.qr}
                                    />
                                    {!isIOS && (
                                        <label
                                            className="absolute block top-2 right-2"
                                            htmlFor="QR"
                                        >
                                            <div className="flex flex-row items-center w-auto gap-2 p-1 text-sm bg-gray-100 rounded-md hover:bg-gray-200 ">
                                                <p>Scan</p>
                                                <QrcodeIcon className="w-4 h-4" />
                                            </div>
                                        </label>
                                    )}
                                    {isIOS && (
                                        <div
                                            className="text-center mt-4 relative flex flex-row justify-center cursor-pointer items-center block gap-2 p-1 bg-gray-100  hover:bg-gray-200"
                                            onClick={() => setShowQr(true)}
                                        >
                                            <p>Scan</p>
                                            <QrcodeIcon className="w-4 h-4" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap items-center w-full px-8 space-y-4 bg-customBlue md:space-x-4 md:space-y-0 py-7 md:px-16">
                        {data.validDate && (
                            <button
                                type="submit"
                                className="flex flex-row items-center justify-center w-full px-8 py-2 font-bold bg-white text-customBlue md:w-auto"
                                disabled={processing}
                            >
                                {processing && (
                                    <div>
                                        <svg
                                            className="mr-2 animate-spin"
                                            width="18px"
                                            height="18px"
                                            viewBox="0 0 120 120"
                                        >
                                            <g
                                                id="Artboard"
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <g
                                                    id="Group"
                                                    transform="translate(9.000000, 9.000000)"
                                                    stroke="#314EB9"
                                                    strokeWidth="9"
                                                >
                                                    <path
                                                        d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                        id="Path"
                                                    ></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                )}
                                Next
                            </button>
                        )}
                        <div className="flex-col items-center w-full py-2 bg-white rounded-full md:flex-row md:w-auto">
                            <div className="flex flex-row items-center px-4">
                                <small className="pr-2">Step 1 of 4</small>
                                <div className="w-4 h-4 rounded-full bg-customBlue"></div>
                                <div className="w-2 h-1 bg-gray-300"></div>
                                <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
                                <div className="w-2 h-1 bg-gray-300"></div>
                                <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
                                <div className="w-2 h-1 bg-gray-300"></div>
                                <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
                            </div>
                        </div>
                    </div>
                </form>
            </Layout>
            {showQr === true && (
                <QRScanner
                    qrValue={qrValue}
                    setQrValue={setQrValue}
                    showQr={showQr}
                    setShowQr={setShowQr}
                />
            )}
        </>
    )
}
