import React, { useState, useEffect } from 'react'
import QrReader from 'react-qr-scanner'

export default function QRScanner({ showQr, setShowQr, qrValue, setQrValue }) {
    // useState(){
    //     super(props)
    //     this.state = {
    //       delay: 100,
    //       result: 'No result',
    //     }

    //     this.handleScan = this.handleScan.bind(this)
    //   }
    const [values, setValues] = useState({
        delay: 1000,
        result: 'No result',
    })

    const previewStyle = {}

    function handleScan(data) {
        if (data) {
            setValues({
                result: data,
            })
            setQrValue(data.text)
            setShowQr(false)
        }
    }
    function handleError(err) {
        console.error(err)
    }

    const [width, setWidth] = useState(window.innerWidth)

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])
    let isDesktop = width > 768

    // render() {
    //     const previewStyle = {
    //         height: 240,
    //         width: 320,
    //     }
    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-none z-30">
            <div
                className="bg-gray-100 p-2 rounded-md w-auto absolute top-2 right-2"
                onClick={() => setShowQr(false)}
            >
                Close
            </div>
            <div className="opacity-100 z-50 w-full h-full flex justify-center items-center">
                <QrReader
                    delay={values.delay}
                    style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                    // facingMode="rear"
                    constraints={{
                        video: {
                            facingMode: { exact: `environment` },
                        },
                    }}
                />
                <p>{values.result['text']}</p>
            </div>
        </div>
    )
}
