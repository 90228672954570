import React from 'react'
import AdminLayout from '../../../../Layout/AdminLayout'
import { useForm } from '@inertiajs/react'
import Select from 'react-select'

export default function LocationSettings({ location }) {
    const { data, setData, put, processing, errors } = useForm({
        org: location.org || '',
        name: location.name || '',
        address: location.address || '',
        crossStreet: location.cross_street || '',
        type: location.type || '',
        zip: location.zip || '',
    })

    let options = [
        { label: 'NYHRE', value: 'NYHRE' },
        { label: 'WHCP', value: 'WHCP' },
    ]
    let options2 = [
        { label: 'Waivered Mobile', value: 'waivered_mobile_site' },
        { label: 'OPST', value: 'opst_site' },
        { label: 'Permanent', value: 'permanent' },
    ]

    function handleSelect(opt) {
        setData({
            ...data,
            org: opt.value,
        })
    }
    function handleSelect2(opt) {
        setData({
            ...data,
            type: opt.value,
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        put(route('locations.update', location.id), data)
    }

    return (
        <>
            <AdminLayout title="Location Settings">
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md mt-4 mb-8">
                    <form
                        onSubmit={handleSubmit}
                        className="w-full p-8 md:p-12"
                    >
                        <h2 className="text-xl font-bold mb-4">
                            Edit {data.name}
                        </h2>
                        <div className="flex flex-row flex-wrap items-end mb-4 gap-2">
                            <div className="w-full lg:w-4/12 flex flex-col">
                                {errors.org && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.org}
                                    </div>
                                )}
                                <label className="block mb-2 text-black">
                                    Organization
                                </label>
                                <Select
                                    defaultValue={{
                                        label: data.org,
                                        value: data.org,
                                    }}
                                    options={options}
                                    onChange={(opt) => handleSelect(opt)}
                                />
                            </div>
                            <div className="w-full lg:w-4/12 flex flex-col">
                                {errors.type && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.type}
                                    </div>
                                )}
                                <label className="block mb-2 text-black">
                                    Location Type
                                </label>
                                <Select
                                    defaultValue={{
                                        label:
                                            data.type.charAt(0).toUpperCase() +
                                            data.type.slice(1),
                                        value: data.type,
                                    }}
                                    options={options2}
                                    onChange={(opt) => handleSelect2(opt)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-end mb-4 gap-2">
                            <div className="text-gray-700 w-full lg:w-4/12">
                                {errors.name && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.name}
                                    </div>
                                )}
                                <label className="block mb-2 text-black">
                                    Location Name
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('name', e.target.value)
                                    }
                                    value={data.name}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="name"
                                />
                            </div>
                            <div className="text-gray-700 w-full lg:w-4/12 mt-2">
                                {errors.address && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.address}
                                    </div>
                                )}
                                <label className="block mb-2 text-black">
                                    Location Address
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('address', e.target.value)
                                    }
                                    value={data.address}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="address"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-end mb-4 gap-2">
                            <div className="text-gray-700 w-full lg:w-4/12 mt-2">
                                {errors.crossStreet && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.crossStreet}
                                    </div>
                                )}
                                <label className="block mb-2 text-black">
                                    Location Cross Streets / Description
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('crossStreet', e.target.value)
                                    }
                                    value={data.crossStreet}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="crossStreet"
                                />
                            </div>
                            <div className="text-gray-700 w-full lg:w-4/12 mt-2">
                                {errors.crossStreet && (
                                    <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                        {errors.crossStreet}
                                    </div>
                                )}
                                <label className="block mb-2 text-black">
                                    Location Zip Code
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('zip', e.target.value)
                                    }
                                    value={data.zip}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    type="text"
                                    placeholder=""
                                    id="zip"
                                />
                            </div>
                        </div>
                        <div className="w-full lg:w-2/12 flex flex-row items-center mt-4">
                            <button
                                type="submit"
                                className="bg-gray-100 hover:bg-gray-200 px-8 py-2"
                                disabled={processing}
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </AdminLayout>
        </>
    )
}
