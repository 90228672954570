import React from 'react'
import Layout from '../../../Layout/Layout'

export default function Index() {

	return (
		<Layout title="Reports">
			<div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 mt-4 overflow-x-auto right-box">
				<div>Reports</div>
			</div>
			{/* <div>
				<Link
					as="button"
					className="block md:hidden bg-customGreen hover:bg-customGreenLight text-white font-bold py-2 mt-4 px-4 mr-2"
					onClick={handleContinue}
				>
					Continue Creating{' '}
					{newParticipant.first_name + ' ' + newParticipant.last_name}
				</Link>
			</div> */}
		</Layout>
	)
}
