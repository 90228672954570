import React from 'react'
import Layout from '../../../Layout/Layout'
import SearchBar from '../../../Components/SearchBar'

export default function Index({ exchanges }) {
    return (
        <Layout>
            <h1>Participants</h1>
            <SearchBar />
            <table className="w-full whitespace-no-wrap">
                <thead>
                    <tr className="text-left font-bold">
                        <th className="px-6 pt-5 pb-4">Created At</th>
                        <th className="px-6 pt-5 pb-4">Created By</th>
                        <th className="px-6 pt-5 pb-4">Created For</th>
                    </tr>
                </thead>
                {exchanges.data.map(
                    ({ id, created_by, created_at, participant }) => (
                        <NeedleExchangeRow
                            key={id}
                            id={id}
                            created={created_at}
                            participant_ccn={participant.ccn}
                            user_name={created_by.name}
                            showSelect={false}
                        />
                    )
                )}
            </table>
        </Layout>
    )
}
