import React, { useState } from 'react'
import Layout from '../../../../Layout/Layout'
import { useForm } from '@inertiajs/react'

export default function IntakePageFour() {
    const { data, setData, post, processing, errors } = useForm({
        insurdesc: '',
        pprov_name: '',
        risk: '',
        drug_of_choice: '',
        opioid_details: '',
        cocaine_details: '',
        frequency: '',
        daily_use_details: '',
        alert: '',
        alert_message: '',
    })

    const [showDrugOfChoiceOther, setShowDrugOfChoiceOther] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        post('create4', data)
    }
    return (
        <>
            <Layout title="New Participant Intake Form">
                <form onSubmit={handleSubmit} className="mb-4">
                    <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 mt-4 mb-8 md:mb-0">
                        <h5 className="mt-8 text-gray-700 font-semibold">Insurance Status</h5>
                        {errors.insurdesc && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                {errors.insurdesc}
                            </div>
                        )}
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.insurdesc === 'Known/Specify'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Known/Specify"
                                value={data.insurdesc}
                                onClick={(e) => setData('insurdesc', e.target.id)}
                            >
                                Known
                            </button>
                            <button
                                className={
                                    data.insurdesc === 'Unknown/Unreported'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Unknown/Unreported"
                                value={data.insurdesc}
                                onClick={(e) => setData('insurdesc', e.target.id)}
                            >
                                Unknown / Unreported
                            </button>
                            <button
                                className={
                                    data.insurdesc === 'No Insurance'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="No Insurance"
                                value={data.insurdesc}
                                onClick={(e) => setData('insurdesc', e.target.id)}
                            >
                                No Insurance / Self-Pay
                            </button>
                        </div>
                        <h5 className="mt-4 text-gray-700 font-semibold ">Insurance Carrier</h5>
                        {errors.pprov_name && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                {errors.pprov_name}
                            </div>
                        )}
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.pprov_name === 'Medicaid fee-for-service'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Medicaid fee-for-service"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Medicaid (fee-for-service)
                            </button>
                            <button
                                className={
                                    data.pprov_name.includes('Medicaid (MCO)')
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Medicaid (MCO)"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Medicaid (MCO)
                            </button>
                            <button
                                className={
                                    data.pprov_name === 'Medicare'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Medicare"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Medicare
                            </button>
                            <button
                                className={
                                    data.pprov_name === 'Private - Employer'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Private - Employer"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Private - Employer
                            </button>
                            <button
                                className={
                                    data.pprov_name === 'Private - Individual'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Private - Individual"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Private - Individual
                            </button>
                            <button
                                className={
                                    data.pprov_name === 'Uninsured / Possibly Eligible'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="uninsured/possibly_eligible"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Uninsured - Possibly Eligible
                            </button>
                            <button
                                className={
                                    data.pprov_name === 'Uninsured / Not Eligible'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="Uninsured / Not Eligible"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Uninsured - Not Eligible
                            </button>
                            <button
                                className={
                                    data.pprov_name === 'No Insurance / Self Pay'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="No Insurance / Self Pay"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                No Insurance / Self-Pay
                            </button>
                            <button
                                className={
                                    data.pprov_name === 'declined'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="declined"
                                value={data.pprov_name}
                                onClick={(e) => setData('pprov_name', e.target.id)}
                            >
                                Declined
                            </button>
                        </div>
                        {data.pprov_name.includes('Medicaid (MCO)') && (
                            <>
                                <h5 className="mt-8 text-gray-700 font-semibold">Medicaid Carrier</h5>
                                <div className="w-full flex flex-wrap mt-2 gap-2">
                                    <button
                                        className={
                                            data.pprov_name.includes('Healthfirst PHSP')
                                                ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):Healthfirst PHSP"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        Healthfirst PHSP
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('Amida Care')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):Amida Care"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        Amida Care
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('MetroPlus Health Plan')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):MetroPlus Health Plan"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        MetroPlus Health Plan
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('Affinity Health Plan')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):Affinity Health Plan"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        Affinity Health Plan
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('EmblemHealth (HIP)')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):EmblemHealth (HIP)"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        EmblemHealth (HIP)
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('Empire Blue Cross Blue Shield Health Plus')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):Empire Blue Cross Blue Shield Health Plus"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        Empire Blue Cross Blue Shield Health Plus
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('Fidelis Care New York')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):Fidelis Care New York"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        Fidelis Care New York
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('United Healthcare Community Plan')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):United Healthcare Community Plan"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        United Healthcare Community Plan
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('WellCare of New York')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):WellCare of New York"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        WellCare of New York
                                    </button>
                                    <button
                                        className={
                                            data.pprov_name.includes('Other/unknown')
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Medicaid (MCO):Other/unknown"
                                        value={data.pprov_name}
                                        onClick={(e) => setData('pprov_name', e.target.id)}
                                    >
                                        Other/unknown
                                    </button>
                                </div>
                            </>
                        )}
                        <h5 className="mt-4 text-gray-700 font-semibold">Drug of choice</h5>
                        {errors.drug_of_choice && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                {errors.drug_of_choice}
                            </div>
                        )}
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.drug_of_choice === 'opioids'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="opioids"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                Opioids
                            </button>
                            <button
                                className={
                                    data.drug_of_choice === 'cocaine'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="cocaine"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                Cocaine
                            </button>
                            <button
                                className={
                                    data.drug_of_choice === 'marijuana'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="marijuana"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                Marijuana
                            </button>
                            <button
                                className={
                                    data.drug_of_choice === 'alcohol'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="alcohol"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                Alcohol
                            </button>
                            <button
                                className={
                                    data.drug_of_choice === 'methamphetamine'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="methamphetamine"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                Methamphetamine
                            </button>
                            <button
                                className={
                                    data.drug_of_choice === 'benzodiazepines'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="benzodiazepines"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                Benzodiazepines
                            </button>
                            <button
                                className={
                                    data.drug_of_choice === 'K2'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="K2"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                K2
                            </button>
                            <button
                                className={
                                    data.drug_of_choice === 'speedball'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="speedball"
                                value={data.drug_of_choice}
                                onClick={(e) => {
                                    setData('drug_of_choice', e.target.id), setShowDrugOfChoiceOther(false)
                                }}
                            >
                                Speedball
                            </button>
                            <button
                                className={
                                    showDrugOfChoiceOther == true
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                onClick={(e) => {
                                    setData('drug_of_choice', ''), setShowDrugOfChoiceOther(true)
                                }}
                            >
                                Other
                            </button>
                        </div>
                        {data.drug_of_choice === 'opioids' && (
                            <>
                                <h5 className="mt-8 text-gray-700 font-semibold">Opioid use details</h5>
                                <div className="w-full flex flex-wrap mt-2 gap-2">
                                    <button
                                        className={
                                            data.opioid_details === 'heroin/fentanyl'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="heroin/fentanyl"
                                        value={data.opioid_details}
                                        onClick={(e) => setData('opioid_details', e.target.id)}
                                    >
                                        Heroin/Fentanyl
                                    </button>
                                    <button
                                        className={
                                            data.opioid_details === 'other_opioids'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="other_opioids"
                                        value={data.opioid_details}
                                        onClick={(e) => setData('opioid_details', e.target.id)}
                                    >
                                        Other Opioids (pills)
                                    </button>
                                </div>
                            </>
                        )}
                        {data.drug_of_choice === 'cocaine' && (
                            <>
                                <h5 className="mt-8 text-gray-700 font-semibold">Cocaine use details</h5>
                                <div className="w-full flex flex-wrap mt-2 gap-2">
                                    <button
                                        className={
                                            data.cocaine_details === 'powder'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="powder"
                                        value={data.cocaine_details}
                                        onClick={(e) => setData('cocaine_details', e.target.id)}
                                    >
                                        Powder
                                    </button>
                                    <button
                                        className={
                                            data.cocaine_details === 'crack'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="crack"
                                        value={data.cocaine_details}
                                        onClick={(e) => setData('cocaine_details', e.target.id)}
                                    >
                                        Crack
                                    </button>
                                </div>
                            </>
                        )}
                        {showDrugOfChoiceOther == true && (
                            <div className="text-gray-700 w-full max-w-lg mt-4">
                                <label className="block mb-2" htmlFor="">
                                    Drug of Choice Other
                                </label>
                                <input
                                    onChange={(e) => setData('drug_of_choice', e.target.value)}
                                    value={data.drug_of_choice}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                    type="text"
                                    placeholder=""
                                    id="drug_of_choice_other"
                                />
                            </div>
                        )}
                        <h5 className="mt-4 text-gray-700 font-semibold">Frequency</h5>
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.frequency === 'daily'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="daily"
                                value={data.frequency}
                                onClick={(e) => setData('frequency', e.target.id)}
                            >
                                Daily
                            </button>
                            <button
                                className={
                                    data.frequency === 'weekly'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="weekly"
                                value={data.frequency}
                                onClick={(e) => setData('frequency', e.target.id)}
                            >
                                Weekly
                            </button>
                            <button
                                className={
                                    data.frequency === 'monthly'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="monthly"
                                value={data.frequency}
                                onClick={(e) => setData('frequency', e.target.id)}
                            >
                                Monthly
                            </button>
                            <button
                                className={
                                    data.frequency === 'currently_abstaining'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="currently_abstaining"
                                value={data.frequency}
                                onClick={(e) => setData('frequency', e.target.id)}
                            >
                                Currently abstaining
                            </button>
                        </div>
                        {data.frequency === 'daily' && (
                            <>
                                <h5 className="mt-8 text-gray-700 font-semibold">Daily use details (use per day)</h5>
                                <div className="w-full flex flex-wrap mt-2 gap-2">
                                    <button
                                        className={
                                            data.daily_use_details === '1_to_2'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="1_to_2"
                                        value={data.daily_use_details}
                                        onClick={(e) => setData('daily_use_details', e.target.id)}
                                    >
                                        1 to 2
                                    </button>
                                    <button
                                        className={
                                            data.daily_use_details === '3_to_4'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="3_to_4"
                                        value={data.daily_use_details}
                                        onClick={(e) => setData('daily_use_details', e.target.id)}
                                    >
                                        3 to 4
                                    </button>
                                    <button
                                        className={
                                            data.daily_use_details === '5_or_more'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="5_or_more"
                                        value={data.daily_use_details}
                                        onClick={(e) => setData('daily_use_details', e.target.id)}
                                    >
                                        5 or more
                                    </button>
                                </div>
                            </>
                        )}
                        <h5 className="mt-8 text-gray-700 font-semibold">HIV Risk - Drug/Needle Use History</h5>
                        {errors.risk && (
                            <div className="bg-red-500 text-white font-bold rounded-full py-2 px-4 mt-2">
                                {errors.risk}
                            </div>
                        )}
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.risk === 'injection_drug_user'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="injection_drug_user"
                                value={data.risk}
                                onClick={(e) => setData('risk', e.target.id)}
                            >
                                IDU (Injection Drug User)
                            </button>
                            <button
                                className={
                                    data.risk === 'non_injection_drug_user'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="non_injection_drug_user"
                                value={data.risk}
                                onClick={(e) => setData('risk', e.target.id)}
                            >
                                Non-IDU
                            </button>
                        </div>
                        <h5 className="mt-8 text-gray-700 font-semibold">Add Alert (optional)</h5>
                        <div className="w-full flex flex-wrap mt-2 gap-2">
                            <button
                                className={
                                    data.alert === '1'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="1"
                                value={data.alert}
                                onClick={(e) =>
                                    data.alert == 1 ? setData('alert', null) : setData('alert', e.target.id)
                                }
                            >
                                Follow up with Supportive Services
                            </button>
                            <button
                                className={
                                    data.alert === '2'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="2"
                                value={data.alert}
                                onClick={(e) =>
                                    data.alert == 2 ? setData('alert', null) : setData('alert', e.target.id)
                                }
                            >
                                Follow up with Medical
                            </button>
                            <button
                                className={
                                    data.alert === '3'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="3"
                                value={data.alert}
                                onClick={(e) =>
                                    data.alert == 3 ? setData('alert', null) : setData('alert', e.target.id)
                                }
                            >
                                Follow up with Drop-In Center Manager
                            </button>
                            <button
                                className={
                                    data.alert === '4'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                }
                                type="button"
                                id="4"
                                value={data.alert}
                                onClick={(e) =>
                                    data.alert == 4 ? setData('alert', null) : setData('alert', e.target.id)
                                }
                            >
                                Participant on Temporary Ban (Contact Manager)
                            </button>
                            <div className="text-gray-700 w-full mt-4">
                                <label className="block mb-2" htmlFor="">
                                    Alert Message Details (optional)
                                </label>
                                <input
                                    onChange={(e) => setData('alert_message', e.target.value)}
                                    value={data.alert_message}
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                    type="text"
                                    placeholder=""
                                    id="alert_message"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row flex-wrap bg-customBlue md:space-x-4 space-y-4 md:space-y-0 items-center py-7 px-8 md:px-16">
                        <button
                            type="submit"
                            className="bg-white text-customBlue py-2 px-8 w-full justify-center md:w-auto font-bold flex flex-row items-center"
                            disabled={processing}
                        >
                            {processing && (
                                <div>
                                    <svg className="animate-spin mr-2" width="18px" height="18px" viewBox="0 0 120 120">
                                        <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g
                                                id="Group"
                                                transform="translate(9.000000, 9.000000)"
                                                stroke="#314EB9"
                                                strokeWidth="9"
                                            >
                                                <path
                                                    d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                    id="Path"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            )}
                            Done
                        </button>
                        <div className="flex-col md:flex-row items-center w-full md:w-auto bg-white rounded-full py-2 ">
                            <div className="flex flex-row items-center px-4">
                                <small className="pr-2">Step 4 of 4</small>
                                <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                                <div className="h-1 w-2 bg-gray-300"></div>
                                <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                            </div>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}
