import React from 'react'
import Layout from '../../Layout/Layout'
import { usePage } from '@inertiajs/react'

export default function Dashboard({
    user,
    participantCounts,
    exchangeCounts,
    narcanCounts,
    dropinCounts,
    worksOutSum,
}) {
    return (
        <>
            <Layout title="Dashboard">
                {/* <div>Currently Seeing: {user.is.currently_seeing}</div> */}
                <div className="flex flex-row gap-8 flex-grow flex-wrap w-full bg-white shadow-md p-8 md:p-16 mt-4 mb-8">
                    <div className="flex flex-row flex-wrap gap-8">
                        {/* start intake row */}
                        <div className="flex flex-row flex-wrap w-auto gap-2">
                            <h1 className="text-black text-4xl w-full font-semibold h-auto m-0">
                                Intakes
                            </h1>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Today
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {participantCounts.todayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Yesterday
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {participantCounts.yesterdayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        This month
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {participantCounts.monthTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        YTD
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {participantCounts.ytdTotal}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* start exchange row */}
                        <div className="flex flex-row flex-wrap w-auto gap-2">
                            <h1 className="text-black text-4xl w-full font-semibold h-auto m-0">
                                Exchanges
                            </h1>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Today
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {exchangeCounts.todayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Yesterday
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {exchangeCounts.yesterdayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        This month
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {exchangeCounts.monthTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        YTD
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {exchangeCounts.ytdTotal}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap gap-8">
                        {/* start drop in row */}
                        <div className="flex flex-row flex-wrap w-auto gap-2">
                            <h1 className="text-black text-4xl w-full font-semibold h-auto m-0">
                                Unique Drop In / Outreach Visits
                            </h1>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Today
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {dropinCounts.todayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Yesterday
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {dropinCounts.yesterdayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        This month
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {dropinCounts.monthTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        YTD
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {dropinCounts.ytdTotal}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* start narcan row */}
                        <div className="flex flex-row flex-wrap justify-start  w-auto gap-2">
                            <h1 className="text-black text-4xl w-full font-semibold">
                                Narcan/Naloxone
                            </h1>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Today
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {narcanCounts.todayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Yesterday
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {narcanCounts.yesterdayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        This month
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {narcanCounts.monthTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        YTD
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {narcanCounts.ytdTotal}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap gap-8">
                        {/* start works-in/out row */}
                        <div className="flex flex-row flex-wrap w-auto gap-2">
                            <h1 className="text-black text-4xl w-full font-semibold h-auto m-0">
                                Works Out
                            </h1>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Today
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {worksOutSum.todayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        Yesterday
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {worksOutSum.yesterdayTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        This month
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {worksOutSum.monthTotal}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col m-2 shadow-md h-24 w-24 lg:h-36 lg:w-36">
                                <div className="w-full bg-customYellow py-2">
                                    <h3 className="text-gray-800 text-md lg:text-xl text-center">
                                        YTD
                                    </h3>
                                </div>
                                <div className="h-full flex items-center">
                                    <p className="w-full text-4xl lg:text-5xl text-black text-center py-2">
                                        {worksOutSum.ytdTotal}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
