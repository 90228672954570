import React, { useEffect, useState } from 'react'
import Layout from '../../../Layout/Layout'
import { useForm, usePage, Link, router } from '@inertiajs/react'
import InputMask from 'react-input-mask'
import HistoryRow from '../../../Components/HistoryRow'
import QRScanner from '../QRScanner'
import { QrcodeIcon, UserAddIcon } from '@heroicons/react/solid'
import moment from 'moment'
import AddAlert from '../../../Components/Alerts/AddAlert'
import ShowAlerts from '../../../Components/Alerts/ShowAlerts'
import AlertHistoryRow from '../../../Components/Alerts/AlertHistoryRow'

const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream

export default function Edit({
    participant,
    exchanges,
    drop_ins,
    narcan,
    holistic,
    smokingkit,
    smokingKitCount,
    vaccineService,
    alerts,
}) {
    const { data, setData, put, processing, errors, post } = useForm({
        participant_uid: participant.participant_uid || '',
        first_name: participant.first_name || '',
        last_name: participant.last_name || '',
        dob: moment(participant.dob, 'YYYY/MM/DD').format('MM/DD/YYYY') || '',
        qr: participant.qr || null,
        sexual_orientation: participant.sexual_orientation || '',
        sexual_orientation_other: participant.sexual_orientation_other || '',
        gender_identity: participant.gender_identity || '',
        gender_identity_other: participant.gender_identity_other || '',
        assigned_sex: participant.assigned_sex || '',
        pronouns: participant.pronouns || '',
        pronouns_other: participant.pronouns_other || '',
        home_address: participant.home_address || '',
        home_address2: participant.home_address2 || '',
        state: participant.state || '',
        city: participant.city || '',
        zip_code: participant.zip_code || '',
        phone_number: participant.primary_phone || '',
        ethnicity: participant.ethnicity || '',
        hispdetds: participant.hispdetds || '',
        race_white: participant.race_white || false,
        race_blafrican: participant.race_blafrican || false,
        race_asian: participant.race_asian || false,
        asian_ethnicity_country: participant.asian_ethnicity_country || '',
        race_hawaisland: participant.race_hawaisland || false,
        race_indialaska: participant.race_indialaska || false,
        race_dk: participant.race_dk || false,
        race_declined: participant.race_declined || false,
        race_other: participant.race_someother || false,
        otherValue: participant.someother_string || '',
        insurdesc: participant.insurdesc || '',
        pprov_name: participant.pprov_name || '',
        medicaid_pprov_name: participant.medicaid_pprov_name || '',
        risk: participant.risk || '',
        drug_of_choice: participant.drug_of_choice || '',
        opioid_details: participant.opioid_details || '',
        cocaine_details: participant.cocaine_details || '',
        frequency: participant.frequency || '',
        daily_use_details: participant.daily_use_details || '',
        alert: participant.alert || '',
        alert_message: participant.alert_message || '',
        alert_user_id: participant.alert_user_id || '',
    })
    const [showDetails, setShowDetails] = useState('')
    const [showHistory, setShowHistory] = useState('')
    const [showQr, setShowQr] = useState(false)
    const [qrValue, setQrValue] = useState(data.qr)
    const [alertText, setAlertText] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showDrugOfChoiceOther, setShowDrugOfChoiceOther] = useState(false)
    const { user } = usePage().props
    const [showHideConfirmation, setShowHideConfirmation] = useState(false)

    useEffect(() => {
        if (data.pronouns !== 'Other') {
            setData('pronouns_other', '')
        }
    }, [data.pronouns])

    useEffect(() => {
        if (
            data.drug_of_choice == 'opioids' ||
            data.drug_of_choice == 'cocaine' ||
            data.drug_of_choice == 'marijuana' ||
            data.drug_of_choice == 'alchohol' ||
            data.drug_of_choice == 'methamphetamine' ||
            data.drug_of_choice == 'benzodiazepines' ||
            data.drug_of_choice == 'k2' ||
            data.drug_of_choice == 'speedball'
        ) {
            setShowDrugOfChoiceOther(false)
        } else {
            setShowDrugOfChoiceOther(true)
        }
    }, [])
    // useEffect(() => {
    //     setOptions(
    //         sexual_orientation.reduce(({ label, value }) => [
    //             {
    //                 label: label,
    //                 value: value,
    //             },
    //         ])
    //     )
    // }, [options])
    // function setSelectedReferrals(opt) {
    //     setData({
    //         ...data,
    //         sexual_orientation: opt,
    //     })
    // }
    let orientationOptions = [
        { label: 'Straight / Heterosexual', value: 'straight/heterosexual' },
        { label: 'WHCP', value: 'WHCP' },
    ]
    function handleSubmit(e) {
        e.preventDefault()
        put(route('participants.update', participant.id), data)
    }

    useEffect(() => {
        const scanQr = qrValue
        setData((data) => ({
            ...data,
            qr: scanQr,
        }))
    }, [qrValue])

    useEffect(() => {
        if (data.alert == 1)
            setAlertText(
                'This participant should follow up with Supportive Services'
            )
        if (data.alert == 2)
            setAlertText('This participant should follow up with Medical')

        if (data.alert == 3)
            setAlertText(
                'This participant should follow up with Drop-In Center Manager'
            )
        if (data.alert == 4)
            setAlertText(
                'This participant is on Temporary Ban (Contact Manager)'
            )
        if (data.alert) setShowAlert(true)
    })

    function clearOldAlert(e) {
        e.preventDefault()
        setData((data) => ({
            ...data,
            alert: null,
            alert_message: null,
        }))
        setShowAlert(false)
        put(route('participants.clearAlert', participant.id), data)
    }

    function selectParticipant(e, id) {
        e.preventDefault()
        router.visit(route('participants.choose', id), {
            method: 'put',
        })
    }

    function handleHideParticipant(e) {
        e.preventDefault()
        router.post(route('participants.hide', participant.id))
    }

    const activeAlerts = alerts.filter((alert) => alert.is_active)
    const activeAlertsCount = activeAlerts.length + (data.alert ? 1 : 0)
    return (
        <>
            <Layout>
                <form
                    onSubmit={handleSubmit}
                    className="md:md-0 relative mb-8 mt-4 flex w-full flex-col flex-wrap bg-white p-8 shadow-md md:p-16"
                >
                    <div className="flex flex-row items-center gap-8 mb-4">
                        <h5 className="inline text-2xl font-semibold text-gray-700">
                            Editing{' '}
                            {participant.first_name +
                                ' ' +
                                participant.last_name}
                        </h5>
                        {activeAlertsCount > 0 ? (
                            <div className="text-xs px-4 py-1 border border-customRed text-customRed rounded-full">
                                <p>{activeAlertsCount} Active Alerts</p>
                            </div>
                        ) : null}
                    </div>
                    {showAlert ? (
                        <>
                            {alertText && (
                                <div className="mb-4 flex w-full flex-row items-start justify-start gap-2 shadow-sm rounded-md p-4">
                                    <div className="rounded-md bg-red-200 p-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-8 w-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="flex flex-col gap-0">
                                        <h2 className="font-semibold">
                                            {alertText}
                                        </h2>
                                        <h2 className="mb-2 text-md">
                                            {data.alert_message}
                                        </h2>
                                        <div className="flex flex-row items-center gap-4">
                                            <h3 className="text-xs text-gray-600">
                                                Alert added by {data.alert_user_id}
                                            </h3>
                                        </div>
                                        <button
                                            className="w-auto self-start py-1 hover:bg-gray-200  transition-colors px-4 mt-4 text-sm text-customRed bg-gray-100"
                                            onClick={clearOldAlert}
                                        >
                                            Clear Alert
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : null}
                    {activeAlerts.length > 0 ? (
                        <ShowAlerts alerts={activeAlerts} />
                    ) : null}
                    <div className="flex flex-row flex-wrap">
                        <h5 className="w-full mt-4 mb-4 font-semibold text-lg text-gray-700">
                            Basic Information:
                        </h5>
                        <div className="w-full text-gray-700 md:w-6/12 relative">
                            {errors.participant_uid && (
                                <div className="mb-4 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                    {errors.participant_uid}
                                </div>
                            )}
                            <label className="mb-2 block" htmlFor="chartNumber">
                                Participant ID
                            </label>
                            <div className="relative">
                                <input
                                    disabled={!user.can.create_location}
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    id="participant_uid"
                                    value={data.participant_uid}
                                    onChange={(e) =>
                                        setData(
                                            'participant_uid',
                                            e.target.value
                                        )
                                    }
                                />
                                {!user.can.create_location && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="absolute right-4 top-2 h-6  w-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div className="mt-4 flex w-full flex-col md:mt-0 md:w-6/12 md:pl-2">
                            {errors.dob && (
                                <div className="mt-2 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                    {errors.dob}
                                </div>
                            )}
                            <label className="mb-2 block" htmlFor="datePicker">
                                Participant Date of Birth
                            </label>
                            <InputMask
                                className="h-10 w-full border border-gray-400 pl-2 text-gray-700"
                                mask="99/99/9999"
                                value={data.dob}
                                onChange={(e) => setData('dob', e.target.value)}
                                placeholder="MM/DD/YYYY"
                            ></InputMask>
                        </div>
                    </div>
                    <div className="flex w-full flex-row flex-wrap">
                        <div className="mt-4 w-full text-gray-700 md:mt-8 md:w-1/2 md:pr-2 ">
                            {errors.first_name && (
                                <div className="mt-2 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                    {errors.first_name}
                                </div>
                            )}
                            <label className="mb-2 block" htmlFor="first_name">
                                Participant First Name
                            </label>
                            <input
                                className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                type="text"
                                placeholder=""
                                id="first_name"
                                value={data.first_name}
                                onChange={(e) =>
                                    setData('first_name', e.target.value)
                                }
                            />
                        </div>
                        <div className="mt-4 w-full text-gray-700 md:mt-8 md:w-1/2 md:pl-2">
                            {errors.last_name && (
                                <div className="mt-2 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                    {errors.last_name}
                                </div>
                            )}
                            <label className="mb-2 block" htmlFor="lastName">
                                Participant Last Name
                            </label>
                            <input
                                className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                type="text"
                                placeholder=""
                                id="last_name"
                                value={data.last_name}
                                onChange={(e) =>
                                    setData('last_name', e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="flex w-full flex-row flex-wrap">
                        <div className="mt-4 w-full text-gray-700 md:mt-8 md:w-1/2">
                            {errors.qr && (
                                <div className="mt-2 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                    {errors.qr}
                                </div>
                            )}
                            <p>QR</p>
                            <div className="relative">
                                <input
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    id="QR"
                                    onChange={(e) =>
                                        setData('qr', e.target.value)
                                    }
                                    value={data.qr}
                                />
                                {!isIOS && (
                                    <label
                                        className="absolute hidden lg:block top-2 right-2"
                                        htmlFor="QR"
                                    >
                                        <div className="flex flex-row items-center w-auto gap-2 p-1 text-sm bg-gray-100 rounded-md hover:bg-gray-200 ">
                                            <p>Scan</p>
                                            <QrcodeIcon className="h-4 w-4" />
                                        </div>
                                    </label>
                                )}
                                {isIOS && (
                                    <div
                                        className="relative mt-4 flex flex-row items-center justify-center gap-2 bg-gray-100 p-1 hover:bg-gray-200"
                                        onClick={() => setShowQr(true)}
                                    >
                                        <p>Scan</p>
                                        <QrcodeIcon className="h-4 w-4" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <AddAlert id={participant.id} />
                    <div className="flex flex-row flex-wrap items-center mt-12">
                        <h3 className="w-full mb-2 font-semibold text-lg text-gray-700">
                            More Edit Options:
                        </h3>
                        <div className="flex flex-row flex-wrap space-y-2">
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('sexual_orientation'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'sexual_orientation'
                                        ? 'mr-2 mt-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 mt-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Sexual Orientation
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('gender_identity'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'gender_identity'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Gender Identity
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('assigned_sex'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'assigned_sex'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Sex Assigned at birth
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('pronouns'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'pronouns'
                                        ? 'bg-white font-semibold underline px-2 py-4 mr-2 rounded-md'
                                        : 'bg-white shadow-md px-2 py-4 mr-2 rounded-md hover:bg-gray-100'
                                }
                            >
                                Pronouns
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('address_information'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'address_information'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Address and contact information
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('demographic_information'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'demographic_information'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Demographic information
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('insurance_information'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'insurance_information'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Insurance information
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowDetails('drug_usage_information'),
                                        setShowHistory('')
                                }}
                                className={
                                    showDetails === 'drug_usage_information'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Drug usage information
                            </button>
                        </div>
                    </div>

                    {showDetails === 'pronouns' && (
                        <>
                            <h5 className="mt-8 font-semibold text-gray-700">
                                Pronouns
                            </h5>
                            <div className="flex w-full flex-wrap gap-2">
                                <div className="mt-2 text-gray-700 w-full">
                                    <label
                                        className="mb-2 block hidden"
                                        htmlFor="pronouns"
                                    >
                                        Pronouns
                                    </label>
                                    {errors.pronouns && (
                                        <div className="mt-2 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                            {errors.pronouns}
                                        </div>
                                    )}
                                    <div className="mt-2 flex w-full flex-wrap gap-2">
                                        <button
                                            className={
                                                data.pronouns === 'She/Her'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                            }
                                            type="button"
                                            id="She/Her"
                                            value={data.pronouns}
                                            onClick={(e) =>
                                                setData('pronouns', e.target.id)
                                            }
                                        >
                                            She/Her
                                        </button>
                                        <button
                                            className={
                                                data.pronouns === 'He/Him'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                            }
                                            type="button"
                                            id="He/Him"
                                            value={data.pronouns}
                                            onClick={(e) =>
                                                setData('pronouns', e.target.id)
                                            }
                                        >
                                            He/Him
                                        </button>
                                        <button
                                            className={
                                                data.pronouns === 'They/Them'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                            }
                                            type="button"
                                            id="They/Them"
                                            value={data.pronouns}
                                            onClick={(e) =>
                                                setData('pronouns', e.target.id)
                                            }
                                        >
                                            They/Them
                                        </button>
                                        <button
                                            className={
                                                data.pronouns === 'Ze/Zim'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                            }
                                            type="button"
                                            id="Ze/Zim"
                                            value={data.pronouns}
                                            onClick={(e) =>
                                                setData('pronouns', e.target.id)
                                            }
                                        >
                                            Ze/Zim
                                        </button>
                                        <button
                                            className={
                                                data.pronouns ===
                                                    'No Pronouns/Use my name'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                            }
                                            type="button"
                                            id="No Pronouns/Use my name"
                                            value={data.pronouns}
                                            onClick={(e) =>
                                                setData('pronouns', e.target.id)
                                            }
                                        >
                                            No Pronouns/Use my name
                                        </button>
                                        <button
                                            className={
                                                data.pronouns === 'Other'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                            }
                                            type="button"
                                            id="Other"
                                            value={data.pronouns}
                                            onClick={(e) =>
                                                setData('pronouns', e.target.id)
                                            }
                                        >
                                            Other
                                        </button>
                                    </div>
                                    {data.pronouns === 'Other' && (
                                        <div className="text-gray-700 w-full mt-4">
                                            <label
                                                className="block mb-2"
                                                htmlFor=""
                                            >
                                                Other Pronouns Details:
                                            </label>
                                            {errors.pronouns_other && (
                                                <div className="bg-customRed text-white font-bold rounded-full py-2 px-4 mt-2">
                                                    {errors.pronouns_other}
                                                </div>
                                            )}
                                            <input
                                                onChange={(e) =>
                                                    setData(
                                                        'pronouns_other',
                                                        e.target.value
                                                    )
                                                }
                                                value={data.pronouns_other}
                                                className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg"
                                                type="text"
                                                placeholder=""
                                                id="pronouns_other"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {showDetails === 'sexual_orientation' && (
                        <div className="mt-8 flex w-full flex-wrap gap-2">
                            <button
                                className={
                                    data.sexual_orientation ===
                                        'Straight or Heterosexual'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4'
                                }
                                type="button"
                                id="Straight or Heterosexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Straight / Heterosexual
                            </button>
                            <button
                                className={
                                    // TODO: is there a better way to do this?
                                    data.sexual_orientation ===
                                        'Gay or Lesbian' ||
                                        data.sexual_orientation === 'Gay' ||
                                        data.sexual_orientation === 'Lesbian'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Gay or Lesbian"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Gay / Lesbian
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Bisexual'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Bisexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Bisexual
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Queer'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Queer"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Queer
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Pansexual'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Pansexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Pansexual
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Asexual'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Asexual"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Asexual
                            </button>
                            <button
                                className={
                                    data.sexual_orientation ===
                                        'Not Sure/Questioning'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4'
                                }
                                type="button"
                                id="Not Sure/Questioning"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Questioning / Not Sure
                            </button>
                            <button
                                disabled
                                className={
                                    data.sexual_orientation ===
                                        'Sexual orientation not listed'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4'
                                }
                                type="button"
                                id="Sexual orientation not listed"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Sexual orientation not listed
                            </button>
                            <button
                                className={
                                    data.sexual_orientation === 'Other'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Other"
                                value={data.sexual_orientation}
                                onClick={(e) =>
                                    setData('sexual_orientation', e.target.id)
                                }
                            >
                                Other
                            </button>
                            {data.sexual_orientation === 'Other' && (
                                <div className="flex w-full flex-col md:w-4/12">
                                    <label
                                        className="mb-2 mt-4 block"
                                        htmlFor="sexual_orientation_other"
                                    >
                                        Other Sexual Orientation:
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setData(
                                                'sexual_orientation_other',
                                                e.target.value
                                            )
                                        }
                                        value={data.sexual_orientation_other}
                                        className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                        type="text"
                                        placeholder=""
                                        id="sexual_orientation_other"
                                    />
                                    {errors.sexual_orientation_other && (
                                        <div className="mt-2 rounded-full bg-customRed px-4 py-2 text-sm font-bold text-white">
                                            {errors.sexual_orientation_other}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {showDetails === 'gender_identity' && (
                        <div className="mt-8 flex w-full flex-wrap gap-2">
                            <button
                                className={
                                    data.gender_identity === 'Woman/Girl'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Woman/Girl"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Woman or Girl
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Transgender Woman/Girl'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4'
                                }
                                type="button"
                                id="Transgender Woman/Girl"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Transgender Woman or girl
                            </button>
                            <button
                                className={
                                    data.gender_identity === 'Man/Boy'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Man/Boy"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Man or Boy
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Transgender Man/Boy'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4'
                                }
                                type="button"
                                id="Transgender Man/Boy"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Transgender Man or Boy
                            </button>
                            <button
                                className={
                                    data.gender_identity === 'Non-Binary person'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Non-Binary person"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Non-Binary Person
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Gender Non-Conforming person'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4'
                                }
                                type="button"
                                id="Gender Non-Conforming person"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Gender nonconforming person
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Not Sure/Questioning'
                                        ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4'
                                        : 'bg-gray-200 px-4 py-2 w-full md:w-1/4'
                                }
                                type="button"
                                id="Not Sure/Questioning"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Questioning / Not Sure
                            </button>
                            <button
                                disabled
                                className={
                                    data.gender_identity === 'Gender not listed'
                                        ? 'bg-customBlue w-full  px-4 py-2 text-white disabled:opacity-50 md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 disabled:opacity-50 md:w-1/4'
                                }
                                type="button"
                                id="Gender not listed"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Gender not listed
                            </button>
                            <button
                                className={
                                    data.gender_identity === 'Other'
                                        ? 'bg-customBlue w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Other"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Other
                            </button>
                            <button
                                className={
                                    data.gender_identity ===
                                        'Chose not to respond'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Chose not to respond"
                                value={data.gender_identity}
                                onClick={(e) =>
                                    setData('gender_identity', e.target.id)
                                }
                            >
                                Chose not to respond
                            </button>
                            <div className="w-full">
                                {data.gender_identity === 'Other' && (
                                    <div className="flex w-full flex-col md:w-4/12">
                                        <label
                                            className="mb-2 mt-4 block"
                                            htmlFor="gender_identity_other"
                                        >
                                            Other Gender Identity:
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                setData(
                                                    'gender_identity_other',
                                                    e.target.value
                                                )
                                            }
                                            value={data.gender_identity_other}
                                            className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                            type="text"
                                            placeholder=""
                                            id="gender_identity_other"
                                        />
                                        {errors.gender_identity_other && (
                                            <div className="mt-2 rounded-full bg-customRed px-4 py-2 text-sm font-bold text-white">
                                                {errors.gender_identity_other}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {showDetails === 'assigned_sex' && (
                        <div className="mt-8 flex w-full flex-wrap gap-2">
                            <button
                                className={
                                    data.assigned_sex === 'Male'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Male"
                                value={data.assigned_sex}
                                onClick={(e) =>
                                    setData('assigned_sex', e.target.id)
                                }
                            >
                                Male
                            </button>
                            <button
                                className={
                                    data.assigned_sex === 'Female'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Female"
                                value={data.assigned_sex}
                                onClick={(e) =>
                                    setData('assigned_sex', e.target.id)
                                }
                            >
                                Female
                            </button>
                            <button
                                className={
                                    data.assigned_sex === 'Intersex'
                                        ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4'
                                        : 'w-full bg-gray-200 px-4 py-2 md:w-1/4'
                                }
                                type="button"
                                id="Intersex"
                                value={data.assigned_sex}
                                onClick={(e) =>
                                    setData('assigned_sex', e.target.id)
                                }
                            >
                                Intersex
                            </button>
                        </div>
                    )}
                    {showDetails === 'address_information' && (
                        <div className="mt-2 flex w-full flex-wrap">
                            <div className="mt-4 w-full text-gray-700">
                                <label className="mb-2 block" htmlFor="">
                                    Address Line 1
                                </label>
                                <input
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    id="home_address"
                                    value={data.home_address}
                                    onChange={(e) =>
                                        setData('home_address', e.target.value)
                                    }
                                />
                            </div>
                            <div className="flex w-full flex-row space-x-2">
                                <div className="mt-4 w-full text-gray-700">
                                    <label className="mb-2 block" htmlFor="">
                                        Address Line 2
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setData(
                                                'home_address2',
                                                e.target.value
                                            )
                                        }
                                        value={data.home_address2}
                                        className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                        type="text"
                                        placeholder=""
                                        id="home_address2"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row space-x-2">
                                <div className="mt-4 text-gray-700 w-12/12 md:w-1/3">
                                    <label
                                        className="block mb-2"
                                        htmlFor="city"
                                    >
                                        City
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setData('city', e.target.value)
                                        }
                                        value={data.city}
                                        className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                        type="text"
                                        placeholder=""
                                        id="city"
                                    />
                                </div>
                                <div className="w-1/2 mt-4 text-gray-700 md:w-1/3">
                                    <label
                                        className="block mb-2"
                                        htmlFor="state"
                                    >
                                        State
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setData('state', e.target.value)
                                        }
                                        value={data.state}
                                        className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                        type="text"
                                        placeholder=""
                                        id="state"
                                    />
                                </div>
                                <div className="w-1/2 mt-4 text-gray-700 md:w-1/3">
                                    <label
                                        className="block mb-2"
                                        htmlFor="zip_code"
                                    >
                                        Zip Code
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setData('zip_code', e.target.value)
                                        }
                                        value={data.zip_code}
                                        className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                        type="text"
                                        placeholder=""
                                        id="zip_code"
                                    />
                                </div>
                            </div>
                            <div className="w-full mt-4 text-gray-700">
                                <label
                                    className="block mb-2"
                                    htmlFor="phone_number"
                                >
                                    Primary Phone Number
                                </label>
                                <input
                                    onChange={(e) =>
                                        setData('phone_number', e.target.value)
                                    }
                                    value={data.phone_number}
                                    className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                    type="text"
                                    placeholder=""
                                    id="phone_number"
                                />
                            </div>
                        </div>
                    )}

                    {showDetails === 'demographic_information' && (
                        <>
                            <div className="flex flex-row flex-wrap w-full mt-2">
                                <h5 className="mt-8 font-semibold text-gray-700">
                                    Ethnicity
                                </h5>
                                <div className="flex flex-wrap w-full gap-2 mt-2">
                                    <button
                                        className={
                                            data.ethnicity === 'Hispanic'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                        }
                                        type="button"
                                        id="Hispanic"
                                        value={data.ethnicity}
                                        onClick={(e) =>
                                            setData('ethnicity', e.target.id)
                                        }
                                    >
                                        Hispanic
                                    </button>
                                    <button
                                        className={
                                            data.ethnicity === 'Non-Hispanic'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                        }
                                        type="button"
                                        id="Non-Hispanic"
                                        value={data.ethnicity}
                                        onClick={(e) =>
                                            setData((data) => ({
                                                ...data,
                                                ethnicity: e.target.id,
                                                hispdetds: '',
                                            }))
                                        }
                                    >
                                        Non-hispanic
                                    </button>
                                </div>
                            </div>
                            {data.ethnicity === 'Hispanic' && (
                                <>
                                    <h5 className="mt-8 font-semibold text-gray-700 ">
                                        Hispanic Ethnicity Details
                                    </h5>
                                    <div className="flex flex-wrap w-full gap-2 mt-2">
                                        <button
                                            className={
                                                data.hispdetds ===
                                                    'Puerto Rican'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Puerto Rican"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Puerto Rican
                                        </button>
                                        <button
                                            className={
                                                data.hispdetds === 'Dominican'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                            }
                                            type="button"
                                            id="Dominican"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Dominican
                                        </button>
                                        <button
                                            className={
                                                data.hispdetds === 'Cuban'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                            }
                                            type="button"
                                            id="Cuban"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Cuban
                                        </button>
                                        <button
                                            className={
                                                data.hispdetds ===
                                                    'South American'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="South American"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            South American
                                        </button>
                                        <button
                                            className={
                                                data.hispdetds ===
                                                    'Central American'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Central American"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Central American
                                        </button>
                                        <button
                                            className={
                                                data.hispdetds ===
                                                    'Mexican/Mexican-American/Chicano(a)'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Mexican/Mexican-American/Chicano(a)"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Mexican/Mexican-American/Chicano(a)
                                        </button>
                                        <button
                                            className={
                                                data.hispdetds === 'Spanish'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                            }
                                            type="button"
                                            id="Spanish"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Spanish
                                        </button>
                                        <button
                                            className={
                                                data.hispdetds ===
                                                    'Other Hispanic, Latino/a or Spanish'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Other Hispanic, Latino/a or Spanish"
                                            value={data.hispdetds}
                                            onClick={(e) =>
                                                setData(
                                                    'hispdetds',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Other Hispanic
                                        </button>
                                    </div>
                                </>
                            )}
                            <h5 className="mt-4 font-semibold text-gray-700 ">
                                Race (check all that apply)
                            </h5>
                            {errors.race && (
                                <div className="mt-2 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                    {errors.race}
                                </div>
                            )}
                            <div className="mt-2 flex w-full flex-wrap gap-2">
                                <button
                                    className={
                                        data.race_blafrican
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="race_blafrican"
                                    value={data.race_blafrican}
                                    onClick={(e) =>
                                        setData(
                                            'race_blafrican',
                                            !data.race_blafrican
                                        )
                                    }
                                >
                                    Black
                                </button>
                                <button
                                    className={
                                        data.race_white
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="race_white"
                                    value={data.race_white}
                                    onClick={(e) =>
                                        setData('race_white', !data.race_white)
                                    }
                                >
                                    White
                                </button>
                                <button
                                    className={
                                        data.race_asian
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="race_asian"
                                    value={data.race_asian}
                                    onClick={(e) =>
                                        setData('race_asian', !data.race_asian)
                                    }
                                >
                                    Asian
                                </button>
                                <button
                                    className={
                                        data.race_hawaisland
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="race_hawaisland"
                                    value={data.race_hawaisland}
                                    onClick={(e) =>
                                        setData(
                                            'race_hawaisland',
                                            !data.race_hawaisland
                                        )
                                    }
                                >
                                    Native Hawaiian / Pacific Islander
                                </button>
                                <button
                                    className={
                                        data.race_indialaska
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="race_indialaska"
                                    value={data.race_indialaska}
                                    onClick={(e) =>
                                        setData(
                                            'race_indialaska',
                                            !data.race_indialaska
                                        )
                                    }
                                >
                                    American Indian / Alaskan Native
                                </button>
                                <button
                                    className={
                                        data.race_dk
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="1"
                                    value={data.race_dk}
                                    onClick={(e) =>
                                        setData('race_dk', !data.race_dk)
                                    }
                                >
                                    Unknown
                                </button>
                                <button
                                    className={
                                        data.race_declined
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="declined"
                                    value={data.race_declined}
                                    onClick={(e) =>
                                        setData(
                                            'race_declined',
                                            !data.race_declined
                                        )
                                    }
                                >
                                    Declined
                                </button>
                                <button
                                    className={
                                        data.race_other
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="race_other"
                                    value={data.race_other}
                                    onClick={(e) =>
                                        setData('race_other', !data.race_other)
                                    }
                                >
                                    Other
                                </button>
                                {data.race_other && (
                                    <input
                                        onChange={(e) =>
                                            setData(
                                                'otherValue',
                                                e.target.value
                                            )
                                        }
                                        value={data.otherValue}
                                        className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                        type="text"
                                        placeholder="Other"
                                        id="otherValue"
                                    />
                                )}
                                {data.race_asian && (
                                    <div className="w-full mt-4">
                                        {errors.asian_ethnicity_country && (
                                            <div className="bg-customRed text-white font-bold rounded-full py-2 px-4 my-2 self-start">
                                                {errors.asian_ethnicity_country}
                                            </div>
                                        )}
                                        <input
                                            onChange={(e) =>
                                                setData(
                                                    'asian_ethnicity_country',
                                                    e.target.value
                                                )
                                            }
                                            value={data.asian_ethnicity_country}
                                            className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg  "
                                            type="text"
                                            placeholder="Asian Ethnicity/Country"
                                            id="asian_ethnicity_country"
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {showDetails === 'insurance_information' && (
                        <>
                            <div className="flex flex-row flex-wrap mt-2">
                                <h5 className="mt-8 font-semibold text-gray-700">
                                    Insurance Status
                                </h5>
                                <div className="flex flex-wrap w-full gap-2 mt-2">
                                    <button
                                        className={
                                            data.insurdesc === 'Known/Specify'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                        }
                                        type="button"
                                        id="Known/Specify"
                                        value={data.insurdesc}
                                        onClick={(e) =>
                                            setData('insurdesc', e.target.id)
                                        }
                                    >
                                        Known
                                    </button>
                                    <button
                                        className={
                                            data.insurdesc ===
                                                'Unknown/Unreported'
                                                ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                        }
                                        type="button"
                                        id="Unknown/Unreported"
                                        value={data.insurdesc}
                                        onClick={(e) =>
                                            setData('insurdesc', e.target.id)
                                        }
                                    >
                                        Unknown / Unreported
                                    </button>
                                    <button
                                        className={
                                            data.insurdesc === 'No Insurance'
                                                ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                        }
                                        type="button"
                                        id="No Insurance"
                                        value={data.insurdesc}
                                        onClick={(e) =>
                                            setData('insurdesc', e.target.id)
                                        }
                                    >
                                        No Insurance / Self-Pay
                                    </button>
                                </div>
                            </div>
                            <h5 className="mt-4 font-semibold text-gray-700 ">
                                Insurance Carrier
                            </h5>
                            <div className="flex flex-wrap w-full gap-2 mt-2">
                                <button
                                    className={
                                        data.pprov_name.includes(
                                            'Medicaid fee-for-service'
                                        )
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="Medicaid fee-for-service"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Medicaid (fee-for-service)
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes(
                                            'Medicaid (MCO)'
                                        )
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="Medicaid (MCO)"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Medicaid (MCO)
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes('Medicare')
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="Medicare"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Medicare
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes(
                                            'Private - Employer'
                                        )
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="Private - Employer"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Private - Employer
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes(
                                            'Private - Individual'
                                        )
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="Private - Individual"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Private - Individual
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes(
                                            'Uninsured / Possibly Eligible'
                                        )
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="Uninsured / Possibly Eligible"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Uninsured - Possibly Eligible
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes(
                                            'Uninsured / Not Eligible'
                                        )
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="Uninsured / Not Eligible"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Uninsured - Not Eligible
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes(
                                            'No Insurance / Self Pay'
                                        )
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="No Insurance / Self Pay"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    No Insurance / Self-Pay
                                </button>
                                <button
                                    className={
                                        data.pprov_name.includes('declined')
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="declined"
                                    value={data.pprov_name}
                                    onClick={(e) =>
                                        setData('pprov_name', e.target.id)
                                    }
                                >
                                    Declined
                                </button>
                            </div>
                            {data.pprov_name.includes('Medicaid (MCO)') && (
                                <>
                                    <h5 className="mt-8 font-semibold text-gray-700">
                                        Medicaid Carrier
                                    </h5>
                                    <div className="flex flex-wrap w-full gap-2 mt-2">
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'Healthfirst PHSP'
                                                )
                                                    ? 'bg-customBlue text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):Healthfirst PHSP"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Healthfirst PHSP
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'Amida Care'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):Amida Care"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Amida Care
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'MetroPlus Health Plan'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):MetroPlus Health Plan"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            MetroPlus Health Plan
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'Affinity Health Plan'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):Affinity Health Plan"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Affinity Health Plan
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'EmblemHealth (HIP)'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):EmblemHealth (HIP)"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            EmblemHealth (HIP)
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'Empire Blue Cross Blue Shield Health Plus'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):Empire Blue Cross Blue Shield Health Plus"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Empire Blue Cross Blue Shield Health
                                            Plus
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'Fidelis Care New York'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):Fidelis Care New York"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Fidelis Care New York
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'United Healthcare Community Plan'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):United Healthcare Community Plan"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            United Healthcare Community Plan
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'WellCare of New York'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):WellCare of New York"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            WellCare of New York
                                        </button>
                                        <button
                                            className={
                                                data.pprov_name.includes(
                                                    'Other/unknown'
                                                )
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="Medicaid (MCO):Other/unknown"
                                            value={data.pprov_name}
                                            onClick={(e) =>
                                                setData(
                                                    'pprov_name',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Other/unknown
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {showDetails === 'drug_usage_information' && (
                        <>
                            <h5 className="mt-8 font-semibold text-gray-700">
                                Drug of choice
                            </h5>
                            <div className="flex flex-wrap w-full gap-2 mt-2">
                                <button
                                    className={
                                        data.drug_of_choice === 'opioids'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="opioids"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData((data) => ({
                                            ...data,
                                            drug_of_choice: e.target.id,
                                            cocaine_details: '',
                                        })),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    Opioids
                                </button>
                                <button
                                    className={
                                        data.drug_of_choice === 'cocaine'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="cocaine"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData((data) => ({
                                            ...data,
                                            drug_of_choice: e.target.id,
                                            opioid_details: '',
                                        })),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    Cocaine
                                </button>
                                <button
                                    className={
                                        data.drug_of_choice === 'marijuana'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="marijuana"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData('drug_of_choice', e.target.id),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    Marijuana
                                </button>
                                <button
                                    className={
                                        data.drug_of_choice === 'alcohol'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="alcohol"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData('drug_of_choice', e.target.id),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    Alcohol
                                </button>
                                <button
                                    className={
                                        data.drug_of_choice ===
                                            'methamphetamine'
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="methamphetamine"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData('drug_of_choice', e.target.id),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    Methamphetamine
                                </button>
                                <button
                                    className={
                                        data.drug_of_choice ===
                                            'benzodiazepines'
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="benzodiazepines"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData('drug_of_choice', e.target.id),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    Benzodiazepines
                                </button>
                                <button
                                    className={
                                        data.drug_of_choice === 'K2'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="K2"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData('drug_of_choice', e.target.id),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    K2
                                </button>
                                <button
                                    className={
                                        data.drug_of_choice === 'speedball'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="speedball"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setData('drug_of_choice', e.target.id),
                                            setShowDrugOfChoiceOther(false)
                                    }}
                                >
                                    Speedball
                                </button>
                                <button
                                    className={
                                        showDrugOfChoiceOther == true
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="other"
                                    value={data.drug_of_choice}
                                    onClick={(e) => {
                                        setShowDrugOfChoiceOther(true),
                                            setData('drug_of_choice', '')
                                    }}
                                >
                                    Other
                                </button>
                            </div>
                            {showDrugOfChoiceOther == true && (
                                <div className="mt-4 w-full max-w-lg text-gray-700">
                                    <label className="mb-2 block" htmlFor="">
                                        Drug of Choice Other
                                    </label>
                                    <input
                                        onChange={(e) =>
                                            setData(
                                                'drug_of_choice',
                                                e.target.value
                                            )
                                        }
                                        value={data.drug_of_choice}
                                        className="h-10 w-full rounded-lg border px-3 text-base placeholder-gray-600"
                                        type="text"
                                        placeholder=""
                                        id="drug_of_choice_other"
                                    />
                                </div>
                            )}
                            {data.drug_of_choice === 'opioids' && (
                                <>
                                    <h5 className="mt-8 font-semibold text-gray-700">
                                        Opioid use details
                                    </h5>
                                    <div className="flex flex-wrap w-full gap-2 mt-2">
                                        <button
                                            className={
                                                data.opioid_details ===
                                                    'heroin/fentanyl'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="heroin/fentanyl"
                                            value={data.opioid_details}
                                            onClick={(e) =>
                                                setData(
                                                    'opioid_details',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Heroin/Fentanyl
                                        </button>
                                        <button
                                            className={
                                                data.opioid_details ===
                                                    'other_opioids'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="other_opioids"
                                            value={data.opioid_details}
                                            onClick={(e) =>
                                                setData(
                                                    'opioid_details',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Other Opioids (pills)
                                        </button>
                                    </div>
                                </>
                            )}
                            {data.drug_of_choice === 'cocaine' && (
                                <>
                                    <h5 className="mt-8 font-semibold text-gray-700">
                                        Cocaine use details
                                    </h5>
                                    <div className="flex flex-wrap w-full gap-2 mt-2">
                                        <button
                                            className={
                                                data.cocaine_details ===
                                                    'powder'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="powder"
                                            value={data.cocaine_details}
                                            onClick={(e) =>
                                                setData(
                                                    'cocaine_details',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Powder
                                        </button>
                                        <button
                                            className={
                                                data.cocaine_details === 'crack'
                                                    ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                                    : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                            }
                                            type="button"
                                            id="crack"
                                            value={data.cocaine_details}
                                            onClick={(e) =>
                                                setData(
                                                    'cocaine_details',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            Crack
                                        </button>
                                    </div>
                                </>
                            )}
                            <h5 className="mt-8 font-semibold text-gray-700">
                                Frequency
                            </h5>
                            <div className="flex flex-wrap w-full gap-2 mt-2">
                                <button
                                    className={
                                        data.frequency === 'daily'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="daily"
                                    value={data.frequency}
                                    onClick={(e) =>
                                        setData('frequency', e.target.id)
                                    }
                                >
                                    Daily
                                </button>
                                <button
                                    className={
                                        data.frequency === 'weekly'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="weekly"
                                    value={data.frequency}
                                    onClick={(e) =>
                                        setData('frequency', e.target.id)
                                    }
                                >
                                    Weekly
                                </button>
                                <button
                                    className={
                                        data.frequency === 'monthly'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="monthly"
                                    value={data.frequency}
                                    onClick={(e) =>
                                        setData('frequency', e.target.id)
                                    }
                                >
                                    Monthly
                                </button>
                                <button
                                    className={
                                        data.frequency ===
                                            'currently_abstaining'
                                            ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                            : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                    }
                                    type="button"
                                    id="currently_abstaining"
                                    value={data.frequency}
                                    onClick={(e) =>
                                        setData('frequency', e.target.id)
                                    }
                                >
                                    Currently abstaining
                                </button>
                            </div>
                            {data.frequency === 'daily' && (
                                <>
                                    <h5 className="mt-8 font-semibold text-gray-700">
                                        Daily use details (use per day)
                                    </h5>
                                    <div className="mt-2 flex w-full flex-wrap gap-2">
                                        <button
                                            className={
                                                data.daily_use_details ===
                                                    '1_to_2'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="1_to_2"
                                            value={data.daily_use_details}
                                            onClick={(e) =>
                                                setData(
                                                    'daily_use_details',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            1 to 2
                                        </button>
                                        <button
                                            className={
                                                data.daily_use_details ===
                                                    '3_to_4'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="3_to_4"
                                            value={data.daily_use_details}
                                            onClick={(e) =>
                                                setData(
                                                    'daily_use_details',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            3 to 4
                                        </button>
                                        <button
                                            className={
                                                data.daily_use_details ===
                                                    '5_or_more'
                                                    ? 'bg-customBlue  text-white px-4 py-2 w-full md:w-1/4  '
                                                    : 'bg-gray-200 px-4 py-2 w-full md:w-1/4  '
                                            }
                                            type="button"
                                            id="5_or_more"
                                            value={data.daily_use_details}
                                            onClick={(e) =>
                                                setData(
                                                    'daily_use_details',
                                                    e.target.id
                                                )
                                            }
                                        >
                                            5 or more
                                        </button>
                                    </div>
                                </>
                            )}
                            <h5 className="mt-8 font-semibold text-gray-700">
                                HIV Risk - Drug/Needle Use History
                            </h5>
                            {errors.risk && (
                                <div className="mt-2 rounded-full bg-customRed px-4 py-2 font-bold text-white">
                                    {errors.risk}
                                </div>
                            )}
                            <div className="mt-2 flex w-full flex-wrap gap-2">
                                <button
                                    className={
                                        data.risk === 'injection_drug_user'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="injection_drug_user"
                                    value={data.risk}
                                    onClick={(e) =>
                                        setData('risk', e.target.id)
                                    }
                                >
                                    IDU (Injection Drug User)
                                </button>
                                <button
                                    className={
                                        data.risk === 'non_injection_drug_user'
                                            ? 'bg-customBlue  w-full px-4 py-2 text-white md:w-1/4  '
                                            : 'w-full bg-gray-200 px-4 py-2 md:w-1/4  '
                                    }
                                    type="button"
                                    id="non_injection_drug_user"
                                    value={data.risk}
                                    onClick={(e) =>
                                        setData('risk', e.target.id)
                                    }
                                >
                                    Non-IDU
                                </button>
                            </div>
                        </>
                    )}
                    <div className="flex flex-row flex-wrap items-center mt-12 mb-8">
                        <h3 className="w-full mb-2 font-semibold text-lg text-gray-700">
                            See history:
                        </h3>
                        <div className="flex flex-row flex-wrap space-y-2">
                            <div
                                onClick={() => {
                                    setShowHistory('needle_exchange')
                                    setShowDetails('')
                                }}
                                className={
                                    showHistory === 'needle_exchange'
                                        ? 'mr-2 mt-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 mt-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Needle Exchange
                            </div>
                            <div
                                onClick={() => {
                                    setShowHistory('drop_in')
                                    setShowDetails('')
                                }}
                                className={
                                    showHistory === 'drop_in'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Drop In / Outreach
                            </div>
                            <div
                                onClick={() => {
                                    setShowHistory('narcan')
                                    setShowDetails('')
                                }}
                                className={
                                    showHistory === 'narcan'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Narcan / Naloxone
                            </div>
                            <div
                                onClick={() => {
                                    setShowHistory('holistic')
                                    setShowDetails('')
                                }}
                                className={
                                    showHistory === 'holistic'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Holistic Services
                            </div>
                            <div
                                onClick={() => {
                                    setShowHistory('smokingkit')
                                    setShowDetails('')
                                }}
                                className={
                                    showHistory === 'smokingkit'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Smoking Kit{' '}
                                <span className="ml-2 rounded-full bg-gray-100 p-2 text-sm">
                                    {smokingKitCount} in the last week
                                </span>
                            </div>
                            <div
                                onClick={() => {
                                    setShowHistory('vaccine_service')
                                    setShowDetails('')
                                }}
                                className={
                                    showHistory === 'vaccine_service'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Vaccine Service
                            </div>
                            <div
                                onClick={() => {
                                    setShowHistory('alerts')
                                    setShowDetails('')
                                }}
                                className={
                                    showHistory === 'alerts'
                                        ? 'mr-2 rounded-md bg-white px-2 py-4 font-semibold underline'
                                        : 'mr-2 rounded-md bg-white px-2 py-4 shadow-md hover:bg-gray-100'
                                }
                            >
                                Alerts
                            </div>
                        </div>
                    </div>
                    {showHistory === 'needle_exchange' && (
                        <table className="whitespace-no-wrap w-full">
                            <thead>
                                <tr className="font-bold text-left">
                                    <th className="px-6 pt-5 pb-4">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            {exchanges.map(
                                ({
                                    id,
                                    created_by,
                                    created_at,
                                    participant,
                                }) => (
                                    <HistoryRow
                                        key={id}
                                        id={id}
                                        type={'exchange'}
                                        created={created_at}
                                        showSelect={false}
                                    />
                                )
                            )}
                        </table>
                    )}
                    {showHistory === 'drop_in' && (
                        <table className="whitespace-no-wrap w-full">
                            <thead>
                                <tr className="font-bold text-left">
                                    <th className="px-6 pt-5 pb-4">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            {drop_ins.map(
                                ({
                                    id,
                                    created_by,
                                    created_at,
                                    participant,
                                }) => (
                                    <HistoryRow
                                        key={id}
                                        id={id}
                                        type={'dropin'}
                                        created={created_at}
                                        showSelect={false}
                                    />
                                )
                            )}
                        </table>
                    )}
                    {showHistory === 'narcan' && (
                        <table className="whitespace-no-wrap w-full">
                            <thead>
                                <tr className="font-bold text-left">
                                    <th className="px-6 pt-5 pb-4">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            {narcan.map(
                                ({
                                    id,
                                    created_by,
                                    created_at,
                                    participant,
                                }) => (
                                    <HistoryRow
                                        key={id}
                                        id={id}
                                        type={'narcan'}
                                        created={created_at}
                                        showSelect={false}
                                    />
                                )
                            )}
                        </table>
                    )}
                    {showHistory === 'holistic' && (
                        <table className="whitespace-no-wrap w-full">
                            <thead>
                                <tr className="font-bold text-left">
                                    <th className="px-6 pt-5 pb-4">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            {holistic.map(
                                ({
                                    id,
                                    created_by,
                                    created_at,
                                    participant,
                                }) => (
                                    <HistoryRow
                                        key={id}
                                        id={id}
                                        type={'holistic'}
                                        created={created_at}
                                        showSelect={false}
                                    />
                                )
                            )}
                        </table>
                    )}
                    {showHistory === 'smokingkit' && (
                        <table className="whitespace-no-wrap w-full">
                            <thead>
                                <tr className="font-bold text-left">
                                    <th className="px-6 pt-5 pb-4">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            {smokingkit.map(
                                ({
                                    id,
                                    created_by,
                                    created_at,
                                    participant,
                                }) => (
                                    <HistoryRow
                                        key={id}
                                        id={id}
                                        type={'smokingkit'}
                                        created={created_at}
                                        showSelect={false}
                                    />
                                )
                            )}
                        </table>
                    )}
                    {showHistory === 'vaccine_service' && (
                        <table className="whitespace-no-wrap w-full">
                            <thead>
                                <tr className="font-bold text-left">
                                    <th className="px-6 pt-5 pb-4">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            {vaccineService.map(
                                ({
                                    id,
                                    created_by,
                                    created_at,
                                    participant,
                                }) => (
                                    <HistoryRow
                                        key={id}
                                        id={id}
                                        type={'vaccineService'}
                                        created={created_at}
                                        showSelect={false}
                                    />
                                )
                            )}
                        </table>
                    )}
                    {showHistory === 'alerts' && (
                        <table className="whitespace-no-wrap w-full">
                            {/* <thead>
                                <tr className="font-bold text-left">
                                    <th className="px-6 pt-5 pb-4">
                                        Created At
                                    </th>
                                </tr>
                            </thead> */}
                            <AlertHistoryRow alerts={alerts} />
                        </table>
                    )}
                    <div className="mb-16 mt-8 flex w-full flex-row flex-wrap items-center justify-between gap-2 md:justify-start">
                        <button
                            type="submit"
                            className={
                                processing === 'true'
                                    ? 'bg-black px-4 py-2 text-white'
                                    : 'text-customGreen w-full rounded bg-gray-100 px-4 py-2 text-center text-sm font-semibold hover:bg-gray-200 lg:w-auto'
                            }
                            disabled={processing}
                            onClick={(e) => {
                                setData({
                                    ...data,
                                    selectParticipant: 1,
                                })
                            }}
                        >
                            Save and make active participant
                        </button>
                        <button
                            type="submit"
                            className={
                                processing === 'true'
                                    ? 'bg-black px-4 py-2 text-white'
                                    : 'text-customGreen w-full rounded bg-gray-100 px-4 py-2 text-center text-sm font-semibold hover:bg-gray-200 lg:w-auto'
                            }
                            disabled={processing}
                        >
                            Save
                        </button>
                        <Link
                            className="w-full rounded bg-gray-100 px-4 py-2 text-center text-sm text-black hover:bg-gray-200 lg:w-auto"
                            href="/participants/search"
                        >
                            Cancel without saving
                        </Link>
                        {user.can.create_location && (
                            <a
                                onClick={() => setShowHideConfirmation(true)}
                                className="text-customRed w-full rounded bg-gray-100 px-4 py-2 text-center text-sm hover:bg-gray-200 lg:w-auto"
                            >
                                Hide Participant
                            </a>
                        )}
                    </div>

                    {showHideConfirmation && (
                        <div className="mb-4 flex w-full max-w-xl flex-row gap-4 shadow-md">
                            <span className="bg-customRed relative w-12 w-auto max-w-xs flex-grow-0 items-center justify-center  rounded-l-md p-2 text-white md:flex-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-1/2 top-1/2 h-8 w-8 -translate-x-1/2 -translate-y-1/2 transform"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </span>
                            <div className="my-4 flex w-10/12 flex-col gap-0 p-2">
                                <p className="mb-4">
                                    You are about to hide this participant. The
                                    participant will no longer appear when
                                    searching, but will still appear in any
                                    relevant exports. This cannot be reversed.
                                    Please click confirm to proceed.
                                </p>
                                <div className="flex justify-start gap-8">
                                    <button
                                        onClick={() =>
                                            setShowHideConfirmation(false)
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleHideParticipant}
                                        className="flex-grow-0 self-start rounded bg-gray-100 px-4 py-2 text-center text-sm text-black hover:bg-gray-200 lg:w-auto"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </Layout>
            {showQr === true && (
                <QRScanner
                    qrValue={qrValue}
                    setQrValue={setQrValue}
                    showQr={showQr}
                    setShowQr={setShowQr}
                />
            )}
        </>
    )
}