import React from 'react'
import Layout from '../../../../Layout/Layout'
import { usePage, useForm } from '@inertiajs/react'
import FormButton from '../../../../Components/FormButton'
import { parseJSON, parseISO, format } from 'date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function Edit({ holistic }) {
    const { data, setData, put, processing, errors } = useForm({
        acupuncture: holistic.acupuncture || false,
        acupressure: holistic.acupressure || false,
        aromatherapy: holistic.aromatherapy || false,
        reflexology: holistic.reflexology || false,
        reiki: holistic.reiki || false,
        musicSoundTherapy: holistic.music_sound_therapy || false,
        energyArtsTaiChiQigong: holistic.energy_arts_tai_chi_qigong || false,
        massageTherapy: holistic.massage_therapy_hand_head_or_feet || false,
        massageTherapyFullBody: holistic.massage_therapy_full_body || false,
        spiritualityHolisticHealing: holistic.spirituality_holistic_healing || false,
        natureTherapy: holistic.nature_therapy || false,
        fisherWallace: holistic.fisher_wallace || false,
        yoga: holistic.yoga || false,
        meditationAndVisualization: holistic.meditation_and_visualization || false,
        manual_start_time: holistic.manual_start_time || '',
        manual_end_time: holistic.manual_end_time || '',
    })

    const { user } = usePage().props

    function handleSubmit(e) {
        e.preventDefault()
        put(route('holistic.update', holistic.id), data)
    }

    return (
        <Layout title="Holistic Services">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 md:mb-0 mt-2">
                    <div className="flex flex-row flex-wrap items-center mb-4">
                        <h5 className="text-2xl text-gray-700 mr-2">Editing: </h5>
                        <p className="text-2xl font-semibold">
                            {holistic.participant.first_name + ' ' + holistic.participant.last_name}
                        </p>

                        <p className="text-2xl font-semibold">{user.is.currently_seeing}</p>
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-6">
                        <h5 className="text-2xl text-gray-700 mr-2 w-full">Holistic Services Start & End Time:</h5>
                        {errors.manual_start_time ? (
                            <p className="italic text-red-500 text-sm w-full">{errors.manual_start_time}</p>
                        ) : null}
                        {errors.manual_end_time ? (
                            <p className="italic text-red-500 text-sm w-full">{errors.manual_end_time}</p>
                        ) : null}
                        <div className="flex flex-row flex-wrap gap-2 mt-4 items-center gap-4">
                            <div className="flex flex-col items-start gap-2">
                                <label className="block text-gray-700 text-sm mb-2 " htmlFor="manual_start_time">
                                    Start Time
                                </label>
                                {/* <input
                                    disabled
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border border-gray-400"
                                    value={
                                        data.manual_start_time
                                            ? format(
                                                  new Date(parseJSON(data.manual_start_time)),
                                                  'MM-dd-yyyy hh:mm:ss a'
                                              )
                                            : ''
                                    }
                                    id="manual_start_time"
                                    type="text"
                                    placeholder=""
                                /> */}
                                <DatePicker
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    showTimeSelect
                                    showTimeInput
                                    selected={
                                        data.manual_start_time ? new Date(parseJSON(data.manual_start_time)) : null
                                    }
                                    onChange={(date) => setData('manual_start_time', date)}
                                    className="w-full h-10 pl-2 text-gray-700 border border-gray-400"
                                />
                                <button
                                    type="button"
                                    className="bg-gray-200 w-full rounded p-2 whitespace-nowrap hover:bg-gray-100"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setData('manual_start_time', new Date().toISOString())
                                    }}
                                >
                                    Now
                                </button>
                            </div>
                            <div className="flex flex-col items-start gap-2">
                                <label className="block text-gray-700 text-sm mb-2 ml-1" htmlFor="manual_end_time">
                                    End Time
                                </label>

                                <DatePicker
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    showTimeSelect
                                    showTimeInput
                                    selected={data.manual_end_time ? new Date(parseJSON(data.manual_end_time)) : null}
                                    onChange={(date) => setData('manual_end_time', date)}
                                    className="w-full h-10 pl-2 text-gray-700 border border-gray-400"
                                />
                                <button
                                    type="button"
                                    className="bg-gray-200 w-full rounded p-2 whitespace-nowrap hover:bg-gray-100"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setData('manual_end_time', new Date().toISOString())
                                    }}
                                >
                                    Now
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap items-center my-4">
                        <h5 className="text-2xl text-gray-700 mr-2">Holistic Services Administered:</h5>
                        <div className="w-full flex flex-wrap my-4 gap-2">
                            <FormButton
                                text="Acupuncture"
                                value={data.acupuncture}
                                id="Acupuncture"
                                isActive={data.acupuncture ? true : false}
                                data={data}
                                onClick={(e) => setData('acupuncture', !data.acupuncture)}
                            />
                            <FormButton
                                text="Acupressure"
                                value={data.acupressure}
                                id="acupressure"
                                isActive={data.acupressure ? true : false}
                                data={data}
                                onClick={(e) => setData('acupressure', !data.acupressure)}
                            />
                            <FormButton
                                text="Aromatherapy"
                                value={data.aromatherapy}
                                id="aromatherapy"
                                isActive={data.aromatherapy ? true : false}
                                data={data}
                                onClick={(e) => setData('aromatherapy', !data.aromatherapy)}
                            />
                            <FormButton
                                text="Reflexology"
                                value={data.reflexology}
                                id="reflexology"
                                isActive={data.reflexology ? true : false}
                                data={data}
                                onClick={(e) => setData('reflexology', !data.reflexology)}
                            />
                            <FormButton
                                text="Reiki"
                                value={data.reiki}
                                id="reiki"
                                isActive={data.reiki ? true : false}
                                data={data}
                                onClick={(e) => setData('reiki', !data.reiki)}
                            />{' '}
                            <FormButton
                                text="Music/Sound Therapy"
                                value={data.musicSoundTherapy}
                                id="musicSoundTherapy"
                                isActive={data.musicSoundTherapy ? true : false}
                                data={data}
                                onClick={(e) => setData('musicSoundTherapy', !data.musicSoundTherapy)}
                            />
                            <FormButton
                                text="Energy Arts Tai Chi/Qigong"
                                value={data.energyArtsTaiChiQigong}
                                id="EnergyArtsTaiChi_Qigong"
                                isActive={data.energyArtsTaiChiQigong ? true : false}
                                data={data}
                                onClick={(e) => setData('energyArtsTaiChiQigong', !data.energyArtsTaiChiQigong)}
                            />
                            <FormButton
                                text="Massage Therapy (Hand, Head Or Feet)"
                                value={data.massageTherapy}
                                id="messageTherapy"
                                isActive={data.massageTherapy ? true : false}
                                data={data}
                                onClick={(e) => setData('massageTherapy', !data.massageTherapy)}
                            />
                            <FormButton
                                text="Massage Therapy Full-Body"
                                value={data.massageTherapyFullBody}
                                id="massageTherapyFullBody"
                                isActive={data.massageTherapyFullBody ? true : false}
                                data={data}
                                onClick={(e) => setData('massageTherapyFullBody', !data.massageTherapyFullBody)}
                            />
                            <FormButton
                                text="Spirituality & Holistic Healing"
                                value={data.spiritualityHolisticHealing}
                                id="spiritualityHolisticHealing"
                                isActive={data.spiritualityHolisticHealing ? true : false}
                                data={data}
                                onClick={(e) =>
                                    setData('spiritualityHolisticHealing', !data.spiritualityHolisticHealing)
                                }
                            />
                            <FormButton
                                text="Nature Therapy"
                                value={data.natureTherapy}
                                id="natureTherapy"
                                isActive={data.natureTherapy ? true : false}
                                data={data}
                                onClick={(e) => setData('natureTherapy', !data.natureTherapy)}
                            />{' '}
                            <FormButton
                                text="Fisher/Wallace"
                                value={data.fisherWallace}
                                id="Fisher_Wallace"
                                isActive={data.fisherWallace ? true : false}
                                data={data}
                                onClick={(e) => setData('fisherWallace', !data.fisherWallace)}
                            />
                            <FormButton
                                text="Yoga"
                                value={data.yoga}
                                id="yoga"
                                isActive={data.yoga ? true : false}
                                data={data}
                                onClick={(e) => setData('yoga', !data.yoga)}
                            />
                            <FormButton
                                text="Meditation And Visualization"
                                value={data.meditationAndVisualization}
                                id="meditationAndVisualization"
                                isActive={data.meditationAndVisualization ? true : false}
                                data={data}
                                onClick={(e) => setData('meditationAndVisualization', !data.meditationAndVisualization)}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row flex-wrap bg-customBlue md:space-x-4 space-y-4 md:space-y-0 items-center py-7 px-8 md:px-16 mb-8">
                    <button
                        type="submit"
                        className="bg-white text-customBlue h-12 py-2 px-8 w-full justify-center md:w-auto font-bold flex flex-row items-center"
                        disabled={processing}
                    >
                        {processing && (
                            <div>
                                <svg className="animate-spin mr-2" width="18px" height="18px" viewBox="0 0 120 120">
                                    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                                        <g
                                            id="Group"
                                            transform="translate(9.000000, 9.000000)"
                                            stroke="#314EB9"
                                            strokeWidth="9"
                                        >
                                            <path
                                                d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                id="Path"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        )}
                        Done
                    </button>
                    <div className="flex-col md:flex-row items-center w-auto md:w-auto bg-white rounded-full py-2">
                        <div className="flex flex-row items-center px-4">
                            <small className="pr-2">Step 1 of 1</small>
                            <div className="h-4 w-4 rounded-full bg-customBlue"></div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    )
}
