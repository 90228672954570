import React from 'react'
import Layout from '../../../../Layout/Layout'
import { usePage, useForm } from '@inertiajs/react'
import Select from 'react-select'

export default function NaloxoneRecipient({ narcan }) {
    const { data, setData, put, processing, errors } = useForm({
        formulation_kit_required: narcan.formulation_kit_required || '',
        kits_dispensed: narcan.kits_dispensed || '',
        expiration_month: narcan.expiration_month || '',
        expiration_year: narcan.expiration_year || '',
        communal_kits: narcan.communal_kits || false,
        communal_zip: narcan.communal_zip || '',
        overdose_prevention_education: narcan.overdose_prevention_education || false,
        fentanyl_test_strips: narcan.fentanyl_test_strips || '',
        pdse_training: narcan.pdse_training || '',
        recipient_zip: narcan.recipient_zip || '',
        first_time: narcan.first_time || false,
        whynalox_od: narcan.whynalox_od || false,
        whynalox_work: narcan.whynalox_work || false,
        whynalox_incase: narcan.whynalox_incase || false,

        //        odResponse: [],
    })

    const months = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
    ]

    function setExpirationMonthId(e) {
        setData((data) => ({
            ...data,
            expiration_month: e,
        }))
    }

    const createYear = new Date().getFullYear()
    var years = []
    for (var i = 0; i < 6; i++) {
        var beginningYear = createYear + i
        years.push({
            value: beginningYear,
            label: beginningYear,
        })
    }

    function setExpirationYearId(e) {
        setData((data) => ({
            ...data,
            expiration_year: e,
        }))
    }

    const { user } = usePage().props

    function handleSubmit(e) {
        e.preventDefault()
        put(route('narcan.update', narcan.id), data)
    }

    return (
        <Layout title="Narcan/Naloxone Recipient Form">
            <form onSubmit={handleSubmit}>
                <div className="flex w-full flex-col flex-wrap bg-white p-8 shadow-md md:mb-0 md:p-16">
                    <div className="mb-4 flex flex-row flex-wrap items-center">
                        <h5 className="mr-2 text-2xl text-gray-700">Editing: </h5>
                        <p className="text-2xl font-semibold">
                            {narcan.participant.first_name + ' ' + narcan.participant.last_name}
                        </p>
                        <p className="text-2xl font-semibold">{user.is.currently_seeing}</p>
                    </div>
                    <h5 className="mb-4 mr-2 text-2xl text-gray-700">Kit details:</h5>
                    <p className="mb-2 w-full">Naloxone formulation provided:</p>
                    <div className="mb-6 flex w-full flex-wrap gap-2">
                        <button
                            className={
                                data.formulation_kit_required === '1'
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-auto'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-auto'
                            }
                            type="button"
                            id="1"
                            value={data.formulation_kit_required}
                            onClick={(e) => setData('formulation_kit_required', e.target.id)}
                        >
                            Two doses single-step Narcan
                        </button>
                        <button
                            className={
                                data.formulation_kit_required === '3'
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-auto'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-auto'
                            }
                            type="button"
                            id="3"
                            value={data.formulation_kit_required}
                            onClick={(e) => setData('formulation_kit_required', e.target.id)}
                        >
                            Two doses intramuscular (0.4mg/1ml)
                        </button>
                    </div>
                    <div className="mb-4 flex w-full flex-row flex-wrap gap-4 md:gap-4">
                        <div className="flex w-full flex-col md:mt-0 md:w-4/12">
                            <label className="mb-2 block" htmlFor="expiration_month">
                                Expiration Month
                            </label>
                            <Select
                                label="Expiration Month"
                                options={months}
                                // selectOption={options}
                                className="w-full"
                                classNamePrefix="select"
                                onChange={(opt) => setExpirationMonthId(opt.value)}
                                placeholder={data.expiration_month}
                                // defaultValue={options[0]}
                                // isDisabled={isDisabled}
                                // isLoading={isLoading}
                                // isClearable={isClearable}
                                // isRtl={isRtl}
                                isSearchable={true}
                                name="color"
                            />
                        </div>
                        <div className="flex w-full flex-col md:mt-0 md:w-4/12">
                            <label className="mb-2 block" htmlFor="expiration_year">
                                Expiration Year
                            </label>
                            <Select
                                label="Expiration Year"
                                options={years}
                                // selectOption={options}
                                className="w-full"
                                classNamePrefix="select"
                                placeholder={data.expiration_year}
                                onChange={(opt) => setExpirationYearId(opt.value)}
                                // defaultValue={options[0]}
                                // isDisabled={isDisabled}
                                // isLoading={isLoading}
                                // isClearable={isClearable}
                                // isRtl={isRtl}
                                isSearchable={true}
                                name="color"
                            />
                        </div>
                        <div className="w-full gap-2 text-gray-700 md:mt-2 md:w-4/12 md:gap-4">
                            <label className="mb-2 block" htmlFor="kits_dispensed">
                                How many kits were dispensed?
                            </label>
                            <input
                                onChange={(e) => setData('kits_dispensed', e.target.value)}
                                value={data.kits_dispensed}
                                className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                type="text"
                                placeholder=""
                                id="kits_dispensed"
                            />
                        </div>
                        <div className="w-full text-gray-700 md:mt-2 md:w-4/12">
                            <label className="mb-2 block" htmlFor="kits_dispensed">
                                Number of fentanyl test strips provided
                            </label>
                            <input
                                onChange={(e) => setData('fentanyl_test_strips', e.target.value)}
                                value={data.fentanyl_test_strips}
                                className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                type="text"
                                placeholder=""
                                id="fentanyl_test_strips"
                            />
                            {errors.fentanyl_test_strips && (
                                <div className="mt-2 rounded-full bg-red-500 px-4 py-2 text-sm font-bold text-white">
                                    {errors.fentanyl_test_strips}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-2 flex w-full flex-row flex-wrap items-center gap-2">
                        <div className="flex w-full flex-col">
                            <p className="mb-2  mt-4 w-full">Are these communal kits?</p>
                            <div className="flex flex-row gap-2">
                                <button
                                    className={
                                        data.communal_kits == 1
                                            ? 'bg-customBlue w-full flex-grow-0 px-4 py-2 text-white md:w-3/12'
                                            : 'w-full flex-grow-0 bg-gray-200 px-4 py-2 md:w-3/12'
                                    }
                                    type="button"
                                    id="1"
                                    value={data.communal_kits}
                                    onClick={(e) => setData('communal_kits', !data.communal_kits)}
                                >
                                    Yes
                                </button>
                                <button
                                    className={
                                        data.communal_kits == 0
                                            ? 'bg-customBlue w-full flex-grow-0 px-4 py-2 text-white md:w-3/12'
                                            : 'w-full flex-grow-0 bg-gray-200 px-4 py-2 md:w-3/12'
                                    }
                                    type="button"
                                    id="0"
                                    value={data.communal_kits}
                                    onClick={(e) =>
                                        setData((data) => ({
                                            ...data,
                                            communal_kits: !data.communal_kits,
                                            communal_zip: '',
                                        }))
                                    }
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                    {data.communal_kits == 1 && (
                        <div className="flex w-full flex-col md:w-4/12">
                            <label className="mb-2 mt-4 block" htmlFor="communal_zip">
                                ZIP code where communal kits will be kept
                            </label>
                            <input
                                onChange={(e) => setData('communal_zip', e.target.value)}
                                value={data.communal_zip}
                                className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                type="text"
                                placeholder=""
                                id="communal_zip"
                            />
                        </div>
                    )}

                    <div className="mb-2 mt-4 flex w-full flex-row flex-wrap gap-2">
                        <p className="mb-2 mt-4 w-full">Was training delivered via PDSE?</p>
                        <button
                            className={
                                data.pdse_training == 1
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-3/12'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="1"
                            value={data.pdse_training}
                            onClick={(e) => setData('pdse_training', e.target.id)}
                        >
                            Yes
                        </button>
                        <button
                            className={
                                data.pdse_training == 0
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-3/12'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="0"
                            value={data.pdse_training}
                            onClick={(e) => setData('pdse_training', e.target.id)}
                        >
                            No
                        </button>
                    </div>
                    <div className="mb-2 mt-4 flex w-full flex-row flex-wrap gap-2">
                        <p className="mb-2 mt-4 w-full">Was overdose prevention education provided?</p>
                        <button
                            className={
                                data.overdose_prevention_education == 1
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-3/12'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="1"
                            value={data.overdose_prevention_education}
                            onClick={(e) =>
                                setData('overdose_prevention_education', !data.overdose_prevention_education)
                            }
                        >
                            Yes
                        </button>
                        <button
                            className={
                                data.overdose_prevention_education == 0
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-3/12'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="0"
                            value={data.overdose_prevention_education}
                            onClick={(e) =>
                                setData('overdose_prevention_education', !data.overdose_prevention_education)
                            }
                        >
                            No
                        </button>
                    </div>
                    <div className="mb-2 mt-4 flex w-full flex-row flex-wrap space-x-2">
                        <p className="mb-2 mt-4 w-full text-2xl">Questions for recipient:</p>
                        <div className="flex w-full flex-col md:w-3/12">
                            <label className="mb-2 mt-4 block" htmlFor="recipient_zip">
                                What is the ZIP code of the place you live or stay the most often?
                            </label>
                            <input
                                onChange={(e) => setData('recipient_zip', e.target.value)}
                                value={data.recipient_zip}
                                className="h-10 w-full border border-gray-400 px-3 text-base placeholder-gray-600"
                                type="text"
                                placeholder=""
                                id="recipient_zip"
                            />
                        </div>
                    </div>
                    <div className="mb-2 mt-4 flex w-full flex-row flex-wrap gap-2">
                        <p className="mb-2  mt-4 w-full">
                            Is this the first time you've received or purchased a naloxone/Narcan kit?
                        </p>
                        <button
                            className={
                                data.first_time == 1
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-3/12'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="1"
                            value={data.first_time}
                            onClick={(e) => setData('first_time', !data.first_time)}
                        >
                            Yes
                        </button>
                        <button
                            className={
                                data.first_time == 0
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-3/12'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="0"
                            value={data.first_time}
                            onClick={(e) => setData('first_time', !data.first_time)}
                        >
                            No
                        </button>
                    </div>
                    <div className="mb-2 mt-4 flex w-full flex-row flex-wrap gap-2">
                        <p className="mb-2  mt-4 w-full">
                            Why are you getting a kit today? Please select all that apply.
                        </p>
                        <button
                            className={
                                data.whynalox_od == 1
                                    ? 'bg-customBlue w-full flex-grow-0 px-4 py-2 text-white md:w-3/12'
                                    : 'w-full flex-grow-0 bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="1"
                            value={data.whynalox_od}
                            onClick={(e) => setData('whynalox_od', !data.whynalox_od)}
                        >
                            I'm worried that someone I know will overdose OR that I will overdose
                        </button>
                        <button
                            className={
                                data.whynalox_work == 1
                                    ? 'bg-customBlue w-full flex-grow-0 px-4 py-2 text-white md:w-3/12'
                                    : 'w-full flex-grow-0 bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="1"
                            value={data.whynalox_work}
                            onClick={(e) => setData('whynalox_work', !data.whynalox_work)}
                        >
                            I work with people who use drugs as part of my job
                        </button>

                        <button
                            className={
                                data.whynalox_incase == 1
                                    ? 'bg-customBlue w-full px-4 py-2 text-white md:w-3/12'
                                    : 'w-full bg-gray-200 px-4 py-2 md:w-3/12'
                            }
                            type="button"
                            id="1"
                            value={data.whynalox_incase}
                            onClick={(e) => setData('whynalox_incase', !data.whynalox_incase)}
                        >
                            Just in case I see someone overdose
                        </button>
                    </div>
                </div>
                <div className="bg-customBlue mb-8 flex w-full flex-row flex-wrap items-center space-y-4 px-8 py-7 md:space-x-4 md:space-y-0 md:px-16">
                    <button
                        type="submit"
                        className="text-customBlue flex h-12 w-full flex-row items-center justify-center bg-white px-8 py-2 font-bold hover:underline md:w-auto"
                        disabled={processing}
                    >
                        {processing && (
                            <div>
                                <svg className="mr-2 animate-spin" width="18px" height="18px" viewBox="0 0 120 120">
                                    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                                        <g
                                            id="Group"
                                            transform="translate(9.000000, 9.000000)"
                                            stroke="#314EB9"
                                            strokeWidth="9"
                                        >
                                            <path
                                                d="M102,51 C102,22.8334778 79.1665222,0 51,0 C22.8334778,0 0,22.8334778 0,51 C0,79.1665222 22.8334778,102 51,102"
                                                id="Path"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        )}
                        Done
                    </button>
                    <div className="w-auto flex-col items-center rounded-full bg-white py-2 md:w-auto md:flex-row">
                        <div className="flex flex-row items-center px-4">
                            <small className="pr-2">Step 1 of 1</small>
                            <div className="bg-customBlue h-4 w-4 rounded-full"></div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    )
}
