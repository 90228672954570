import React, { useState } from 'react'
import { usePage } from '@inertiajs/react'
import NavItem from '../Components/NavItem'

export default function AdminNavMenu({ setShowAdminNav }) {
    const { user } = usePage().props
    const [isShown, setIsShown] = useState(true)
    const isActive = route().current()

    function handleBack(e) {
        e.preventDefault()
        setIsShown(false)
    }
    return (
        <>
            <div className="hidden py-8 md:py-0 h-full md:flex flex-col justify-start bg-gray-100">
                <div className="z-50">
                    <NavItem
                        name="Users"
                        link="/settings/users"
                        icon="User"
                        iconColor={isActive === 'users.index' ? '#FFFFFF' : 'rgba(251, 212, 24, 1)'}
                        shadowColor="shadow-yellow"
                        activeColor="bg-customYellow"
                        isActive={isActive === 'users.index' ? true : false}
                    />
                    <NavItem
                        name="Locations"
                        link="/settings/locations"
                        icon="OfficeBuildingIcon"
                        iconColor={isActive === 'locations.index' ? '#FFFFFF' : 'rgba(90, 164, 85, 1)'}
                        shadowColor="shadow-green"
                        activeColor="bg-customGreen"
                        isActive={isActive === 'locations.index' ? true : false}
                    />
                    <NavItem
                        name="Import/Export"
                        link="/settings/import"
                        icon="SwitchVerticalIcon"
                        iconColor={isActive === 'import' ? '#FFFFFF' : 'rgba(100, 116, 175, 1)'}
                        shadowColor="shadow-green"
                        activeColor="bg-customBlue"
                        isActive={isActive === 'import' ? true : false}
                    />
                    <NavItem
                        name="Audit Logs"
                        link="/settings/audit"
                        icon="ClipboardCheckIcon"
                        iconColor={isActive === 'audit.index' ? '#FFFFFF' : 'rgba(142, 63, 120, 1)'}
                        shadowColor="shadow-red"
                        activeColor="bg-customPurple"
                        isActive={isActive === 'audit.index' ? true : false}
                    />
                    <NavItem
                        name="What's New"
                        link="/settings/changelog"
                        icon="NewspaperIcon"
                        iconColor="rgb(171, 46, 46)"
                        shadowColor="shadow-red"
                    />
                    <NavItem
                        name="Back"
                        link="/dashboard"
                        icon="ArrowLeftIcon"
                        iconColor="rgba(128, 128, 128, 1)"
                        shadowColor="shadow-gray"
                    />
                    {/*
                    <MinimizeButton
                        name="Minimize"
                        icon="ArrowLeft"
                        iconColor="gray"
                        shadowColor="gray"
                    />
                    */}
                </div>
            </div>
        </>
    )
}
