import React from 'react'
import { Link, router } from '@inertiajs/react'
import moment from 'moment'

export default function Index({
    id,
    created,
    participant_ccn,
    user_name,
    showSelect,
    type,
}) {
    function handleSelect(e, id) {
        e.preventDefault()
        if (type === 'exchange') {
            router.visit(route('exchange.edit', id), {
                method: 'get',
            })
        }
        if (type === 'dropin') {
            router.visit(route('dropin.edit', id), {
                method: 'get',
            })
        }
        if (type === 'narcan') {
            router.visit(route('narcan.edit', id), {
                method: 'get',
            })
        }
        if (type === 'holistic') {
            router.visit(route('holistic.edit', id), {
                method: 'get',
            })
        }
        if (type === 'smokingkit') {
            router.visit(route('smoking.edit', id), {
                method: 'get',
            })
        }
        if (type === 'vaccineService') {
            router.visit(route('vaccineservice.edit', id), {
                method: 'get',
            })
        }
    }
    function selectParticipant(e, id) {
        e.preventDefault()
        router.visit(route('exchange.index', id), {
            method: 'put',
        })
    }
    return (
        <>
            <tbody>
                <tr className="hover:bg-gray-100 focus-within:bg-gray-100">
                    <td key={id} className="border-t">
                        <Link
                            onClick={(e) => handleSelect(e, id)}
                            className="flex items-center justify-start py-4 pr-6 focus:text-indigo-700"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-8 h-8 mr-4 text-gray-500 hover:text-customGreen"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1}
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                            </svg>
                            {moment(created).format('dddd, MMMM Do YYYY')}
                        </Link>
                    </td>
                    {/* <td className="border-t">
                        <Link
                            onClick={(e) => handleSelect(e, id)}
                            className="flex items-center px-6 py-4 focus:text-indigo"
                        >
                            {user_name}
                        </Link>
                    </td>
                    <td className="border-t">
                        <Link
                            onClick={(e) => handleSelect(e, id)}
                            className="flex items-center px-6 py-4 focus:text-indigo"
                        >
                            {participant_ccn}
                        </Link>
                    </td>
                    {/* <td className="border-t">
                        <Link
                            onClick={(e) => handleSelect(e, id)}
                            className="flex items-center px-6 py-4 focus:text-indigo"
                        >
                            {primary_phone}
                        </Link>
                    </td>
                    <td className="border-t">
                        <Link
                            onClick={(e) => handleSelect(e, id)}
                            className="flex items-center px-6 py-4 focus:text-indigo"
                        >
                            {exchange_number}
                        </Link>
                    </td> */}
                    {showSelect && (
                        <td className="text-right border-t">
                            <Link
                                as="button"
                                className="px-4 py-2 mr-2 font-bold text-white rounded bg-customGreen hover:bg-customGreenLight"
                                onClick={(e) => {
                                    selectParticipant(e, id)
                                }}
                            >
                                Select
                            </Link>
                        </td>
                    )}
                </tr>
            </tbody>
        </>
    )
}
